import React from "react";
import ContactShow from "../../platform/contact-show/contact-show";
import ReactGA from "react-ga";

export default class Contact extends React.Component {
    componentDidMount() {
        ReactGA.pageview("/contact");
    }

    render() {
        return (
            <ContactShow />
        );
    }
}
