import React from "react";
import PropTypes from "prop-types";
import {WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon} from "react-share";

//TODO this almost the same as  xenginebtn reactions, eliminate xenginebtn reactions and just leave this one
const XEngineShare = (props) => {
    const close = () => {
        document.getElementById("share-modal").style.visibility = "hidden";
    };

    return (
        <div className="xengine-modal-share-menu" id="share-modal" onClick={close}>
            <div className="close-btn" onClick={close}>X</div>
            <FacebookShareButton
                url={"https://app.immersotours.com/" + props.experience.slug}
                quote={"Te invito a vivir una gran experiencia de turismo inmersivo en " + props.experience.name + "!"}
                hashtag="#Immerso"
                className="share-Facebook">
                <FacebookIcon size={40} round={true}/>
            </FacebookShareButton>
            <WhatsappShareButton
                url={"https://app.immersotours.com/" + props.experience.slug}
                quote={"Te invito a vivir una gran experiencia de turismo inmersivo en " + props.experience.name + "!"}
                hashtag="#Immerso"
                className="share-Whatsapp">
                <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>     
        </div> 
    );
};

XEngineShare.propTypes = {
    experience: PropTypes.object.isRequired,
};


export default XEngineShare;
