
/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

window.AFRAME.registerComponent("interactive-object", {
    init:function() {
        let scalar = 1.1;
        this.el.addEventListener("mouseenter", () => {
            this.el.object3D.scale.multiplyScalar(scalar);
        });

        this.el.addEventListener("mouseleave", () => {
            this.el.object3D.scale.multiplyScalar((1/scalar));
        });
    }
});