import { iOS } from "../../helpers";

class VideoManager {
    constructor() {
        if (!VideoManager.instance) {
            this._data = [];
            this.video = null;
            this.videoSphere = null;
            this.playing = false;
        }
        return VideoManager.instance;
    }

    get video() {
        return this._video;
    }

    set video(value) {
        this._video = value;
    }

    get videoSphere() {
        return this._videoSphere;
    }

    set videoSphere(value) {
        this._videoSphere = value;
    }

    get playing() {
        return this._playing;
    }

    set playing(value) {
        this._playing = value;
    }

    init(videosphere) {
        this.videoSphere = videosphere;
    }

    setVideo(video) {
        this.video = video;
        video.volume = 0;
        this.videoSphere.setAttribute("src", `#${video.id}`);
        if (!iOS()) this.playVideo();
        else {
            this.video.play();
            this.videoSphere.object3D.scale.set(-1, 1, 1);
        }
    }

    playVideo() {
        this.playing = true;
        setTimeout(() => {
            this.video.play();
            this.videoSphere.object3D.scale.set(-1, 1, 1);
        }, 1);

    }

    stopVideo() {
        this.playing = false;
        this.videoSphere.object3D.scale.set(0, 0, 0);
        this.videoSphere.components.material.material.map.image.pause();
    }
}

const videoManager = new VideoManager();

export default videoManager;
