/**
 * @fileoverview
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

AFRAME.registerComponent("waypoints", {
  init: function () {
    this.el.addEventListener("reloadspots", function (evt) {
      //get the entire current spot group and scale it to 0
      let groups = document.getElementsByClassName("waypoint-group");
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].getAttribute("visible")) {
          groups[i].setAttribute("visible", false);
          groups[i].object3D.scale.set(0, 0, 0);
        }
      }

      //get the entire new spot group and scale it to 1
      let newspotgroup = document.getElementById(evt.detail.newspots);
      newspotgroup.setAttribute("visible", true);
      newspotgroup.object3D.scale.set(1, 1, 1);
    });
  },
});
