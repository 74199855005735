const DRAGLIMIT = 10;
const TRANSITIONDURATION = 1000;
const OPENHOTSPOTDISTANCE = 0.875;
const OPENHOTSPOTDISTANCEMOBILE = 1.175;
const CALLTOACTIONTYPES = {
    BOOKINGS: "Book",
    TICKETS: "Ticket",
    CONTACTS: "Contact",
};

const VROBJECTS = {
    WAYPOINT: true,
    HOTSPOT: true,
    VIDEOHOTSPOT: false,
    TRIVIA: false
};

export {
    DRAGLIMIT,
    OPENHOTSPOTDISTANCE,
    OPENHOTSPOTDISTANCEMOBILE,
    CALLTOACTIONTYPES,
    TRANSITIONDURATION,
    VROBJECTS
};


