import React from "react";
import PropTypes from "prop-types";

//TODO this almost the same as  xenginebtn reactions, eliminate xenginebtn reactions and just leave this one
const XEngineStaticBtn = (props) => {
    let style = {};
    if(props.backgroundColor) style.backgroundColor = props.backgroundColor + "88";
    // if(props.borderColor) style.borderColor = props.borderColor;
    if(props.textColor) style.color = props.textColor;

    return (
        <div style={style} onClick={props.onClick} id={props.id} className={`xengine-static-btn ${props.class}`}>
            <i className={`fas fa-${props.icon}`}></i>
            <span id={props.textId} className="menu-btn-text">{props.text}</span>
            <img id={`image-${props.id}`} src={props.imageURL} />
        </div>
    );
};

XEngineStaticBtn.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
    id: PropTypes.string.isRequired,
    class: PropTypes.string,
    imageURL: PropTypes.string,
    text: PropTypes.string,
    textId: PropTypes.string,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
};


export default XEngineStaticBtn;
