/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

AFRAME.registerComponent("lookatcamera", {
    init: function () {
        this.el.setAttribute("look-at", "#cam");
    }
});
