import React from "react";
import { api } from "../../../managers/api/api-manager";
import PropTypes from "prop-types";

const VideoConference = (props) => {
    const jitsiContainerId = "jitsi-container-id";

    const [jitsi, setJitsi] = React.useState(false);

    const _jitsiCopy = false;

    const loadJitsiScript = () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise((resolve) => {
            resolveLoadJitsiScriptPromise = resolve;
        });

        const script = document.createElement("script");
        script.src = "https://meet.jit.si/external_api.js";
        script.async = true;
        script.onload = resolveLoadJitsiScriptPromise;

        document.body.appendChild(script);

        return loadJitsiScriptPromise;
    };

    const pinGuide = () => {
        let participants = window.jitsi._participants;
        let sessionGuideUUIDs = props.guideIds;
        let participantIDs = Object.keys(participants);

        props.setPartipantsCount(participants);

        for (let i = 0; i < participantIDs.length; i++) {
            let participantId = participantIDs[i];
            //Looks for session guide
            let userId = participants[participantId].displayName.split(" ");
            userId = parseInt(userId[userId.length - 1]);
            if (sessionGuideUUIDs.includes(userId)) {
                //Gets session guide ID
                //To pin it to the call
                window.jitsi.pinParticipant(participantId);
            }
        }
        // If the user is just joining in, teleport them to the guide
        // It is in pinGuide because this method is called when someone new joins the session
        if (api.sessionUser?.isGuide()) {
            //Send to other users in session this JSON via websockets
            api.experienceCommunicationSend({
                "action": "switch_location", "new_location": window.sessionStorage.getItem("lastLocationID"),
                "new_rotation": window.sessionStorage.getItem("lastLocationRigYRotation")
            });
        }
          
    };

    const initialiseJitsi = async () => {
        if (window._jitsi)
            return;
        if (!window.JitsiMeetExternalAPI) {
            await loadJitsiScript();
        }

        props.jitsiParameters.parentNode = document.getElementById(jitsiContainerId);

        const _jitsi = new window.JitsiMeetExternalAPI("meet.jit.si", {
            ...props.jitsiParameters
        });
        window.jitsi = _jitsi;
        // In case "videoConferenceJoined" doesn't work, "participantJoined" is used
        window.jitsi.on("videoConferenceJoined", pinGuide);
        window.jitsi.on("participantJoined", pinGuide);
        window.jitsi.on("participantLeft", pinGuide);

        // This hides the film strip on mobile

        setJitsi(_jitsi);
        if (props.onLoad)
            props.onLoad();
    };

    React.useEffect(() => {
        if (_jitsiCopy === jitsi) {
            initialiseJitsi();
        }

        return () => jitsi?.dispose?.();
    });
    return (
        <div>
            <div className={_jitsiCopy === jitsi ? "loader" : "d-none"} />
            <div className={_jitsiCopy !== jitsi ? "" : "d-none"} id={jitsiContainerId} />
        </div>
    );
};

VideoConference.propTypes = {
    onLoad: PropTypes.func,
    guideIds: PropTypes.arrayOf(PropTypes.number),
    jitsiParameters: PropTypes.object.isRequired,
    setPartipantsCount: PropTypes.func.isRequired
};

export default VideoConference;
