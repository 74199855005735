class CameraManager {

    constructor() {
        document.addEventListener("mousedown", (e) => this.mouseDownHandler(e));
        document.addEventListener("touchstart", (e) => this.mouseDownHandler(e));
    }

    mouseDownHandler(e) {
        this.stopIdleRotate();
        this.resetIdleTimer();
    }

    resetIdleTimer() {
        let timer = window.sessionStorage.getItem("interactionTimer");
        if(timer) window.clearTimeout(timer);
        let timeoutHandle = window.setTimeout(() => cameraManager.startIdleRotate(), 5000);
        window.sessionStorage.setItem("interactionTimer", timeoutHandle);
    }

    lookToPoint(y_angle) {
        this.stopIdleRotate();
        this.resetIdleTimer();
        let rig = document.querySelector("#rig");
        let cam = document.querySelector("#cam");
        let camRotation = cam.getAttribute("rotation");
        let rigRotation = rig.getAttribute("rotation");
        let newy = y_angle - camRotation.y;
        let newRotation = `${rigRotation.x} ${(newy < 0 ? 360 + newy : newy) % 360} ${rigRotation.z}`;
        rig.setAttribute("rotation", newRotation);
    }

    rotateToPoint(position, time) {
        let rig = document.querySelector("#rig");
        let cam = document.querySelector("#cam");
        let camRotation = cam.getAttribute("rotation");
        let rigRotation = rig.getAttribute("rotation");
        let newy = (position.y_angle - camRotation.y) % 360;
        let rotateAnim = rig.getAttribute("animation__rotate");
        rotateAnim.from = rigRotation.y;
        rotateAnim.to = newy;
        rotateAnim.dur = time;
        rotateAnim.startEvents = [];
        rotateAnim.enabled = true;
        rig.setAttribute("animation__rotate", rotateAnim);
    }

    moveToPoint(from, to, time, easing) {
        let cam = document.querySelector("#cam");
        let moveAnim = cam.getAttribute("animation__move");
        moveAnim.from = from;
        moveAnim.to = to;
        moveAnim.dur = time;
        moveAnim.startEvents = [];
        moveAnim.easing = easing;
        moveAnim.enabled = true;
        cam.setAttribute("animation__move", moveAnim);
    }

    startIdleRotate() {
        let mode = new URLSearchParams(location.search).get("j");
        if(mode != 2) {
            let elementOpen = window.sessionStorage.getItem("hotspotOpen");
            if(elementOpen == "true") {
                this.resetIdleTimer();
            }
            else {
                let rig = document.querySelector("#rig");
                if(rig) {
                    let rigRotation = rig.getAttribute("rotation");
                    let idleAnimY = rig.getAttribute("animation__idle_y");
                    idleAnimY.from = rigRotation.y;
                    idleAnimY.to = rigRotation.y - 360;
                    idleAnimY.enabled = true;
                    rig.setAttribute("animation__idle_y", idleAnimY);
                }
            }
        }
    }

    stopIdleRotate() {
        let rig = document.querySelector("#rig");
        if(rig) {
            let rotateAnim = rig.getAttribute("animation__idle_y");
            rotateAnim.enabled = false;
            rig.setAttribute("animation__idle_y", rotateAnim);
        }
    }
}

let cameraManager = new CameraManager();

export { cameraManager };
