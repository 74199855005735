/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

import { interacionManager } from "../../managers/interaction/interaction-manager";

 window.AFRAME.registerComponent("move-video-hotspot", {
    schema: {
        mode: { type: "number", default: 0 },
        moving: { type: "boolean", default: false },
        position: { type: "vec3", default: {} },
        point: { type: "vec3", default: {} },
        distance: { type: "number", default: 0.0 },
    },

    tick: function (time, timeDelta) {
        if(this.data.mode == 2) {
            if (this.raycaster == undefined) { return; }  // Not intersecting.
            this.intersection = this.raycaster.components.raycaster.getIntersection(this.open);
            if (this.intersection == undefined) { return; }
            this.data.point = this.intersection.point;

            if(this.data.moving && this.intersection){

                this.data.point.x += this.offset.x;
                this.data.point.y += this.offset.y;
                this.data.point.z += this.offset.z;

                let length = Math.sqrt(this.data.point.x**2 + (this.data.point.y)**2 + this.data.point.z**2);
                this.data.point.x = this.data.distance*this.data.point.x/length;
                this.data.point.y = this.data.distance*this.data.point.y/length;
                this.data.point.z = this.data.distance*this.data.point.z/length;
                this.iconbtn.setAttribute("position", this.data.point);
            }
        }
    },

    init:function() {
        let data = this.data;
        if(data.mode == 2) {
            let camera = document.querySelector("#cam");

            let hotspotID = this.el.id;

            let openbtnID = `openbtn-${hotspotID}`;
            let iconbtnID = `icon-${hotspotID}`;
            let highlightID = `highlight-${hotspotID}`;

            this.open = document.getElementById(openbtnID);
            this.iconbtn = document.getElementById(iconbtnID);
            this.highlight = document.getElementById(highlightID);

            this.open.addEventListener("mousedown", (evt) => {
                this.data.moving = true;

                this.data.position = this.iconbtn.object3D.position;

                this.offset = {
                    x: this.data.position.x - evt.detail.intersection.point.x,
                    y: this.data.position.y - evt.detail.intersection.point.y,
                    z: this.data.position.z - evt.detail.intersection.point.z,
                };

                camera.setAttribute("look-controls-enabled", false);

            });

            this.open.addEventListener('raycaster-intersected', evt => {
                this.raycaster = evt.detail.el;
            });
            this.open.addEventListener('raycaster-intersected-cleared', evt => {
                this.raycaster = null;
            });

            this.open.addEventListener("mouseup", () => {
                if(!interacionManager.wasLastInteractionDrag()){
                    window.parent.postMessage({action: "hotspot-selected", data: hotspotID.split("_")[1]}, "*");
                }
                this.data.moving = false;
                camera.setAttribute("look-controls-enabled", true);

                let originalVector, highlightVector;
                originalVector = highlightVector = this.iconbtn.getAttribute("position");

                window.dispatchEvent(new CustomEvent("hotspot-position-update", { detail: {id: hotspotID.split("_")[1], position: originalVector} }));

                let highlightDistance = this.data.distance - 0.5;

                highlightVector.x = highlightDistance*originalVector.x/this.data.distance;
                highlightVector.y = highlightDistance*originalVector.y/this.data.distance;
                highlightVector.z = highlightDistance*originalVector.z/this.data.distance;

                this.highlight.setAttribute("position", highlightVector);

            });
        }

    }
});