import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { default as $t } from "../../../../i18nProvider/translate";
import ModalTriviaAnswer from "../xengine-modal-trivia-answer/xengine-modal-trivia-answer";


const ModalTrivia = (props) => {
    const [selected, setSelected] = useState({});
    const [showResults, setShowResults] = useState(false);
    const [result, setResult] = useState("");
    const [showResultText, setShowResultText] = useState(false);
    const [optionsSelected, setOptionsSelected] = useState(0);
    const [questionsAnswered, setQuestionsAnswered] = useState([]);

    useEffect(() => {
        window.addEventListener("changeResultText", HideResultText)
    }, [])

    const close = () => {
        document.dispatchEvent(new CustomEvent("CloseTriviaModal"));
    };

    const selectedOption = (option) => {
        let newSelected = JSON.parse(JSON.stringify(selected));
        if (newSelected[option] == true) {
            setOptionsSelected(optionsSelected - 1);
            newSelected[option] = false;
        }
        else if (!props.trivia.answers_type == "single" || optionsSelected == 0) {
            setOptionsSelected(optionsSelected + 1);
            newSelected[option] = true;
        } else {
            Object.keys(newSelected).forEach(function (key) {
                newSelected[key] = false;
            });
            newSelected[option] = true;
        }
        setSelected(newSelected);
    };

    const SubmitResponse = () => {
        if (optionsSelected > 0) {
            let result = "success";

            for (let index = 0; index < props.trivia.answers.length; index++) {
                if (props.trivia.answers[index].value == "right" && !selected[index]) {
                    result = "fail";
                    break;
                }
                else if (props.trivia.answers[index].value == "wrong" && selected[index]) {
                    result = "fail";
                    break;
                }
            }
            setQuestionsAnswered([...questionsAnswered, props.trivia.id_tour_creator])
            setShowResults(true);
            setResult(result);
        }
    };

    const HideResultText = () => {
        setShowResultText(false);
    }



    const SwitchResultText = () => {
        setShowResultText(!showResultText);
    };

    if (props.trivia == undefined) {
        return <div />;
    }


    return (
        <div className="xengine-modal-trivia-container" style={{ visibility: props.visible ? "visible" : "hidden" }}>
            <div id="modalTrivia" className={`xengine-modal-trivia   ${result}  ${showResultText ? "lowBrightness" : ""}`}>
                <div className="trivia-nav">
                    <h3>Trivia</h3>
                    <button className="close-button" onClick={close}>✖</button>
                </div>
                <div className="containerTrivia">
                    <div className="triviaQuestion">
                        <h1>{props.trivia.question}</h1>
                        {props.trivia.image &&
                            <img className="imageTrivia" src={props.assets.img.filter(image => image.id == props.trivia.image)[0]?.src} />}
                    </div>
                    {props.trivia.video_url && <video width="auto" height="auto" controls autoPlay="true">
                        <source src={props.trivia.video_url}></source>
                    </video>}
                    <div className={"triviaOptions " + (showResults && questionsAnswered.includes(props.trivia.id_tour_creator) ? "showing-result " : "") + (props.trivia.image ? ' withImage' : ' withoutImage')}>
                        {props.trivia.answers.map((optionTrivia, index) => (
                            <div key={`opcion${index}`} id={`opcion${index}`} className={`${selected[index] == true ? "selected option " : "option "} ${selected[index] && optionTrivia.answer.value == "wrong" ? "wrong " : ""} ${optionTrivia.answer.value == "right" ? "right" : ""} ${props.trivia.answers.length >= 4 ? "fourAnswers" : ""}`} onClick={() => { selectedOption(index) }}>
                                {optionTrivia.answer.image ? <img className="trivia-image" src={props.assets.img.filter(image => image.id == optionTrivia.answer.image)[0]?.src} /> : <div />}
                                <div className={!optionTrivia.answer.image ? "MarginAuto" : ""}>
                                    {showResults && questionsAnswered.includes(props.trivia.id_tour_creator) ? optionTrivia.answer.value == "right" ?
                                        <div className={`check-icon ${optionTrivia.answer.image ? 'absoluteIcon' : ''}`}>
                                            ✔
                                        </div> :
                                        document.getElementById(`opcion${index}`)?.classList.contains('wrong') ?
                                            <div className={`wrong-icon ${optionTrivia.answer.image ? 'absoluteIcon' : ''}`}>
                                                ✖
                                            </div> : null
                                        : <div></div>}
                                    <label> {optionTrivia.answer.text}</label>
                                </div>
                            </div>))}
                        <div className="result-text">{props.trivia.result_stage_text}</div>
                    </div>
                </div>
                {showResults && questionsAnswered.includes(props.trivia.id_tour_creator) ?
                    <div className="containerTriviaButtons">
                        <button className="btnResponse response" onClick={SwitchResultText}>{$t("show-trivia-results")}</button>
                        <button className="btnResponse" onClick={close}>{$t("finish-trivia-answers")}</button>
                    </div>
                    :
                    <div className="containerTriviaButtons"><button className="btnResponse" onClick={SubmitResponse}>{$t("submit-trivia-answers")}</button></div>
                }
            </div>
            {showResultText ? <ModalTriviaAnswer triviaAnswer={props.trivia.result_stage_text} /> : null}
        </div>
    );
};

ModalTrivia.propTypes = {
    visible: PropTypes.bool.isRequired,
    trivia: PropTypes.object,
    assets: PropTypes.object.isRequired,
};

export default ModalTrivia;