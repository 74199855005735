
const LOCALES = {
    SPANISH: "es",
    ENGLISH: "en",
    GERMAN: "de",
    FRENCH: "fr",
};

export {
    LOCALES
};

