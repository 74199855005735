import React from "react";
import PropTypes from "prop-types";

//TODO this almost the same as  xenginebtn reactions, eliminate xenginebtn reactions and just leave this one
const XEngineBtn = (props) => {
    return (
        <div onClick={props.onClick} id={props.id} className={`xengine-btn ${props.class}`}>
            <i className={`fas fa-${props.icon}`}></i>
            <span id={props.textId} className="menu-btn-text">{props.text}</span>
            {props.imageURL && <img id={`image-${props.id}`} src={props.imageURL} />}
        </div>
    );
};

XEngineBtn.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
    id: PropTypes.string.isRequired,
    class: PropTypes.string,
    imageURL: PropTypes.string,
    text: PropTypes.string,
    textId: PropTypes.string,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
};


export default XEngineBtn;
