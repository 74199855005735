import React from "react";
import {Entity, Scene} from "aframe-react";
import { directionToVector } from "../../../helpers";
import PropTypes from "prop-types";

const TriviaHotspot = (props) => {
    const { triviaHotspot, id } = props;
    const btnPosition = directionToVector(triviaHotspot.position.origin, triviaHotspot.position.x_angle, triviaHotspot.position.y_angle, triviaHotspot.position.distance);
    const modeParam = new URLSearchParams(location.search).get("j");
    const mode = modeParam === null ? 0 : Number(modeParam);

    return <Entity class="trivias-container">
        {/* Hotspot btn component */}
        <Entity
            trivia-hotspot={`origin:${btnPosition};position:${JSON.stringify(triviaHotspot.position)};mode:${mode};trivia:${JSON.stringify(triviaHotspot)}`}
            position={btnPosition}
            lookatcamera
            move-trivia={`mode: ${mode}`}
            scale="1.5 1.5 1.5"
            id={id}
            interactive-object
            animation__scale="property: scale; dir: alternate; to: 1.6 1.6 1.6; easing: easeInOutQuad; loop: true; dur: 1000; pauseEvents: mouseenter; resumeEvents: mouseleave"
        >
            <a-image id={`openbtn-trivia-${id}`} fit-texture class="clickable" src="/img/preguntas.png" material="transparent: false; alphaTest: 0.5"></a-image>
        </Entity>
    </Entity>;
};

TriviaHotspot.propTypes = {
    triviaHotspot: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};

export default TriviaHotspot;
