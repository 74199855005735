import React from "react";

const Notification = () => {
    return (
        <div id="notification" className="notification">
            Usuario <i className="fas fa-hand-paper"></i>
        </div>
    );
};

export default Notification;
