import React from "react";
import GoogleAuth from "../../platform/google-auth/google-auth";
import axios from "axios";
import User from "../../../models/user";
import { management, userLoggedIn } from "../../../managers/api/api-manager";
import ReCaptcha from "react-google-invisible-recaptcha";
import ReactGA from "react-ga";
import { default as $t } from "../../../i18nProvider/translate";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.isLoggedIn();
        this.state = {
            first_name: "",
            last_name: "",
            password: "",
            confirmPassword: "",
            email: "",
            errors: {
                username: [],
                password: [],
                email: []
            },
            recaptchaToken: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleGmailSubmit = this.handleGmailSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    findErrors(error) {        
        let emailErrors = [];
        let passwordErrors = [];

        try {
            passwordErrors.push(error.password[0]);
        }
        catch (error) {
            // Continue regardless of error
        }

        try {
            emailErrors.push(error.email[0]);
        }
        catch (error) {
            // Continue regardless of error
        }
        try{
            if(this.isGmail && error.email[0]=="Email is already registered."){
                this.logUser();
            }
        }catch{
            // Continue regardless of error
        }

        this.recaptcha.reset();
        this.setState({ errors: { email: emailErrors, password: passwordErrors } });
    }

    handleLogin(event) {
        event.preventDefault();
        const queryString = window.location.search;

        location.href = location.origin + "/login" + queryString;
    }

    validateForm() {
        let valid = this.state.first_name.length > 0 && this.state.last_name.length > 0 && this.state.password.length > 0 && this.state.confirmPassword.length > 0;
        valid &= this.state.password === this.state.confirmPassword;
        return valid;
    }


    handleGmailSubmit(response) {
        this.setState({ first_name: response.profileObj.givenName });
        this.setState({ last_name: response.profileObj.familyName });
        this.setState({ email: response.profileObj.email });
        this.setState({ password: response.googleId });
        this.handleSubmit(response, true);
    }

    isLoggedIn() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (userLoggedIn(window.localStorage.getItem("token"), window.localStorage.getItem("refresh_token"))) {
            let redirectUrl = urlParams.get("u");
            if (redirectUrl) {
                location.href = redirectUrl;
            } else {
                location.href = "/";
            }
        }
    }

    componentDidMount() {
        ReactGA.pageview("/register");
    }

    logUser() {
    //Logs user in once we registered the user
        management.getJWTToken(this.state.email, this.state.first_name, this.state.last_name, this.state.password, this.recaptcha.getResponse())
            .then(token => {
                axios.defaults.headers.common["Authorization"] = `Bearer ${token.access}`;
                management.refresh_token = token.refresh;
                localStorage.setItem("token", token.access);
                localStorage.setItem("refresh_token", token.refresh);
                management.getUserWithToken()
                    .then(response => {
                        let data = response.user_info;
                        let user = new User(data.id, data.email, data.first_name,
                            data.last_name, data.groups, data.username);
                        window.localStorage.setItem("user", JSON.stringify({
                            groups: user.groups, username: user.username, experiences: response.experiences
                        }));
                        this.isLoggedIn();
                    })
                    // eslint-disable-next-line no-console
                    .catch(error => console.error(error));
            })
            // eslint-disable-next-line no-console
            .catch(errors => console.error(errors));
    }

    verifyCallback() {

        management.registerUser(this.state.password, this.state.first_name,
            this.state.last_name, this.state.email, ["client"], this.recaptcha.getResponse())
            .then(() => this.logUser())
            .catch(error => this.findErrors(error.response.data));

    }

    handleSubmit(event, isGmail) {
        if (event.preventDefault) {
            event.preventDefault();
        }

        this.isGmail = isGmail;
        this.recaptcha.execute();
    }

    
    render() {
        return (
            <div className="register">
                <form onSubmit={this.handleSubmit}>
                    <div className="title">
                        <h1>{$t("create-account")}</h1>
                        <div className="link">
                            {$t("are-you-already-registered?")}
                            <a href="" onClick={this.handleLogin}>{$t("log-in")}</a>
                        </div>
                    </div>
                    <div style={{marginBottom:"6em"}}>
                        
                        <div className="container">
                            <label htmlFor="uname"><b>{$t("name")}</b></label>
                            <input type="text" placeholder="Name" name="uname" required value={this.state.first_name}
                                onChange={e => this.setState({ first_name: e.target.value })} />
                            <label htmlFor="uname"><b>{$t("last-name")}</b></label>
                            <input type="text" placeholder="Last name" name="uname" required value={this.state.last_name}
                                onChange={e => this.setState({ last_name: e.target.value })} />
                            <label htmlFor="email"><b>{$t("email")}</b></label>
                            <input type="email" placeholder="Email" name="email" required value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })} />
                            <label htmlFor="psw"><b>{$t("password")}</b></label>
                            <input type="password" placeholder="Password" name="psw" required value={this.state.password}
                                onChange={e => this.setState({ password: e.target.value })} />

                            <label htmlFor="cpsw"><b>{$t("confirm-password")}</b></label>
                            <input type="password" placeholder="Confirm password" name="cpsw" required value={this.state.confirmPassword}
                                onChange={e => this.setState({ confirmPassword: e.target.value })} />
                            {this.validateForm()?<button className="enabled-button" type="submit" disabled={false}>{$t("sign-in")}</button>:<button className="disabled-button" type="submit" disabled={true}>{$t("sign-in")}</button>                            }
                            
                            <ReCaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={$PROCESS_ENV_RECAPTCHA_KEY || "6LcWGtMZAAAAADUQ2VyMan61_8o06M8TGJjDsR5u"}
                                onResolved={this.verifyCallback}
                            />
                        </div>
                        <div className="separator" />
                        <div className="container">
                            <GoogleAuth className="buttonGoogle" text={$t("continue-with-gmail")} onSuccess={this.handleGmailSubmit} />
                        </div>
                        <p className="errors">
                            {this.state.errors["email"]}
                            {this.state.errors["password"]}
                        </p>
                    </div>

                </form>
            </div>
        );
    }
}

export default Register;
