/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

AFRAME.registerComponent("loadhandler", {
    init: function () {
        let enterBtn = document.getElementById("enter_btn");

        this.el.addEventListener("loaded", function () {
            enterBtn.setAttribute("scale", { x: 1, y: 1, z: 1 });
        });
    }
});
