import { closeHotSpotVR, hideObjectsNoVRAR, showObjectsNoVRAR } from "../../helpers"

AFRAME.registerComponent('cursor-controller', {
    init: function () {
        var scene = document.querySelector('a-scene');

        if (scene.hasLoaded) {
            run();
        } else {
            scene.addEventListener('loaded', run);
        }

        function run() {
            document.querySelector('a-scene').addEventListener('enter-vr', function () {
                document.getElementById("cursorARVR").setAttribute("cursor", "fuse: true; fuseTimeout: 1000")
                document.getElementById("cursorARVR").setAttribute("geometry", "primitive:sphere; radius:0.06;")
                document.getElementById("cursorARVR").setAttribute("position", "0 0 -2")
                //Hide all the objects that don't work on VR o AR mode
                hideObjectsNoVRAR()

            });
            document.querySelector('a-scene').addEventListener('exit-vr', function () {
                document.querySelector('a-scene').setAttribute("cursor", "rayOrigin: mouse; fuseTimeout: 0;")
                document.getElementById("cursorARVR").removeAttribute("cursor")
                document.getElementById("cursorARVR").removeAttribute("geometry")
                document.getElementById("cursorARVR").setAttribute("position", "55 55 55")
                //function for when closed from button on hotspot
                closeHotSpotVR()
                //show all the objects because you aren't on vr o ar mode
                showObjectsNoVRAR()
            });
        }
    }
});