/**
 * @fileoverview 
 * This file imports all our required packages.
 * It also includes 3rd party A-Frame components.
 * Finally, it mounts the src to the root node.
 */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { HelmetMetaData }  from "./helpers";
import "./components/app.scss";

//TODO: refactor. Redirect to https when in prod and in http
if(window.location.hostname.includes("immerso") && location.protocol !== "https:"){
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
}

document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render(<App />, document.querySelector("#root"));
});
