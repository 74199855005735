AFRAME.registerShader("highlight", {
    // The schema declares any parameters for the shader.
    schema: {
    // Where relevant, it is customary to support color.
    // The A-Frame schema uses `type:'color'`, so it will parse string values like 'white' or 'red.
    // `is:'uniform'` tells A-Frame this should appear as uniform value in the shader(s).
        color: { type: "color", is: "uniform", default: "red" },
        opacity: { type: "number", is: "uniform", default: 1.0 },
        // It is customary to support opacity, for fading in and out.
        image: { type: "map", is: "uniform" }
    }, vertexShader:
    `
    varying vec2 vUv;

    void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
  `

    , fragmentShader:
    `
  // Use medium precision.
  precision mediump float;

  // This receives the color value from the schema, which becomes a vec3 in the shader.
  uniform vec3 color;
  uniform float opacity;
  varying vec2 vUv;
  uniform sampler2D image;

  // This receives the opacity value from the schema, which becomes a number.

  // This is the shader program.
  // A fragment shader can set the color via gl_FragColor,
  // or decline to draw anything via discard.
  void main () {
    // Note that this shader doesn't use texture coordinates.
    // Set the RGB portion to our color,
    // and the alpha portion to our opacity.
    gl_FragColor = vec4(color, texture2D( image, vUv ).a * opacity);
  }
`
});
