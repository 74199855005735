class User {
    /**
   * 
   * @param {number} id 
   * @param {string} email 
   * @param {string} first_name 
   * @param {string} last_name 
   * @param {number[]} groups 
   * @param {string} username 
   * @param {object} currentExperience
   */
    constructor(id, email, first_name, last_name, groups, username, currentExperience) {
        this.id = id;
        this.email = email;
        this.first_name = first_name;
        this.last_name = last_name;
        this.groups = groups;
        this.username = username;
        this.currentExperience = currentExperience;
    }

    getFullName() {
        return `${this.first_name} ${this.last_name}`;
    }

    isGuide() {
        return this.currentExperience.guides.includes(
            this.id
        );
    }

    isAdmin() {
        return this.groups.filter(group => group === 4).length > 0;
    }
}

export default User;
