import axios from "axios";
import { management } from "../managers/api/api-manager";

export class ManagementAPI {

    constructor() {
        this.base_url = "https://api.immersotours.com";
        this.experience = undefined;
        this.refresh_token = window.localStorage.getItem("refresh_token");
        this.token = window.localStorage.getItem("token");
        if (this.token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
            axios.defaults.headers.common["Accept-Language"] = localStorage.getItem("locale");
        }
    }

    static get(url) {
        let locale = localStorage.getItem("locale");
        if (locale)
            axios.defaults.headers.common["Accept-Language"] = localStorage.getItem("locale");
        return new Promise((resolve, reject) => {
            // First attempt
            axios.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(errors => {
                    // If errors we check if we need to refresh token
                    if (management.refresh_token) {
                        management.refreshAccessToken(url, resolve, reject);

                    } else {
                        reject(errors);
                    }
                });
        }
        );
    }

    refreshAccessToken(url, resolve, reject) {
        return new Promise(() => {
            axios.post(`${management.base_url}/api/token/refresh/`,
                {
                    refresh: localStorage.getItem("refresh_token")
                }
            ).then(
                res => {
                    //Once we successfully refresh the token, second attempt
                    localStorage.setItem("token", res.data.access);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.access}`;

                    if (url && resolve && reject) {
                        //url, resolve and reject is used just in management.get to retry a get request in case it fails
                        axios.get(url)
                            .then(second_res => resolve(second_res.data))
                            .catch(errors => reject(errors));
                    }
                }
            ).catch(
                errors => {
                    window.localStorage.removeItem("token");
                    window.localStorage.removeItem("refresh_token");
                    window.location.href = window.location.origin;
                    if (url && resolve && reject)
                        reject(errors);
                }
            );
        }
        );
    }

    async getUserWithToken() {
        return await ManagementAPI.get(`${this.base_url}/api/user/`);
    }

    async registerEmail(email, recaptcha) {
        return await (
            axios.post(`${this.base_url}/api/email/`,
                {
                    email: email,
                    recaptcha
                }
            )
        );
    }


    registerUser(password, first_name, last_name, email, groups, recaptcha) {
        return new Promise((resolve, reject) => {
            axios.post(`${this.base_url}/api/user/register/`,
                {
                    username: email,
                    password,
                    first_name,
                    last_name,
                    email,
                    groups,
                    recaptcha
                }
            )
                .then(res => {
                    resolve(res.data);
                })
                .catch(errors => {
                    reject(errors);
                });
        }
        );
    }

    getJWTToken(email, first_name, last_name, password, recaptcha, isGmail) {
        return new Promise((resolve, reject) => {
            axios.post(`${this.base_url}/api/token/`,
                {
                    username: email,
                    email,
                    first_name,
                    last_name,
                    password,
                    recaptcha,
                    isGmail
                }
            )
                .then(res => resolve(res.data))
                .catch(errors => reject(errors));
        }
        );
    }

    async verifyToken() {
        let token = localStorage.getItem("token");
        if (token) {
            return await axios.post(`${this.base_url}/api/token/verify/`,
                {
                    token
                }
            )
                .catch(errors => {
                    if (errors.response.data.code === "token_not_valid") {
                        this.refreshAccessToken();
                    }
                });

        }
    }

}
