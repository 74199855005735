import React, { useEffect, useState } from "react";
import ExperienceCard from "../experience-card/experience-card";
import { api } from "../../../managers/api/api-manager";
import { default as $t } from "../../../i18nProvider/translate";
import { LoadingScreen } from  "../../xengine/loading-screen/loading-screen";


const ExperienceShow = (props) => {
    let [experienceList, setList] = useState([]);
    useEffect(() => {
        api.getExperienceList().then(response => {
            let newList = response.map(experience => {

                return {
                    id: experience.id,
                    name: experience.name,
                    slug: experience.slug,
                    location: experience.location,
                    price: experience.price,
                    labels: $t(experience.tags),
                    thumbnail: experience.thumbnail,
                    url: experience.url,
                    comingSoon: experience.coming_soon,
                    region: experience.region
                };
            });

            setList(newList);
        });

    }, []
    );

    let experienceId = 0;
    const list = experienceList.sort((a,b) => {
        return (b.comingSoon? 0 : 1) - (a.comingSoon? 0 : 1);
    }).map((experience) => {
        experienceId++;
        if(props.region == "all" || experience.region[0]?.slug == props.region) return <div className='col-xl-auto col-lg-auto col-md-auto col-sm-auto col-auto mb-5' key={`card-${experienceId}-${experience.id}`}>
            <ExperienceCard experience={experience} />
        </div>;
    }

    );

    if (experienceId === 0){
        return <LoadingScreen backgroundStyles={{backgroundColor: "#ffffff"}} ></LoadingScreen>;
    }
    else{
        return (
            <div className="row align-items-center justify-content-center" style={{display: "flex"}}>
                {list}
            </div>
        );}    
};

export default ExperienceShow;
