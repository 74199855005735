import audioManager from "../audio/audio-manager";
import { management } from "../api/api-manager";
import { cameraManager } from "../camera/camera-manager";
import videoManager from "../video/video-manager";
import { TRANSITIONDURATION } from "../../constants";

export function startMoveToLocation(targetLocationID) {
  let currentLocationID = window.localStorage.getItem("locationID");
  let nextLocation;
  let mode =
    new URLSearchParams(location.search).get("j") === null
      ? 0
      : new URLSearchParams(location.search).get("j") == 1
      ? 1
      : new URLSearchParams(location.search).get("j");

  if (targetLocationID == currentLocationID) return;

  nextLocation = management.experience.locations.filter(
    (location) => location.id_tour_creator == targetLocationID
  )[0];

  let nextLocationIndex = management.experience.locations.indexOf(nextLocation);

  window.sessionStorage.setItem("actualLocationIndex", nextLocationIndex);

  if (nextLocation) {
    let targetRigYRotation = nextLocation.default_rotation?.y || 0;
    audioManager.playTransitionSound();
    cameraManager.moveToPoint(1, 1.3, TRANSITIONDURATION, "easeInSine");

    //verify if is loaded
    setTimeout(() => {
      let loadedBackgrounds =
        window.sessionStorage.getItem("loadedBackgrounds");
      if (
        mode != 2 &&
        !loadedBackgrounds.includes(nextLocation.bg_id) &&
        nextLocation.bg_type == "img"
      ) {
        let eventName = "transition-" + nextLocation.bg_id;
        let loadingScreen = document.getElementById("loading-screen");
        loadingScreen.classList.remove("invisible");

        window.localStorage.setItem("locationRigYRotation", targetRigYRotation);
        window.sessionStorage.setItem("lastLocationID", targetLocationID);
        window.sessionStorage.setItem(
          "lastLocationRigYRotation",
          targetRigYRotation
        );

        const assetsEvent = new CustomEvent("assetstransition");
        document.dispatchEvent(assetsEvent);

        document.addEventListener(eventName, () => {
          setTimeout(() => {
            loadingScreen.classList.add("invisible");
            finishMoveToLocation(
              targetLocationID,
              targetRigYRotation,
              currentLocationID,
              nextLocation,
              mode
            );
          }, 1);
        });
        return;
      }
      finishMoveToLocation(
        targetLocationID,
        targetRigYRotation,
        currentLocationID,
        nextLocation,
        mode
      );
    }, TRANSITIONDURATION);
  }
}

export function finishMoveToLocation(
  targetLocationID,
  targetRigYRotation,
  currentLocationID,
  nextLocation,
  mode
) {
  window.sessionStorage.setItem("hotspotOpen", false);

  let currentLocation;

  if (currentLocationID === undefined || currentLocationID == null) {
    currentLocation = management.experience.locations[0];
  } else {
    currentLocation = management.experience.locations.filter(
      (location) => location.id_tour_creator == currentLocationID
    )[0];
  }

  // Set ui location name
  let locationName = document.getElementById("xengine-location-name");
  locationName.innerHTML = nextLocation.name;

  let visitedLocations = window.localStorage.getItem("visitedlocations");
  if (visitedLocations === null) {
    visitedLocations = [];
  } else {
    visitedLocations = visitedLocations.split(",");
  }

  if (!visitedLocations.includes(currentLocationID)) {
    visitedLocations.push.apply(visitedLocations, [currentLocationID]);
  }

  window.localStorage.setItem("locationID", targetLocationID);
  const locationEvent = new CustomEvent("locationChange");
  window.dispatchEvent(locationEvent);

  window.localStorage.setItem("locationRigYRotation", targetRigYRotation);
  window.sessionStorage.setItem("lastLocationID", targetLocationID);
  window.sessionStorage.setItem("lastLocationRigYRotation", targetRigYRotation);

  const assetsEvent = new CustomEvent("assetstransition");
  document.dispatchEvent(assetsEvent);

  let sky = document.getElementById("skybox");

  if (nextLocation.bg_type == "img") {
    //set the skybox source to the new image as per the spot
    sky.setAttribute("src", `#img-${nextLocation.bg_id}`);

    const lastImageSource = document.querySelector(
      `#img-${currentLocation.bg_id}`
    )?.src;
    Object.keys(AFRAME.scenes[0].systems.material.textureCache).forEach(
      (key) => {
        if (key[0] == "{") {
          const textureData = JSON.parse(key);

          if (textureData.src == lastImageSource) {
            AFRAME.scenes[0].systems.material.textureCache[key].then(function (
              texture
            ) {
              texture.dispose();
            });
          }
        }
      }
    );
    //TODO improve dev mode (currentlocation undefined)
    if (currentLocation.bg_type == "video") {
      videoManager.stopVideo();
    }
  } else if (nextLocation.bg_type == "video") {
    //set the skybox source to the new image as per the spot
    let video = document.getElementById(`video-${nextLocation.video_id}`);
    video.volume = audioManager.volume;

    if (currentLocation.bg_type == "video") {
      videoManager.stopVideo();
    }

    videoManager.setVideo(video);
  }

  let nextAudio = document.querySelector(`#sound-${nextLocation.sound_id}`);
  if (nextAudio) {
    audioManager.playBackgroundSound(nextAudio.src);
  }

  let controlAudio = document.getElementById("controlAudio");
  let nextAudioLocation = document.querySelector(
    `#sound-${nextLocation.audio_id}`
  );
  if (
    window.sessionStorage.getItem("in-vr") &&
    currentLocation.waypoints.length == 0
  ) {
    const duration = nextAudioLocation?.duration || 15;
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("trigger-next-location"));
    }, Math.max(20, duration + 5) * 1000);
  }

  if (nextAudioLocation && mode == 0) {
    if (!visitedLocations.includes(`${nextLocation.id_tour_creator}`)) {
      audioManager.playAudioLocation(nextAudioLocation.src);
      controlAudio.style.visibility = "visible";
    } else {
      if (!audioManager.audioLocation.playing()) {
        audioManager.audioLocation = nextAudioLocation.src;
      }
      controlAudio.style.visibility = "visible";
    }
  }

  let spotcomp = document.getElementById("spots");
  //create event for spots component to change the spots data
  spotcomp.emit("reloadspots", {
    newspots: `group-${targetLocationID}`,
    currspots: `group-${currentLocationID}`,
  });
  let hotspotscomp = document.getElementById("hotspots");
  hotspotscomp.emit("reloadhotspots", {
    newhotspots: `hotspots-${targetLocationID}`,
    currhotspots: `hotspots-${currentLocationID}`,
  });

  let rig = document.querySelector("#rig");
  let rotateAnim = rig.getAttribute("animation__rotate");
  rotateAnim.enabled = false;
  rig.setAttribute("animation__rotate", rotateAnim);

  cameraManager.lookToPoint(targetRigYRotation);

  if (!visitedLocations.includes(targetLocationID)) {
    visitedLocations.push.apply(visitedLocations, [targetLocationID]);
    window.localStorage.setItem("visitedlocations", visitedLocations);
  }

  cameraManager.moveToPoint(0.7, 1, TRANSITIONDURATION, "easeOutSine");
}
