/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for finish the loading screen when all
 * assets have loaded.
 */

// import { mobileAndTabletCheck } from "../../helpers";

AFRAME.registerComponent("loader", {
    init: function() {
        // Wait for assets to load.
        this.el.addEventListener("loaded", () => {
            const assetsLoadedEvent = new CustomEvent("assetsloaded");
            document.dispatchEvent(assetsLoadedEvent);
            let loadingScreen = document.getElementById("loading-screen");
            //reload check because of delay set in xengine
            let lastLocationId = window.sessionStorage.getItem("lastLocationID");
            if(lastLocationId && lastLocationId != "null"){
                setTimeout(() => loadingScreen.classList.add("invisible"), 4000);
            } else {
                loadingScreen.classList.add("invisible");
            }

            // Show tutorial for only 5 seconds
            // if(mobileAndTabletCheck()){
            // setTimeout(() => {
            //     let moveImage = document.getElementById("moveImageTutorial");
            //     let moveImageBorder = document.getElementById("moveImageTutorialBorder");
            //     if(moveImage){
            //         moveImage.style.display="none";
            //         moveImageBorder.style.display="none";
            //     }
            // }, 5000);
            // }
        });
    },
});
  