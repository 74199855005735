import React from "react";
import ExperienceFooter from "../platform/experience-footer/experience-footer";
import PlatformNavbar from "../platform/platform-navbar/platform-navbar";
import PropTypes from "prop-types";

const PageContainer = (props) => {
    return <div>
        <PlatformNavbar/>
        {props.children}
        <ExperienceFooter/>
    </div>;
};

PageContainer.propTypes = {
    children: PropTypes.object.isRequired
};

export default PageContainer;