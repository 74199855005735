/**
 * @fileoverview
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */
import { closeHotSpotVR } from "../../helpers";

window.AFRAME.registerComponent("close-vr-hotspot", {
  init: function () {
    this.el.addEventListener("click", function () {
      closeHotSpotVR();
    });

    // On custom event locationChange close Hotspot VR
    window.addEventListener("locationChange", function () {
      closeHotSpotVR();
    });
  },
});
