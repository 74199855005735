import { AES, enc } from "crypto-js";
/* global $PROCESS_ENV_AES_KEY  */

class Settings {
    constructor(){
        this.developmentMode = false;
        this.storageName = this.developmentMode ? "settings": "l3"; // l3 is arbritary, used to obfuscate in production mode
        if(!window.localStorage.getItem(this.storageName)){
            const value = this.developmentMode ? "{\"autoAction\": true}" : AES.encrypt("{\"autoAction\": true}", "");
            window.localStorage.setItem(this.storageName, value);
        }
        this.autoAction = this.getFromStorage("autoAction") || true;
    }

    get autoAction() {
        return this.getFromStorage("autoAction");
    }

    set autoAction(value) {
        this.setFromStorage("autoAction", value);
    }

    getFromStorage(setting) {
        const encrypted = window.localStorage.getItem(this.storageName);
        if(!encrypted)
            return;
        else if(this.developmentMode) {
            return JSON.parse(encrypted)[setting];
        }else{
            let wordArray = AES.decrypt(encrypted, "");
            return JSON.parse(wordArray.toString(enc.Utf8))[setting];
        }
    }

    setFromStorage(setting, newValue){
        let encrypted = window.localStorage.getItem(this.storageName);
        if(!encrypted)
            return;
        else if(this.developmentMode) {
            encrypted = JSON.parse(encrypted);
        }else{
            if(!encrypted)
                encrypted = {};
            else{
                let wordArray = AES.decrypt(encrypted, "");
                encrypted = JSON.parse(wordArray.toString(enc.Utf8));
            }
        }

        encrypted[setting] = newValue;

        if(this.developmentMode) {
            window.localStorage.setItem(this.storageName, JSON.stringify(encrypted));
        }else{
            let wordArray = AES.decrypt(encrypted, "");
            window.localStorage.setItem(this.storageName, wordArray.toString(enc.Utf8));
        }
    }
}
const settings = new Settings();
export default settings;
