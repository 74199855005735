import { LOCALES } from "../constants";

export default {
    [LOCALES.SPANISH]: {

        //Dictionary
        "activate-microphone": "Activar micrófono",
        "are-you-already-registered?": "¿Ya estas registrado?",

        "bank-account-name":  "Cuenta a nombre de:",
        "banner-1": "Visita tus lugares preferidos desde la comodidad de tu hogar.",
        "banner-2": "Explore el mundo de la mano de Immerso",
        "blog": "Blog",
        "clients": "Empresas",
        "buy-experience": "Comprar experiencia",

        "card-call-to-action": "¡Conocer más!",
        "categories": "Categorías",
        "create-account": "Crear cuenta",
        "comming-soon-1": "Pronto más",
        "comming-soon-2": "experiencias",
        "confirm-password": "Confirmar contraseña",
        "contact": "Contacto",
        "contact-line": "Comunícate con el equipo de Immerso mediante las siguientes redes sociales o por medio de un correo a info@theimmerso.com Nuestro equipo te contactará para guiarte en esta nueva experiencia",
        "contact-us": "Contáctenos",
        "contribute":  "Selecciona cuanto quieres aportar",
        "currency-sign": "₡",

        "description": "Descripción",

        "email": "Correo electrónico",
        "email-to-immerso-btn": "Envíanos un correo",
        "enter-paypal": "Apóyanos por medio de PayPal, dando click al botón:",
        "exit": "Ver más tours",
        "experience": "experencia",
        "experience-name": "Experiencia",
        "experiences": "experencias",
        "exploration": "Tours",
        "home": "Home",
        "create": "Crear",

        "failed-subscription":"Algo salió mal, verifica el correo electrónico e inténtalo de nuevo.",
        "follow-the-guide": "Seguir al Guía",
        "free": "Gratis",
        "fullscreen": "Pantalla Completa",

        "guided-by": "Guiado por",
        "guided-sessions": "Próximas Sesiones Guiadas",
        "guided-session-message": "Hay una experiencia guiada en este momento, ¿Desea ingresar?",
        "guided-session-join": "Ingresar al tour guiado",

        "keep-updated":"Te mantendremos informado!",

        "last-name": "Apellido",
        "loading": "Cargando",
        "location": "Ubicación",
        "log-in": "Ingresar",
        "login-to-Immerso": "Login",
        "log-out": "Cerrar Sesión",
        "continue-with-gmail": "Continuar con Gmail",
        "failed-log-in": "Correo o contraseña incorrecta",
        "lower-volume": "Bajar Volumen",

        "menu": "Menú",
        "minimize": "Minimizar",
        "mute-microphone": "Mutear micrófono",

        "name": "Nombre",
        "notice": "¡Aviso!",
        "not-registered?": "¿No estas registrado?",

        "oops":"Ups",

        "packages": "Paquetes",
        "participants": "Participantes",
        "password": "Contraseña",
        "payment-explanation": "Crear estas experiencias conlleva recursos, tiempo y dedicación de Immerso y de los guías turísticos, por esto solicitamos un pago para apoyar al guía turístico a continuar trabajando y con esto mantener la plataforma de Immerso activa.",
        "price": "Precio",

        "quote":  "Cada ayuda es un paso más en el camino del crecimiento",

        "raise-your-hand": "Levantar la mano",
        "rights-reserved": "Todos los derechos reservados",
        "reactions": "Reacciones",
        "google-calendar":"Añadir al calendario de google",
        "google-login-needed":"Se necesita haber iniciado sesión a Gmail para agregar el evento a Google Calendar",

        "see-video-guide": "Ver video del guía",
        "select-language": "Seleccione el idioma",
        "send-us-an-email": "Envíanos un correo",
        "show-survey": "Mostrar la encuesta a todos",
        "sign-in": "Registrarse",
        "sign-gmail": "Registrarse con Gmail",
        "sinpe-description":  "El pago también se puede efectuar por medio de Sinpe Móvil o transferencia bancaria con los siguientes datos:",
        "sinpe-destiny": "SINPE Móvil destino:",
        "sinpe-or-bank-transfer":  "SINPE Móvil o Transferencia bancaria",
        "site-page": "Página del lugar",
        "soon": "Próximamente",
        "soon-more": "Pronto más",
        "standard-screen": "Pantalla estándar",
        "start-experience": "Iniciar Tour",
        "subject":  "Motivo:",
        "subscribe-immerso": "Subscríbase, para saber más sobre los tours de Immerso",
        "subscription-successful":"Subscripción exitosa",

        "to-subscribe": "Subscribirse",
        "tour-guides": "Guías turísticos",
        "turn-up-the-volume": "Subir volumen",

        "voluntary-payment": "Pago Voluntario",

        //Experience Tags
        "demo": "Demostración",
        "nature": "Naturaleza",
        "cultural": "Cultural",

        //Months
        "january": "Enero",
        "february": "Febrero",
        "march": "Marzo",
        "april": "Abril",
        "may": "Mayo",
        "june": "Junio",
        "july": "Julio",
        "august": "Agosto",
        "september": "Septiembre",
        "october": "Octubre",
        "november": "Noviembre",
        "december": "Diciembre",

        //Months short mode
        "jan": "Ene",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Abr",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Ago",
        "sep": "Sep",
        "oct": "Oct",
        "nov": "Nov",
        "dec": "Dic",

        //Date format
        "date": "{day} de {month} a las {time}",

        //XEngine
        "start-waypoint": "Iniciar",
        "landscape-mode": "Por favor, active el modo horizontal",
        "auto": "AUTO",
        "automatic-mode": "Modo automático",
        "move-image":"Toca y arrastra para ver alrededor",
        "mobile-image":"Mueve el dispositivo para ver alrededor",

        //Feedback Form Code
        "feedback-form": "(function(t,e,s,o){var n,c,l;t.SMCX=t.SMCX||[],e.getElementById(o)||(n=e.getElementsByTagName(s),c=n[n.length-1],l=e.createElement(s),l.type=\"text/javascript\",l.async=!0,l.id=o,l.src=\"https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd9fgcw0KfgCkH6wPkkoVnBNqZq0oR_2FpFleYOII2LbAGa.js\",c.parentNode.insertBefore(l,c))})(window,document,\"script\",\"smcx-sdk\");",

        //Event description
        "event-description": "¡Hola {connectedUser.user_info.first_name}{connectedUser.user_info.last_name}! Te invitamos a participar de la experiencia virtual\"{experience.name}\", para ingresar solo debes darle click a este link en la fecha agendada: https://theimmerso.com/experience/{experience.slug}. Para más información puedes visitar nuestro facebook: https://www.facebook.com/immersoofficial. ¡Bienveniodo al mundo del turismo virtual, te esperamos!",

        //Menu static 
        "review":"Evaluar",
        "info":"Información",
        "share": "Compartir",
        "right": "Ubicación siguiente",
        "left": "Ubicación pasada",

        //Call to Action
        "call-to-action-bookings-text": "¡Reservar Ahora!",
        "call-to-action-tickets-text": "¡Compra Tu Entrada Ahora!",
        "call-to-action-contacts-text": "¡Escribenos Ahora!",
        
        //Sponsors
        "sponsors":"Presentado por",

        //Trivias
        "show-trivia-results":"Saber más",
        "show-trivia-answers":"Respuestas",
        "submit-trivia-answers":"Responder",
        "finish-trivia-answers":"Terminar",

        //Trivia Explication
        "explanation-trivia":"Explicación",
        "trivia-explanation-understood": "Entendido",
    }
};
