import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { mobileAndTabletCheck } from "../../../../helpers";
import { default as $t } from "../../../../i18nProvider/translate";
import SponsorPanel from "../sponsor-panel/sponsor-panel";

const NavigationMenu = (props) => {
  const [open, setOpen] = useState(false);
  const [actualLocation, setActualLocation] = useState();
  const locations = JSON.parse(JSON.stringify(props.locations));

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setActualLocation(window.localStorage.getItem("locationID"));

    window.addEventListener("locationChange", () => {
      setActualLocation(window.localStorage.getItem("locationID"));
    });
  }, []);

  let style = {
    backgroundColor: props.colors.primary_light + "88",
    color: props.colors.primary_dark,
  };

  let buttonStyle = {
    backgroundColor: props.colors.primary_light + "88",
    border: `0.25rem solid ${props.colors.primary_dark}`,
    color: props.colors.primary_dark,
  };

  return (
    <div
      className={`navigation-menu ${open ? "open" : undefined}`}
      style={style}
    >
      {/* <SponsorPanel sponsors={props.sponsors}/> */}
      <div className="locations">
        {locations
          .sort(function (a, b) {
            if (a.order < b.order) {
              return -1;
            }
            if (a.order > b.order) {
              return 1;
            }
            return 0;
          })
          .map((location) => {
            return (
              <div
                onClick={() => {
                  const index = locations.indexOf(location);
                  // window.dispatchEvent(
                  //     new CustomEvent("newLocation", { detail:index })
                  //   );
                  postMessage({
                    action: "go-to-location",
                    data: {
                      targetID: location.id_tour_creator,
                      targetRy: location.default_rotation?.y || 0,
                    },
                  });
                  setOpen(false);
                }}
                style={{
                  backgroundColor:
                    (actualLocation == location.id_tour_creator
                      ? props.colors.secondary_light
                      : props.colors.primary_light) + "88",
                  color: props.colors.primary_dark,
                }}
                className="location-nav"
                key={`location-nav-${location.id_tour_creator}`}
              >
                {location.name}
              </div>
            );
          })}
      </div>
      <i
        onClick={handleClick}
        className={
          "nav-button " +
          (!open ? "fas fa-search-location" : "fas fa-chevron-left")
        }
        style={buttonStyle}
      ></i>
    </div>
  );
};

NavigationMenu.propTypes = {
  locations: PropTypes.array.isRequired,
  sponsors: PropTypes.array.isRequired,
  colors: PropTypes.object.isRequired,
};

export default NavigationMenu;
