import React, { useState } from "react";
import { default as $t } from "../../../../i18nProvider/translate";

const ModalTriviaAnswer = (props) => {
    const [isOpen, setIsOpen] = useState(true)


    return (
        isOpen ? 
        <div className="xengine-modal-trivia-answer">
            <div className="trivia-nav">
                <h3>{$t("explanation-trivia")}</h3>
            </div>
            <div className="triviaAnswerContainer">
                <p>{props.triviaAnswer}</p>
                <button className="btnResponse" onClick={() => { dispatchEvent(new CustomEvent("changeResultText")); setIsOpen(false) }}>{$t("trivia-explanation-understood")}</button>
            </div>
        </div> : null
    )
}

export default ModalTriviaAnswer