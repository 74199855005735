import React, { useEffect, useState } from "react";
import { default as $t } from "../../../i18nProvider/translate";
import { api, management } from "../../../managers/api/api-manager";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { HelmetMetaData } from "../../../helpers";
import { LoadingScreen } from  "../../xengine/loading-screen/loading-screen";

const ExperiencePage = (props) => {
    let slug = props.match.params.slug;
    let [experience, setExperience] = useState();

    function handleClientPage() {
        window.open(experience.url, "_blank");
    }

    useEffect(() => {
        ReactGA.pageview(`/${props.match.params.slug}`);
        management.verifyToken();
    }, []);

    function handleEnter() {
        location.href = `/tour/${experience.slug}`;
    }


    useEffect(() => {
        api.getExperienceForExperiencePage(slug).then(response => {
            let listSession = response.live_sessions.map(date => {
                const dateObject = new Date(date.date);
                return (dateObject); // our Date object
            });

            response.liveSessions = listSession;

            setExperience({ ...response });
            
        }
        );

    }, []);

    if (experience === undefined) {
        return <LoadingScreen backgroundStyles={{backgroundColor: "white"}} ></LoadingScreen>;
    }
    const mystyle = {
        backgroundSize: "cover",
        backgroundImage: `url(${experience.banner})`,
        justifyContent: "flex-end",
        backgroundPosition: "center"
    };
    let labels = experience.tags.map(label => {

        return <span className="labels content" key={label}>{$t(label)}</span>;

    });
   
    return (
        <div className="w-100 align-self-center experiencePage overflow-auto" style={mystyle}>
            {HelmetMetaData(experience)}
            <div className='experienceInfo w-100 h-100'  >

                <div className='experienceColumn content container' >
                    <h1 className="experience-name">{experience.name}</h1>  
                    <button className="action-btn btn-experience" onClick={handleEnter}>{$t("start-experience")}</button>
                    <div className="info-container">
                        <h3 className='textReset' >{$t("location")}</h3>
                        <a className="info-location" style={{ textDecoration: "none", color: "black" }} href={experience.link_google_maps} target="_blank" rel="noreferrer">
                            <i className="fas fa-map-marked"></i>
                            <p>{experience.location}</p>
                        </a>
                        
                        <h3 className='textReset'>{$t("description")}</h3>
                        <p className='experience-description'>{experience.description}</p>
                    </div>

                    {
                        experience.link_youtube?
                            <div className="iframe-box"><iframe src={experience.link_youtube} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>:<div/>
                    }  

                </div>
            </div>
        </div>
    );
};

ExperiencePage.propTypes = {
    match: PropTypes.object.isRequired
};

export default ExperiencePage;
