import React from "react";
import PropTypes from "prop-types";

//TODO this almost the same as 
const XEngineBtnReactions = (props) => {
    return (
        <div onClick={props.onClick} id={props.id} className={`xengine-btn-reactions ${props.class}`}>
            <img id={`image-${props.id}`} src={props.imageURL} />
            <i className={`fas fa-${props.icon}`}></i>
        </div>
    );
};

XEngineBtnReactions.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    class: PropTypes.string,
    imageURL: PropTypes.string,
};

export default XEngineBtnReactions;
