import { startMoveToLocation } from "../waypoints/waypoints-manager";
import { addHighlightToHotspot, removeHighlightFromHotspot } from "../hotspots/hotspots-manager";
import { api } from "../api/api-manager";
import { cameraManager } from "../camera/camera-manager";
import { validateEmail } from "../../helpers";
import audioManager from "../audio/audio-manager";
import { deleteCookie, removeElement } from "../../helpers";

class ActionManager {

    constructor() {
    }

    init() {
        window.addEventListener("changelocation", function (e) {
            window.localStorage.setItem("guideLocation", e.detail.new_location);
            window.localStorage.setItem("guideRotation", e.detail.new_rotation);
        });
        window.addEventListener("receivedraisehand", function (e) {
            window.localStorage.setItem("userRaisedHand", e.detail.user);
            let user = e.detail.user;
            if (validateEmail(user)) {
                user = user.slice(0, user.indexOf("@"));
            }
            let html = `${user} <i class="fas fa-hand-paper"></i>`;
            let notification = document.getElementById("notification");
            notification.innerHTML = html;
            notification.classList.add("showing");
            audioManager.playNotificationSound();
            setTimeout(() => {
                notification.classList.remove("showing");
            }, 30000);
        });
        window.addEventListener("receivedsurvey", function () {
            // The following lines are used to rerender the survey
            deleteCookie("smcx_0_last_shown_at");
            removeElement("surveymonkey-script");
            removeElement("__smcx__");
            removeElement("smcx-sdk");
            let smcxWidget = document.getElementsByClassName("smcx-widget")[0];
            if(smcxWidget)
                smcxWidget.parentNode.removeChild(smcxWidget);

            let script = document.createElement("script");
            script.setAttribute("async", "");
            script.setAttribute("id", "surveymonkey-script");

            if (localStorage.getItem("locale").includes("es")) {
                script.innerHTML = "(function(t,e,s,o){var n,c,l;t.SMCX=t.SMCX||[],e.getElementById(o)||(n=e.getElementsByTagName(s),c=n[n.length-1],l=e.createElement(s),l.type=\"text/javascript\",l.async=!0,l.id=o,l.src=\"https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd9fgcw0KfgCkH6wPkkoVnBNqZq0oR_2FpFleYOII2LbAGa.js\",c.parentNode.insertBefore(l,c))})(window,document,\"script\",\"smcx-sdk\");";
            } else {
                script.innerHTML = "(function(t,e,s,o){var n,c,l;t.SMCX=t.SMCX||[],e.getElementById(o)||(n=e.getElementsByTagName(s),c=n[n.length-1],l=e.createElement(s),l.type=\"text/javascript\",l.async=!0,l.id=o,l.src=\"https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdxSelZMIWnGyT5p0b3bsU24_2BynLPTbMn7_2ByIL_2Fv3XOmy.js\",c.parentNode.insertBefore(l,c))})(window,document,\"script\",\"smcx-sdk\");";
            }
            let head = document.getElementsByTagName("head")[0];
            head.appendChild(script);
        });


        window.addEventListener("hightlighthotspot", function (e) {
            const hotspotId = e.detail.hotspot_html_id;
            let lastSelectedHotspot = window.localStorage.getItem("selectedHotspot");
            if (lastSelectedHotspot) removeHighlightFromHotspot(lastSelectedHotspot);

            window.localStorage.setItem("selectedHotspot", hotspotId);
            addHighlightToHotspot(hotspotId);
        });
        window.addEventListener("teleport", function () {
            let guideLocation = window.localStorage.getItem("guideLocation");
            let guideRotation = window.localStorage.getItem("guideRotation");

            if (guideLocation != undefined && guideRotation != undefined) {
                cameraManager.lookToPoint(guideRotation);
                startMoveToLocation(guideLocation);
            }
        });
        window.addEventListener("raisehand", function () {
            //Send to other users in session this JSON via websockets
            let user = `${api.sessionUser.first_name} ${api.sessionUser.last_name}`;
            api.experienceCommunicationSend({ "action": "raise_hand", "user": user });
        });
        window.addEventListener("survey", function () {
            //Send to other users in session the message to activate the survey
            api.experienceCommunicationSend({ "action": "survey", "activate": true });
        });

        window.addEventListener("locate", function () {
            let selectedHotspotID = window.localStorage.getItem("selectedHotspot");
            if (selectedHotspotID) {
                let selectedHotspot = document.getElementById(selectedHotspotID);
                cameraManager.lookToPoint(JSON.parse(selectedHotspot.getAttribute("hotspot").position).y_angle);
            }
        });

        window.addEventListener("exit", () => {
            try {
                api.closeExperienceCommunication();
                window.location.href = "https://app.immersotours.com";
            }
            catch(err) {
                window.location.href = "https://app.immersotours.com";
            }
        });
    }
}

let actionManager = new ActionManager();
actionManager.init();

export { actionManager };
