import { ExperienceAPI } from "../../api/experience";
import { ManagementAPI } from "../../api/management";
import ReactGA from "react-ga";

let api = new ExperienceAPI();
let management = new ManagementAPI();

function userLoggedIn(token, refresh_token) {
    if (token === null || refresh_token === null || token === undefined || refresh_token === undefined) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("refresh");
        ReactGA.event({
            category: "Registered",
            action: "false"
        });
        return false;
    }
    ReactGA.event({
        category: "Registered",
        action: "true"
    });
    return true;
}

function getUser() {
    let user = window.localStorage.getItem("user");
    return JSON.parse(user);
}



export {
    api,
    management,
    userLoggedIn,
    getUser
};
