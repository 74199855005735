/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */
window.AFRAME.registerComponent('openinfo-vr-hotspot', {
    init: function () {
        const hotspotCointainerVR = document.getElementById("hotspotCointainerVR")
        const VRWatchInfo = document.getElementById("VRWatchInfo")
        this.el.addEventListener("click", function () {
            if (hotspotCointainerVR.getAttribute("text").opacity == 0) {
                //VRWatchInfo.setAttribute("text", "width: 1; color: white; value: Image; side: double;")
                hotspotCointainerVR.setAttribute("text", `align: center; value: ${hotspotCointainerVR.getAttribute("text").value}; opacity: 1`)
                hotspotCointainerVR.setAttribute("opacity", ".7")
                hotspotCointainerVR.setAttribute("color", "black")
                document.getElementById("btnImageButtonVR").setAttribute("scale", "0.18 0.18 0.18")
                document.getElementById("btnInfoButtonVR").setAttribute("scale", "0")
            } else {
                //VRWatchInfo.setAttribute("text", "width: 1; color: white; value: Info; side: double;")
                hotspotCointainerVR.setAttribute("opacity", "1")
                hotspotCointainerVR.setAttribute("text", `align: center; value: ${hotspotCointainerVR.getAttribute("text").value}; opacity: 0`)
                hotspotCointainerVR.setAttribute("color", "")
                document.getElementById("btnInfoButtonVR").setAttribute("scale", "0.18 0.18 0.18")
                document.getElementById("btnImageButtonVR").setAttribute("scale", "0")
            }
        });
    }
});