/**
 * @fileoverview
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */
import { startMoveToLocation } from "../../managers/waypoints/waypoints-manager";
import { api } from "../../managers/api/api-manager";
import audioManager from "../../managers/audio/audio-manager";
import { interacionManager } from "../../managers/interaction/interaction-manager";
import { mobileAndTabletCheck } from "../../helpers";

AFRAME.registerComponent("spot", {
  schema: {
    linkto: { type: "string", default: "" },
    position: { type: "string", default: "" },
    mode: { type: "number", default: 0 },
  },
  init: function () {
    let data = this.data;

    const spotClickable = new Event("spotClickable");

    if (data.mode != 2) {
      this.el.addEventListener("click", function () {
        if (
          mobileAndTabletCheck() ||
          !interacionManager.wasLastInteractionDrag()
        ) {
          window.dispatchEvent(spotClickable);

          // if (api.sessionUser?.isGuide()) {
          //     //Send to other users in session this JSON via websockets
          //     api.experienceCommunicationSend({
          //         "action": "switch_location", "new_location": data.linkto,
          //         "new_rotation": data.rigyrotation
          //     });
          // }
          startMoveToLocation(data.linkto);
        }
      });
    }
  },
});
