import { removeHighlightFromHotspot } from "../../managers/hotspots/hotspots-manager";
import { getYoutubeLinkID, mobileAndTabletCheck } from "../../helpers";
import { interacionManager } from "../../managers/interaction/interaction-manager";
import audioManager from "../../managers/audio/audio-manager";

AFRAME.registerComponent("hotspot", {
  schema: {
    hotspot: { type: "string", default: "" },
    origin: { type: "string", default: "" },
    mode: { type: "number", default: 0 },
    url: { type: "string", default: "" },
  },
  init: function () {
    let hotspot = JSON.parse(this.data.hotspot);
    let sound = hotspot.sound_id;
    let image = hotspot.image_id;
    let youtube = hotspot.video_url;
    let hotspotID = this.el.id;
    let audio = document.querySelector(`#sound-${sound}`);

    let youtubeID = getYoutubeLinkID(youtube);

    let openbtnID = `openbtn-${hotspotID}`;
    let closebtnID = `closebtn-${hotspotID}`;
    let audiobtnID = `audiobtn-${hotspotID}`;
    let infobtnID = `infobtn-${hotspotID}`;
    let imageID = `image-card-${hotspotID}`;
    let assetID = `img-${image}`;
    let infoID = `info-${hotspotID}`;
    let urlID = `url-btn-${hotspotID}`;

    let openbtn = document.getElementById(openbtnID);
    let closebtn = document.getElementById(closebtnID);
    let audiobtn = document.getElementById(audiobtnID);
    let infobtn = document.getElementById(infobtnID);
    let imageElement = document.getElementById(imageID);
    let asset = document.getElementById(assetID);
    let infoElement = document.getElementById(infoID);
    let urlBtn = document.getElementById(urlID);
    hotspot.src = asset?.src;

    this.el.addEventListener("animationcomplete__return", () => {
      this.el.setAttribute("visible", false);
    });

    if (!mobileAndTabletCheck()) {
      openbtn?.addEventListener("mouseenter", () => {
        removeHighlightFromHotspot(hotspotID);
      });
    }

    urlBtn?.addEventListener("click", () => {
      window.open(this.data.url, "_blank").focus();
    });

    openbtn?.addEventListener("click", () => {
      if (
        mobileAndTabletCheck() ||
        !interacionManager.wasLastInteractionDrag()
      ) {
        // let xScale = asset.width > asset.height ? 1 : asset.width/asset.height;
        // let yScale = asset.width < asset.height ? 1 : asset.height/asset.width;

        // imageElement.setAttribute("scale",`${xScale} ${yScale} 1`);

        window.sessionStorage.setItem("hotspotOpen", true);

        // if(infobtn){
        //     // infobtn.classList.add("clickable");
        // }

        // // If youtubeID is found, then we don't display hotspot like normal
        // if(!youtubeID) {
        //     // openHotspot(hotspotID);
        // }
        // if (api.sessionUser?.isGuide()) {
        //     //Send to other users in session this JSON via websockets
        //     api.experienceCommunicationSend({ "action": "highlight_hotspot", "hotspot_html_id": hotspotID });
        // }

        // if(audiobtn){
        //     audioManager.stopBackgroundSound();
        //     audioManager.playAudioHotspot(audio.src);
        // }
        if (youtubeID) {
          let iframe = document.createElement("iframe");
          iframe.setAttribute("width", 560);
          iframe.setAttribute("height", 315);
          iframe.setAttribute("frameborder", 0);
          iframe.setAttribute("allowfullscreen", true);
          iframe.setAttribute(
            "src",
            `https://www.youtube.com/embed/${youtubeID}?enablejsapi=1&version=3&playerapiid=ytplayer&autoplay=1`
          );
          iframe.setAttribute("id", youtubeID);
          let modal = document.getElementById("video-hotspot-display");
          modal.appendChild(iframe);
          modal.style.display = "initial";

          let bgElement = document.createElement("div");

          bgElement.style.zIndex = 2;
          bgElement.style.position = "absolute";
          bgElement.style.width = "100%";
          bgElement.style.height = "100%";
          bgElement.style.backgroundColor = "rgba(100, 100, 100, 0)";
          bgElement.style.top = "0";
          bgElement.dataset.toggle = false;

          document.body.appendChild(bgElement);

          bgElement.addEventListener("click", function handler(e) {
            window.sessionStorage.setItem("hotspotOpen", false);
            const displayID = "video-hotspot-display";
            if (e.target.id !== displayID) {
              let display = document.getElementById(displayID);
              display.style.display = "none";
              let children = display.children;

              if (children.length > 0) {
                for (let child of children) child.remove();
                bgElement.remove();
              }
            }
          });
        } else {
          if (audio) {
            //audioManager.stopBackgroundSound();
            audioManager.playAudioHotspot(audio.src);
          }
          window.dispatchEvent(
            new CustomEvent("hotspotOpened", { detail: { hotspot: hotspot } })
          );
        }
      }
    });

    // if(closebtn){
    //     closebtn.addEventListener("click", () => {
    //         window.sessionStorage.setItem("hotspotOpen", false);

    //         if (mobileAndTabletCheck() || !interacionManager.wasLastInteractionDrag()) {
    //             closeHotspot(hotspotID, data.origin);
    //         }
    //         if(audioManager.paused){
    //             audioManager.resumeBackgroundSound();
    //         }
    //         if(audiobtn){
    //             audioManager.resumeBackgroundSound();
    //             audioManager.stopAudioHotspot();
    //             audiobtn.setAttribute("src", "/img/pause1.png");
    //         }
    //     });
    // }

    // if(audiobtn)
    // {
    //     audiobtn.addEventListener("click", () => {
    //         if(!audioManager.paused){
    //             audioManager.stopBackgroundSound();
    //             audioManager.playAudioHotspot(audio.src);
    //             audiobtn.setAttribute("src", "/img/pause1.png");
    //         }else{
    //             audioManager.resumeBackgroundSound();
    //             audioManager.stopAudioHotspot();
    //             audiobtn.setAttribute("src", "/img/play1.png");
    //         }

    //     });
    //     document.addEventListener("EndAudioHotspot", () => {
    //         audioManager.resumeBackgroundSound();
    //         audioManager.stopAudioHotspot();
    //         audiobtn.setAttribute("src", "/img/play1.png");
    //     });

    // }

    // if(infobtn)
    // {
    //     infobtn.addEventListener("click", () => {
    //         if(infoElement.getAttribute("visible") == true){
    //             infoElement.setAttribute("visible", false);
    //             infobtn.setAttribute("src", "/img/info.png");
    //             imageElement.setAttribute("visible", true);
    //         }else{
    //             infoElement.setAttribute("visible", true);
    //             infobtn.setAttribute("src", "/img/return.png");
    //             imageElement.setAttribute("visible", false);
    //         }

    //     });
    // }
  },
});
