import { LOCALES } from "../constants";

export default {
    [LOCALES.ENGLISH]: {

        //Dictionary
        "activate-microphone": "Activate microphone",
        "are-you-already-registered?": "Are you already registered?",

        "bank-account-name":  "Bank account in the name of:",
        "banner-1": "Visit your favorite places from the comfort of your home.",
        "banner-2": "Explore the world with Immerso",
        "blog": "Blog",
        "clients": "Clients",
        "buy-experience": "Buy Experience",

        "card-call-to-action": "Know more!",
        "categories": "Categories",
        "create-account": "Create account",
        "comming-soon-1": "Comming soon",
        "comming-soon-2": "experiences",
        "confirm-password": "Confirm password",
        "contact": "Contact",
        "contact-line": "Contact the Immerso team through the following social networks or by email to info@theimmerso.com. Our team will contact you briefly to guide you in this new experience",
        "contact-us": "Contact us",
        "contribute": "Select how much you want to contribute",
        "currency-sign": "$",

        "description": "Description",

        "email": "Email",
        "email-to-immerso-btn": "Send us an email",
        "enter-paypal": "Support us through PayPal, by clicking on the button:",
        "exit": "View more tours",
        "experience": "experience",
        "experience-name": "Experience",
        "experiences": "experiences",
        "exploration": "Tours",
        "home": "Home",
        "create": "Create",

        "failed-subscription":"Something went wrong, verify your email and try again.",
        "follow-the-guide": "Follow the guide",
        "free": "Free",
        "fullscreen": "Fullscreen",

        "guided-by": "Guided by",
        "guided-sessions": "Guided sessions",
        "guided-session-message": "There is a guided session at the moment, do you want to join?",
        "guided-session-join": "Join guided session",

        "keep-updated":"We will keep you updated!",

        "last-name": "Last name",
        "loading": "Loading",
        "location": "Location",
        "log-in": "Log in",
        "login-to-Immerso": "Log in",
        "log-out": "Log out",
        "continue-with-gmail": "Continue with Gmail",
        "failed-log-in": "Incorrect email or password",
        "lower-volume": "Lower volume",

        "menu": "Menu",
        "minimize": "Minimize",
        "mute-microphone": "Mute microphone",

        "name": "Name",
        "notice": "Notice!",
        "not-registered?": "Not registered?",
        
        "oops": "Oops",

        "packages": "Packages",
        "participants": "Participants",
        "password": "Password",
        "payment-explanation": "Creating these experiences takes resources, time and dedication from Immerso and the tourist guides. We kindly request a voluntary payment to support the tourist guide and to maintain the platform active.",
        "price": "Price",

        "quote":  "Each help is one more step on the path of growth",

        "raise-your-hand": "Raise your hand",
        "reactions": "Reactions",
        "rights-reserved": "All rights reserved",
        "google-calendar":"Add to google calendar",
        "google-login-needed":"You need to be logged in to Gmail to add the event to Google Calendar",

        "see-video-guide": "See video guide",
        "select-language": "Select the language",
        "send-us-an-email": "Send us an email",
        "show-survey": "Show the survey to all",
        "sign-in": "Sign in",
        "sign-gmail": "Sign in with Gmail",
        "sinpe-description":  "Payment can also be made by SINPE Móvil or Bank transfer with the following information:",
        "sinpe-destiny": "SINPE Móvil Destiny:",
        "sinpe-or-bank-transfer":  "SINPE Móvil or Bank transfer",
        "site-page": "Site page",
        "soon": "Soon",
        "soon-more": "Soon more",
        "standard-screen": "Standard screen",
        "start-experience": "Start Tour",
        "subject":  "Subject:",
        "subscribe-immerso": "Subscribe to learn more about new tours",
        "subscription-successful":"Email subscription successful",

        "to-subscribe": "Subscribe",
        "tour-guides": "Tour guides",
        "turn-up-the-volume": "Turn up the volume",

        "voluntary-payment": "Voluntary payment",

        //Experience Tags
        "demo": "Demo",
        "nature": "Nature",
        "cultural": "Cultural",

        //Month
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December",

        //Months short mode
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Aug",
        "sep": "Sep",
        "oct": "Oct",
        "nov": "Nov",
        "dec": "Dic",

        //Date format
        "date": "{month} {day}{numbering} at {time}",

        //XEngine
        "start-waypoint": "Start",
        "landscape-mode": "Please, turn on landscape mode",
        "auto": "AUTO",
        "automatic-mode": "Automatic mode",
        "move-image":"Click and drag to look around",
        "mobile-image":"Move the device to look around",

        //Feedback Form Code
        "feedback-form": "(function(t,e,s,o){var n,c,l;t.SMCX=t.SMCX||[],e.getElementById(o)||(n=e.getElementsByTagName(s),c=n[n.length-1],l=e.createElement(s),l.type=\"text/javascript\",l.async=!0,l.id=o,l.src=\"https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdxSelZMIWnGyT5p0b3bsU24_2BynLPTbMn7_2ByIL_2Fv3XOmy.js\",c.parentNode.insertBefore(l,c))})(window,document,\"script\",\"smcx-sdk\");",

        //Event description
        "event-description": "¡Hello {connectedUser.user_info.first_name}{connectedUser.user_info.last_name}! Te invitamos a participar de la experiencia virtual\"{experience.name}\", para ingresar solo debes darle click a este link en la fecha agendada: https://theimmerso.com/experience/{experience.slug}. Para más información puedes visitar nuestro facebook: https://www.facebook.com/immersoofficial. ¡Bienveniodo al mundo del turismo virtual, te esperamos!",

        //Menu static 
        "review":"Survey",
        "info":"Information",
        "share": "Share",
        "right": "Next location",
        "left": "Previous location",
        
        //Call to Action
        "call-to-action-bookings-text": "Book Now!",
        "call-to-action-tickets-text": "Buy Your Ticket Now!",
        "call-to-action-contacts-text": "Contact Us!",

        //Sponsors
        "sponsors":"Presented by",

        //Trivias
        "show-trivia-results":"Know more",
        "show-trivia-answers":"Show answers",
        "submit-trivia-answers":"Submit",
        "finish-trivia-answers":"Finish",

        
        //Trivia Explication
        "explanation-trivia":"Explanation",
        "trivia-explanation-understood": "Understood",
    },
};
