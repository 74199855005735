
import { directionToVector, mobileAndTabletCheck } from "../../helpers";
import { OPENHOTSPOTDISTANCE, OPENHOTSPOTDISTANCEMOBILE } from "../../constants";

let actualOpenHotspotID = null;
let loadedVideoEventListener = false; // To only load the event listener, that removes the video modal, once

export const loadVideoEventListener = () => loadedVideoEventListener = true;

export {
    loadedVideoEventListener
};

export function openHotspot(targetHotspotID) {
    let openbtnID = `openbtn-${targetHotspotID}`;
    let closebtnID = `closebtn-${targetHotspotID}`;

    let openbtn = document.getElementById(openbtnID);
    let closebtn = document.getElementById(closebtnID);
    let infobtn = document.getElementById(`infobtn-${targetHotspotID}`);


    if(infobtn){
        infobtn.classList.add("clickable");
    }

    openbtn.classList.remove("clickable");
    closebtn.classList.add("clickable");

    let hotspot = document.getElementById(targetHotspotID);
    hotspot.setAttribute("visible", true);
    let camRotation = document.querySelector("#cam").getAttribute("rotation");
    let rigRotation = document.querySelector("#rig").getAttribute("rotation");
    let targetPosition = directionToVector([0, 1.6, 0], camRotation.x + rigRotation.x, camRotation.y + rigRotation.y,
        mobileAndTabletCheck()?OPENHOTSPOTDISTANCEMOBILE:OPENHOTSPOTDISTANCE);

    let actualPosition = hotspot.getAttribute("position");

    let returnAnim = hotspot.getAttribute("animation__return");
    returnAnim.enabled = false;
    hotspot.setAttribute("animation__return", returnAnim);

    let approachAnim = hotspot.getAttribute("animation__approach");
    approachAnim.enabled = true;
    approachAnim.from = actualPosition;
    approachAnim.to = targetPosition;
    approachAnim.easing = "easeOutSine";
    approachAnim.startEvents = []; // Set animation to automatic start
    hotspot.setAttribute("animation__approach", approachAnim);

    // Close any other openned hotspot
    if (actualOpenHotspotID != null && actualOpenHotspotID != targetHotspotID) {
        let actualHotspot = document.getElementById(actualOpenHotspotID);
        let origin = actualHotspot.getAttribute("hotspot").origin;
        closeHotspot(actualOpenHotspotID, origin);
    }

    actualOpenHotspotID = targetHotspotID;
}

export function closeHotspot(targetHotspotID, origin) {
    let openbtnID = `openbtn-${targetHotspotID}`;
    let closebtnID = `closebtn-${targetHotspotID}`;
    let infobtn = document.getElementById(`infobtn-${targetHotspotID}`);

    if(infobtn){
        infobtn.classList.remove("clickable");
    } 

    let openbtn = document.getElementById(openbtnID);
    let closebtn = document.getElementById(closebtnID);
    openbtn.classList.add("clickable");
    closebtn.classList.remove("clickable");

    actualOpenHotspotID = null;
    let hotspot = document.getElementById(targetHotspotID);

    let approachAnim = hotspot.getAttribute("animation__approach");
    approachAnim.enabled = false;
    hotspot.setAttribute("animation__approach", approachAnim);

    let actualPosition = hotspot.getAttribute("position");

    let returnAnim = hotspot.getAttribute("animation__return");
    returnAnim.enabled = true;
    returnAnim.from = actualPosition;
    returnAnim.to = origin;
    returnAnim.easing = "easeInSine";
    returnAnim.startEvents = [];
    hotspot.setAttribute("animation__return", returnAnim);
}

export function addHighlightToHotspot(targetHotspotID) {
    let highlight = document.getElementById(`highlight-${targetHotspotID}`);
    highlight.setAttribute("scale", "1 1 1");
}

export function removeHighlightFromHotspot(targetHotspotID) {
    let highlight = document.getElementById(`highlight-${targetHotspotID}`);
    highlight.setAttribute("scale", "0 0 0");
}
