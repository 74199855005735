
import {Entity, Scene} from "aframe-react";
import React, {useEffect} from "react";
import PropTypes from "prop-types";

const RigRotationPreview = props => {
    const { type, src, rotation } = props;
    
    useEffect(() => {
        let rig = document.querySelector("#rig");
        let cam = document.querySelector("#cam");

        window.addEventListener("mouseup", () => {
            let camRotation = cam.getAttribute("rotation");
            let rigRotation = rig.getAttribute("rotation");
            let rotation = (rigRotation.y + camRotation.y) % 360;
            window.parent.postMessage({action: "rigrotation-change", data: {rotation:rotation}}, "*");
        });
    }, []);

    return (
        <Scene cursor="rayOrigin: mouse; fuseTimeout: 0;" raycaster="objects: .clickable" vr-mode-ui="arEnabled: false; enabled: false;">
            <a-assets timeout="40000">
                {type == "img" && <img crossOrigin="true" key="bg_img" id='bg_img' src={src}></img>}
                {type == "video" && <video key="bg_video" id="bg_video" loop crossOrigin="anonymous" autoPlay src={src}></video>}
            </a-assets>
            <Entity
                id = "rig"
                rotation={`0 ${rotation} 0`}
            >
                <Entity
                    id = "cam"
                    primitive="a-camera"
                    wasd-controls="false"
                    position="0 1.6 0"
                    rotation="0 0 0"
                    look-controls="magicWindowTrackingEnabled: "
                >
                </Entity>
            </Entity>
            {type == "img" && <Entity
                primitive="a-sky"
                src="#bg_img"
                scale="-20 20 20"
            />}
            {type == "video" && <a-videosphere class="clickable" video src="#bg_video"></a-videosphere>}
        </Scene>
    );
};

RigRotationPreview.propTypes = {
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    rotation: PropTypes.number.isRequired,
};

export default RigRotationPreview;