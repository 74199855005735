import React from "react";
import PropTypes from "prop-types";

const XEngineLocationName = (props) => {
    let style = {};
    if (props.colors?.primary_dark)
        style.color = props.colors?.primary_dark;
    if (props.colors?.primary_light)
        style["backgroundColor"] = props.colors?.primary_light + "88";
    return (
        <div style={style} id='xengine-location-name' className='xengine-location-name'>
            {props.name}
        </div>
    );
};

XEngineLocationName.propTypes = {
    name: PropTypes.string.isRequired,
    colors: PropTypes.object
};

export default XEngineLocationName;
