import React from "react";
import PropTypes from "prop-types";

/**
 * @param {object} props ->
 * backgroundStyles: An object containing the styling for the background object
 * @return {React.ReactElement} An animated HTML loading screen showned on top of
 * the aframe canvas and any other element
 */

export const LoadingScreen = (props) => {
    let backgroundStyles = {};
    if (props.backgroundStyles !== undefined) {
        backgroundStyles = props.backgroundStyles;
    }
    return <div style={backgroundStyles} id="loading-screen" className="loading-screen"> 
        <img id="loading-image" src="../../assets/img/Animacion-carga-immerso.gif"/>
        <img alt="Image Powered By" src="../../assets/img/poweredbybluecolor.png"/>
    </div>;
};

LoadingScreen.propTypes = {
    backgroundStyles: PropTypes.object,
};
