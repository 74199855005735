import React from "react";
import PropTypes from "prop-types";
import { default as $t } from "../../../../i18nProvider/translate";
import { insertParam } from "../../../../helpers";

const ModalGuide = (props) => {
    const close = () => {
        document.getElementById("modal").classList.add("invisible");
    };

    const join = () => {
        // j is an alias for guide mode (1 is true and 0 false)
        insertParam("j", 1);
        document.getElementById("modal").classList.add("invisible");
    };

    return (
        <div id="modal" onClick={close} style={{visibility: props.visible ? "visible":"hidden"}} className="xengine-modal-guided-session">
            <h1>{$t("notice")}</h1>
            <p>{$t("guided-session-message")}</p>
            <div className="btn" onClick={join}> {$t("guided-session-join")}</div> 
            <div className="close-btn">X</div>
        </div>
        
    );
};

ModalGuide.propTypes = {
    visible: PropTypes.bool.isRequired,
};

export default ModalGuide;