import React from "react";
import PropTypes from "prop-types";
import { default as $t } from "../../../../i18nProvider/translate";

const SponsorPanel = (props) => {
    return (
        <div className="sponsor-panel">
            {props.sponsors.length > 0 && <div className="title">{$t("sponsors")}</div>}
            {props.sponsors.length > 0 && <div className="sponsors">
                {props.sponsors.sort((v1, v2) => v1.order - v2.order).map((sponsors) => {
                    return <a key={`sponsor-${sponsors.sponsor.name}`} href={sponsors.sponsor.url} className="sponsor" target="_blank" rel="noreferrer">
                        <img src={sponsors.sponsor.logo} alt={`${sponsors.sponsor.name} logo`}></img>
                    </a>;
                })}
            </div>}
        </div>
    );
};

SponsorPanel.propTypes = {
    sponsors: PropTypes.array.isRequired,
};

export default SponsorPanel;