import { DRAGLIMIT } from "../../constants";

class InteractionManager {

    constructor() {
        this.clickpos = [-1, -1],
        this.actualpos = [-1, -1],
        this.minDistance = [DRAGLIMIT, DRAGLIMIT];
        this.firstDrag = false;
    }

    mouseDownHandler(e) {
        if (e.clientX && e.clientY) {
            this.clickpos = [e.clientX, e.clientY];
        }
        if (e.touches) {
            if (e.touches[0]?.clientX && e.touches[0]?.clientY) {
                this.clickpos = [e.touches[0]?.clientX, e.touches[0]?.clientY];
            }
        }
    }

    mouseMoveHandler(e) {
        if (e.clientX && e.clientY) {
            this.actualpos = [e.clientX, e.clientY];
        }
        if (e.touches) {
            if (e.touches[0]?.clientX && e.touches[0]?.clientY) {
                this.actualpos = [e.touches[0]?.clientX, e.touches[0]?.clientY];
            }
        }
    }

    mouseUpHandler (e) {
        if(!this.firstDrag){
            if(this.wasLastInteractionDrag()) {
                window.dispatchEvent(new CustomEvent("firstDrag"));
                this.firstDrag = true;
            }
        }
    }

    init() {
        document.addEventListener("mousedown", (e) => this.mouseDownHandler(e));
        document.addEventListener("mousemove", (e) => this.mouseMoveHandler(e));
        document.addEventListener("mouseup", (e) => this.mouseUpHandler(e));
        document.addEventListener("touchstart", (e) => this.mouseDownHandler(e));
        document.addEventListener("touchmove", (e) => this.mouseMoveHandler(e));
        document.addEventListener("touchend", (e) => this.mouseUpHandler(e));
    }

    wasLastInteractionDrag() {     
        let difx = Math.abs(this.clickpos[0] - this.actualpos[0]);
        let dify = Math.abs(this.clickpos[1] - this.actualpos[1]);
        if (difx > this.minDistance[0] || dify > this.minDistance[1]) return true;
        return false;
    }
}

let interacionManager = new InteractionManager();
interacionManager.init();

export { interacionManager };
