import React from "react";
import { default as $t } from "../../../i18nProvider/translate";


const ContactShow = () => {
    return (
        <div className="container justify-content-center contactPage">
            <div className="row p-md-5 m-md-5">
                <div className="col-md-6 col-xs-12">
                    <img className="img-fluid d-block" src='img/contactImage.png' alt="contáctanos" />
                </div>
                <div className="col-md-6 col-xs-12">
                    <div className="row text-center">
                        <div className="col-12">
                            <h1>{$t("contact-us")}</h1>
                            <span>{$t("contact-line")}</span>
                        </div>
                        <div className="col-12 pt-4">
                            <div className="row">
                                <div className="col-6">
                                    <a href="https://www.facebook.com/immersoofficial">
                                        {/* <img className="img-fluid rounded mx-auto d-block" src='img/facebook.png' alt="facebook" /> */}
                                    </a>
                                </div>
                                <div className="col-6">
                                    <a href="https://www.instagram.com/immerso_turismovirtual/">
                                        {/* <img className="img-fluid rounded mx-auto d-block" src='img/instagram.png' alt="instagram" /> */}
                                    </a>
                                </div>
                            </div>
                        </div >
                        <div className="col-12 mb-5">
                            <a href="mailto:info@theimmerso.com">
                                <button className="btn btn-primary btn-lg buttonContact mr-auto">{$t("send-us-an-email")}</button>
                            </a>
                        </div >
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactShow;
