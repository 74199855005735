import { GoogleLogin } from "react-google-login";
import React from "react";
import PropTypes from "prop-types";


const GoogleAuth = (props) => {
    return <GoogleLogin
        clientId="424211710188-g6n6fj9g1lj4e7bvm2kuk50qpuda3af8.apps.googleusercontent.com"
        buttonText={props.text}
        onSuccess={props.onSuccess}
        className={props.className}
        onFailure={props.onFailure}
    />;
};

GoogleAuth.propTypes = {
    text: PropTypes.object,
    onSuccess: PropTypes.func,
    className: PropTypes.string,
    onFailure: PropTypes.func,
};

GoogleAuth.defaultProps = {
    // eslint-disable-next-line no-console
    onFailure: err => console.error("fail", err)
};

export default GoogleAuth;
