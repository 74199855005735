import React from "react";
import XEngineBtnReactions from "../xengine-btn-reactions/xengine-btn-reactions";
import PropTypes from "prop-types";


const XEngineReactions = (props) => {

    const reaction = new Event("reaction");

    const love = () => {
        props.closeMenu();
        window.sessionStorage.setItem("reaction", "love");
        window.dispatchEvent(reaction);
    };

    const like = () => {
        props.closeMenu();
        window.sessionStorage.setItem("reaction", "like");
        window.dispatchEvent(reaction);
    };

    const enjoy = () => {
        props.closeMenu();
        window.sessionStorage.setItem("reaction", "haha");
        window.dispatchEvent(reaction);
    };

    const sad = () => {
        props.closeMenu();
        window.sessionStorage.setItem("reaction", "sad");
        window.dispatchEvent(reaction);
    };

    const wow = () => {
        props.closeMenu();
        window.sessionStorage.setItem("reaction", "wow");
        window.dispatchEvent(reaction);
    };

    return (
        <div id='xengine-reactions' className='xengine-menu-reaction menu-hidden-r'>
            <a data-tip data-for='Sad'><XEngineBtnReactions id="sad" icon="" class="reaction" imageURL='/img/sad.png' onClick={sad} /></a>
            <a data-tip data-for='Wow'><XEngineBtnReactions id="wow" icon="" class="reaction" imageURL='/img/wow.png' onClick={wow} /></a>
            <a data-tip data-for='Enjoy'><XEngineBtnReactions id="enjoy" icon="" class="reaction" imageURL='/img/haha.png' onClick={enjoy} /></a>
            <a data-tip data-for='Love'><XEngineBtnReactions id="love" icon="" class="reaction" imageURL='/img/love.png' onClick={love} /></a>
            <a data-tip data-for='Like'><XEngineBtnReactions id="like" icon="" class="reaction" imageURL='/img/like.png' onClick={like} /></a>

        </div>
    );
};

XEngineReactions.propTypes = {
    closeMenu: PropTypes.func.isRequired,
};

export default XEngineReactions;
