import React from "react";
import GoogleAuth from "../../platform/google-auth/google-auth";
import { default as $t } from "../../../i18nProvider/translate";
import axios from "axios";
import User from "../../../models/user";
import { api, getUser, management, userLoggedIn } from "../../../managers/api/api-manager";
import ReactGA from "react-ga";
import ReCaptcha from "react-google-invisible-recaptcha";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.isLoggedIn();
        this.state = {
            first_name: "",
            last_name: "",
            password: "",
            email: "",
            register: false,
            errors: {
                detail: []
            },
            recaptchaToken: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleGmailSubmit = this.handleGmailSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    verifyCallback() {
        management.getJWTToken(this.state.email, this.state.first_name, this.state.last_name, this.state.password,
            this.recaptcha.getResponse(), this.isGmail)
            .then(token => {
                axios.defaults.headers.common["Authorization"] = `Bearer ${token.access}`;
                management.refresh_token = token.refresh;
                window.localStorage.setItem("token", token.access);
                window.localStorage.setItem("refresh_token", token.refresh);
                management.getUserWithToken()
                    .then(response => {
                        let user = response.user_info;
                        api.sessionUser = new User(user.id, user.email, user.first_name, user.last_name, user.groups, user.username);
                        window.localStorage.setItem("user", JSON.stringify({
                            groups: user.groups, username: user.username, experiences: response.experiences
                        }));

                        this.isLoggedIn();
                    })
                    // eslint-disable-next-line no-console
                    .catch(error => console.error(error));
            })
            .catch(
                errors => {
                    this.findErrors(errors.response.data.detail);
                });

    }

    componentDidMount() {
        ReactGA.pageview("/login");
    }

    findErrors(error) {
        this.recaptcha.reset();
        this.setState({
            errors: {
                detail: error
            }
        });
        
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleGmailSubmit(response) {
        this.setState({ first_name: response.profileObj.givenName });
        this.setState({ last_name: response.profileObj.familyName });
        this.setState({ email: response.profileObj.email });
        this.setState({ password: response.googleId });
        this.handleSubmit(response, true);
       
        ReactGA.event({
            category: "Login",
            action: "Gmail"
        });
    }

    handleRegister(event) {
        event.preventDefault();
        const queryString = window.location.search;

        location.href = location.origin + "/register" + queryString;
    }

    isLoggedIn() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (userLoggedIn(window.localStorage.getItem("token"), window.localStorage.getItem("refresh_token"))) {
            let user = getUser();
            ReactGA.set({userId: user.username});
            let redirectUrl = urlParams.get("u");
            if (redirectUrl) {
                location.href = redirectUrl + "?&j=1";
            } else {
                location.href = "/";
            }
        }
    }

    handleSubmit(event, isGmail) {
        if (event.preventDefault) {
            event.preventDefault();
        }
        this.isGmail = isGmail;
        if(!this.isGmail)
            ReactGA.event({
                category: "Login",
                action: "Form"
            });
        this.recaptcha.execute(); //This verifies the user via recaptcha, is successful it calls this.verifyCallback
    }

    render() {
        return (
            <React.Fragment>
                <div className="login">
                    <form onSubmit={this.handleSubmit}>
                        <div className="title">
                            <h1>{$t("log-in")}</h1>
                            <div className="link">
                                {$t("not-registered?")}
                                <a href="" onClick={this.handleRegister}>{$t("create-account")}</a>
                            </div>
                        </div>

                        <div style={{marginBottom:"6em"}} >
                            <div className="container">
                                <label htmlFor="email"><b>{$t("email")}</b></label>
                                <input type="email" placeholder="Email" name="email" required value={this.state.email}
                                    onChange={e => this.setState({ email: e.target.value })} />

                                <label htmlFor="psw"><b>{$t("password")}</b></label>
                                <input type="password" placeholder="Password" name="psw" required value={this.state.password}
                                    onChange={e => this.setState({ password: e.target.value })} />
                                <ReCaptcha
                                    ref={ref => this.recaptcha = ref}
                                    sitekey={$PROCESS_ENV_RECAPTCHA_KEY || "6LcWGtMZAAAAADUQ2VyMan61_8o06M8TGJjDsR5u"}
                                    onResolved={this.verifyCallback}
                                />
                                {this.validateForm()?<button className="enabled-button" type="submit" disabled={false}>{$t("login-to-Immerso")}</button>:<button className="disabled-button" type="submit" disabled={true}>{$t("login-to-Immerso")}</button> }
                                
                            </div>
                            <div className="separator" />
                            <div className="container">
                                <GoogleAuth className="buttonGoogle" text={$t("continue-with-gmail")} onSuccess={this.handleGmailSubmit} />
                            </div>
                        </div>

                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default Login;
