/**
 * @fileoverview
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

import { management } from "../../managers/api/api-manager";
import audioManager from "../../managers/audio/audio-manager";
import { cameraManager } from "../../managers/camera/camera-manager";

AFRAME.registerComponent("environment", {
  schema: {},
  init: function () {
    cameraManager.startIdleRotate();
    window.sessionStorage.setItem("interactionTimer", undefined);
    window.sessionStorage.setItem("hotspotOpen", false);

    window.addEventListener("firstDrag", function () {
      let mode = new URLSearchParams(location.search).get("j");
      const actualLocationId = window.localStorage.getItem("firstLocationID");
      const actualLocation = management.experience.locations.filter(
        (location) => location.id_tour_creator == actualLocationId
      )[0];
      let backgroundSound = document.querySelector(
        `#sound-${actualLocation.sound_id}`
      );
      if (backgroundSound)
        audioManager.playBackgroundSound(backgroundSound.src);
      let audioGuide = document.querySelector(
        `#sound-${actualLocation.audio_id}`
      );
      let controlAudio = document.getElementById("controlAudio");
      if (audioGuide != null && (!mode || mode === 0)) {
        audioManager.playAudioLocation(audioGuide.src);
        document.dispatchEvent(new CustomEvent("startAudioLocation"));
        controlAudio.style.visibility = "visible";
        document.dispatchEvent(new CustomEvent("firstClick"));
      }
    });
  },
});
