import React, { useEffect, useState } from "react";
import { Entity, Scene } from "aframe-react";
import { directionToVector } from "../../../helpers";
import PropTypes from "prop-types";

const Hotspot = (props) => {
    const { hotspot, id } = props;
    const initialPosition = directionToVector(hotspot.position.origin, hotspot.position.x_angle, hotspot.position.y_angle, hotspot.position.distance + 50);
    const btnPosition = directionToVector(hotspot.position.origin, hotspot.position.x_angle, hotspot.position.y_angle, hotspot.position.distance);
    const highlightPosition = directionToVector(hotspot.position.origin, hotspot.position.x_angle, hotspot.position.y_angle, hotspot.position.distance - 0.5);
    const maxLength = hotspot.title.length;
    const wrapCount = Math.max(55, (maxLength - 13) * 4 + 55);
    const modeParam = new URLSearchParams(location.search).get("j");
    const mode = modeParam === null ? 0 : Number(modeParam);
    const url = hotspot.has_info ? hotspot.info.split("url-")[1] : undefined;
    const [xScale, setXScale] = useState(0);
    const [yScale, setYScale] = useState(0);
    const [format, setFormat] = useState("png");

    useEffect(() => {
        let assetID = `img-${hotspot.icon_id || hotspot.image_id}`;
        let asset = document.getElementById(assetID);

        setIconScale(asset);
    }, []);

    const setIconScale = (asset) => {
        let w = asset.width;
        let h = asset.height;
        if (w != 0 && h != 0) {
            let srcParts = asset.src.split(".");
            let srcFormat = srcParts[srcParts.length - 1];
            setFormat(srcFormat);
            setXScale(asset.width > asset.height ? 1 : asset.width / asset.height);
            setYScale(asset.width < asset.height ? 1 : asset.height / asset.width);
        }
        else {
            setTimeout(() => setIconScale(asset), 100);
        }
    };

    if (xScale != 0 && yScale != 0) {
        return <Entity class="hotspot-container">
            {/* Hotspot card component */}
            <Entity
                position={initialPosition}
                hotspot={`hotspot:${JSON.stringify(hotspot)};origin:${initialPosition};mode:${mode};url:${url};`}
                move-hotspot={`mode: ${mode}; distance: ${hotspot.position.distance}`}
                lookatcamera
                id={id}
            >
            </Entity>

            {/* Hotspot btn component */}
            <Entity
                cursorclickhotspot={`hotspot:${JSON.stringify(hotspot)}`}
                position={btnPosition}
                lookatcamera
                scale="1.5 1.5 1.5"
                id={`icon-${id}`}
                interactive-object
                animation__scale="property: scale; dir: alternate; to: 1.6 1.6 1.6; easing: easeInOutQuad; loop: true; dur: 1000; pauseEvents: mouseenter; resumeEvents: mouseleave"
            >
                {format == "png" ?
                    <a-image
                        id={`openbtn-${id}`}
                        scale={`${xScale} ${yScale} 1`}
                        class="clickable"
                        material={`src: ${`#img-${hotspot.icon_id || hotspot.image_id}`}; transparent: true; alphaTest: 0.5;`}></a-image>
                    :
                    <a-entity
                        geometry={"primitive: circle; radius: 0.5"}
                        material={`shader: standard; repeat: ${Math.min(xScale, yScale)}; offset:${(1 - yScale) / 2} ${(1 - xScale) / 2}; src: ${`#img-${hotspot.icon_id || hotspot.image_id}`}`}
                        id={`openbtn-${id}`}
                        class="clickable"
                    ></a-entity>}
            </Entity>

            {/* Hotspot highlight component */}
            <Entity
                position={highlightPosition}
                lookatcamera
                id={`highlight-${id}`}
                scale="0 0 0"
            >
                <a-entity geometry="primitive: circle; radius: 0.5" id={`highlight-${hotspot.image_id}`} fit-texture scale="1.5 1.5 1" material={`shader: highlight; color: #02F1A0; opacity: 0.6; image: #img-${hotspot.icon_id || hotspot.image_id};`}></a-entity>
            </Entity>
        </Entity>;
    }
    else {
        return <div></div>
    }
};

Hotspot.propTypes = {
    hotspot: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    colors: PropTypes.array.isRequired
};

export default Hotspot;
