import React from "react";
import { userLoggedIn } from "../../../managers/api/api-manager";
import { default as $t } from "../../../i18nProvider/translate";



function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    localStorage.removeItem("locationID");
    location.href = "/";
}

const LogoutButton = () => {
    if (userLoggedIn(localStorage.getItem("token"), localStorage.getItem("refresh_token"))) {
        return <div id="logout" className="logout">
            <img className='logo h-5 sm:h-7 pr-2' src="/img/profile.png" alt="User Image" />
            <a className="textbtn" onClick={logout}>{$t("log-out")}</a>
        </div>;
    }

    return null;
};

export {
    LogoutButton,
};
