import React, {useState, useEffect} from "react";
import ExperienceShow from "../../platform/experience-show/experience-show";
import ReactGA from "react-ga";
import { api, management } from "../../../managers/api/api-manager";

const Explore = () => {

    let query =  new URLSearchParams(window.location.search);
    let selectedRegion =  query.get("r");
    let selectedCountry =  query.get("c");

    const [countries, setCountries] = useState([{
        slug: "all",
        name: "General",
    }]);

    const [regions, setRegions] = useState([{
        slug : "all",
        country: "all",
        name: "Todos",
        color: {primary:"#1B2A45"},
        img: "img/immersoexplore.jpg",
    }]);

    const [country, setCountry] = useState("all");
    const [countryRegions, setCountryRegions] = useState(regions.filter( region => region.country == country));
    const [regionSlug, setRegionSlug] = useState(countryRegions[0].slug);
    const [region, setRegion] = useState(regions.filter( region => region.slug == regionSlug)[0]);

    useEffect(() => {
        ReactGA.pageview("/");
        management.verifyToken();
        api.getRegionsCountryList().then(response => {
            let newCountries = JSON.parse(JSON.stringify(countries));
            let newRegions = JSON.parse(JSON.stringify(regions));

            response.map(c => {
                let slugC = c.slug;
                newCountries.push({
                    slug: slugC,
                    name: c.title,
                });
                c.region.map(r =>{
                    let slugR = r.slug;
                    newRegions.push({
                        slug: slugR,
                        country: slugC,
                        name: r.title,
                        color: r.color1,
                        img: r.img_url,
                    });
                });
            });
            setCountries(newCountries);
            setRegions(newRegions);

            if(selectedRegion) {
                let regionTmp = newRegions.filter( region => region.slug == selectedRegion)[0];

                if(regionTmp) {
                    setRegion(regionTmp);
                    let regionSlugTmp = regionTmp.slug;
                    setRegionSlug(regionSlugTmp);
                }
                else {
                    regionTmp = newRegions[0];
                    setRegion(regionTmp);
                    setRegionSlug("all");
                }

                setCountry(regionTmp.country);
                setCountryRegions(newRegions.filter( region => region.country == region.country));
            }
            else if (selectedCountry) {
                let countryTmp = newCountries.filter( country => country.slug == selectedCountry)[0];

                if(!countryTmp) {
                    selectedCountryTmp = "all";
                }

                setCountry(selectedCountry);

                let countryRegionsTmp = newRegions.filter( region => region.country == selectedCountry);
                setCountryRegions(countryRegionsTmp);
                setRegion(countryRegionsTmp[0] || newRegions[0]);
                let regionSlugTmp = countryRegionsTmp[0].slug;
                setRegionSlug(regionSlugTmp);

                if(regionSlugTmp == "all") {
                    setCountry("all");
                    setCountryRegions(newRegions.filter( region => region.country == selectedCountry));
                }
            }
            
        }
        );

    }, []);

    const handleCountryChange = (e) => {
        let country = e.target.value;
        setCountry(country);
        let countryRegions = regions.filter( region => region.country == country);
        setCountryRegions(countryRegions);
        setRegion(countryRegions[0]);
        setRegionSlug(countryRegions[0].slug);
    };
    const handleRegionChange = (e) => {
        setRegion(e.target.value);
    };

    return (

        <div className="explore">
            <div className="header" style={{
                "backgroundImage": `url(../assets/${region.img})`}}>
                <div className="title">
                    {country == "all" ? "Bienvenidos" : countries.filter(element=> element.slug == country)[0].name}
                </div>
            </div>
            <div className="tour-filter">
                <div className="fields">
                    <select value={country} onChange={handleCountryChange}>
                        {countries.map(country => {
                            return <option key={`country-${country.slug}`} value={country.slug}>{country.name}</option>;
                        })}
                    </select>
                    <select value={region} onChange={handleRegionChange}>
                        {countryRegions.map(region => {
                            if (region.country == country) return <option key={`region-${region.slug}`} value={region.slug}>{region.name}</option>;
                        })}
                    </select>
                </div>
            </div>
            <div className="experienceShow container-fluid" style={{ maxWidth: "100%" }}>
                <ExperienceShow region={regionSlug}/>
            </div> 
        </div>
    );
};

export default Explore;