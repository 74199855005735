/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

import videoManager from "../../managers/video/video-manager";
import { iOS, mobileAndTabletCheck } from "../../helpers";
import { interacionManager } from "../../managers/interaction/interaction-manager";

AFRAME.registerComponent("video", {
    init: function () {
        videoManager.init(this.el);

        if (iOS()) {
            this.el.addEventListener("click", () => {
                if(mobileAndTabletCheck() || !interacionManager.wasLastInteractionDrag()){
                    if (!videoManager.playing) videoManager.playVideo();
                }
            });
        }
    }
});
