import { api } from "../api/api-manager";
import audioManager from "../audio/audio-manager";

const REACTION_IMAGES = {
    "like": "/img/like.png",
    "love": "/img/love.png",
    "haha": "/img/haha.png",
    "wow": "/img/wow.png",
    "sad": "/img/sad.png",
};

const REACTION_ANIMATIONS = {
    "like": "swing",
    "love": "heartBeat",
    "haha": "tada",
    "wow": "tada",
    "sad": "headShake",
};

class ReactionsManager {

    constructor() {
    }

    init() {
        window.addEventListener("receivedreaction", function (e) {
            const template = document.getElementById("animation-reactions");
            let reaction = template.cloneNode();
            reaction.appendChild(template.firstChild.cloneNode());
            reaction.firstChild.src = REACTION_IMAGES[e.detail.reaction];

            reaction.className = "animationReactions";
            reaction.className += " animate__animated";
            reaction.style.animationName = `animation, ${REACTION_ANIMATIONS[e.detail.reaction]}`;

            reaction.firstChild.style.width = "3em";
            reaction.style.animationDuration = "2s";

            template.parentNode.appendChild(reaction);

            audioManager.playReactionSound();
            setTimeout(() => {
                reaction.parentNode.removeChild(reaction);
            }, 3000);
        });

        window.addEventListener("reaction", function () {
            //Send to other users in session this JSON via websockets
            api.experienceCommunicationSend({ "action": "reaction", "reaction": window.sessionStorage.getItem("reaction"), "type": "action" });
        });
    }
}

let reactionsManager = new ReactionsManager();
reactionsManager.init();

export { reactionsManager };
