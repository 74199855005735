import React from "react";
import XEngineLocationName from "../xengine-location-name/xengine-location-name";
import PropTypes from "prop-types";

const XEngineHeader = (props) => {
    return (
        <div id='xengine-header' className='xengine-header'>
            <XEngineLocationName name={props.default} colors={props.colors}/>
        </div>
    );
};

XEngineHeader.propTypes = {
    default: PropTypes.string.isRequired,
    colors: PropTypes.object
};

export default XEngineHeader;
