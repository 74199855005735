import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import XEngineMenu from "../xengine-menu/xengine-menu";
import XEngineBtn from "../xengine-btn/xengine-btn";
import XEngineStaticBtn from "../xengine-static-btn/xengine-static-btn";
import XEngineHeader from "../xengine-header/xengine-header";
import Notification from "../notification/notification";
import ModalGuide from "../xengine-modal-guide/xengine-modal-guide";
import XEngineReactions from "../xengine-reactions/xengine-reactions";
import XEngineBtnReactions from "../xengine-btn-reactions/xengine-btn-reactions";
import XEngineShare from "../xengine-share/xengine-share";
import { mobileAndTabletCheck, iOS } from "../../../../helpers";
import XEngineBtnTips from "../xengine-btn-tips/xengine-btn-tips";
import { default as $t } from "../../../../i18nProvider/translate";
import PropTypes from "prop-types";
import { api } from "../../../../managers/api/api-manager";
import settings from "../../../../models/settings";
// import CallToAction from "../call-to-action/call-to-action";
import SponsorPanel from "../sponsor-panel/sponsor-panel";
import XEngineGuidePhoto from "../xengine-guide-photo/xengine-guide-photo";
import ModalTrivia from "../xengine-modal-trivia/xengine-modal-trivia";
import { components } from "aframe";
import { isMobileOnly } from "react-device-detect";
import ReactGA from "react-ga";
import NavigationMenu from "../navigation-menu/navigation-menu";

const XEngineUI = (props) => {
  const exit = new Event("exit");
  const isMobile = mobileAndTabletCheck();
  const isIOs = iOS();
  const [fullScreen, setFullScreen] = useState(false);
  const liveSession = props.liveSession;
  const mode = props.mode;
  let isGuide = false;
  let isAdmin = false;
  if (mode == 1) {
    isGuide = api.sessionUser.isGuide();
    isAdmin = api.sessionUser.isAdmin();
  }
  const raiseHand = new Event("raisehand");
  const color1 = props.experience.colors?.filter(
    (color) => color.order == 0
  )[0];
  const color2 = props.experience.colors?.filter(
    (color) => color.order == 1
  )[0];
  const [colors, setColors] = useState({
    primary_light: color1?.color?.primary || "#202945",
    primary_dark: color1?.color?.secondary || "#ffffff",
    secondary_light: color2?.color?.primary || "#00f0a1",
    secondary_dark: color2?.color?.secondary || "#202945",
  });
  const [menuState, setMenuState] = useState("close");
  const [menuReactionsState, setMenuReactionsState] = useState("close");
  const [videoState, setVideoState] = useState("open");
  const [mute, setMute] = useState(true);
  const [autoAction, setAutoAction] = useState(settings.autoAction);
  const [openTriviaModal, setOpenTriviaModal] = useState(false);
  const [trivia, setTrivia] = useState();
  const [actualLocation, setActualLocation] = useState(0);

  const closeMenu = () => {
    let menu = document.getElementById("xengine-menu");
    menu.classList.add("menu-hidden");
    setMenuState("close");
  };

  const lead = () => {
    ReactGA.event({
      category: "tour-results",
      action: props.experience?.slug + "-lead",
    });
  };

  const callToActionUrl = isMobileOnly
    ? props.experience?.call_to_action.mobile_redirect
    : props.experience?.call_to_action.redirect;

  const changeColor = (evt) => {
    // "colors": [
    //     {
    //         "id": 1,
    //         "color": {
    //             "id": 1,
    //             "primary": "#ffffff",
    //             "secondary": "#ffffff"
    //         },
    //         "order": 0
    //     },
    //     {
    //         "id": 2,
    //         "color": {
    //             "id": 2,
    //             "primary": "#202945",
    //             "secondary": "#ffffff"
    //         },
    //         "order": 1
    //     }]

    const newColor1 = evt.detail.filter((color) => color.order == 0)[0].color;
    const newColor2 = evt.detail.filter((color) => color.order == 1)[0].color;

    const newColors = {
      primary_light: newColor1.primary,
      primary_dark: newColor1.secondary,
      secondary_light: newColor2.primary,
      secondary_dark: newColor2.secondary,
    };

    setColors({ ...newColors });
  };

  useEffect(() => {
    window.addEventListener("update-color", (e) => changeColor(e));
    document.addEventListener("OpenTriviaModal", (e) => {
      setOpenTriviaModal(true);
      setTrivia(e.detail);
    });
    document.addEventListener("CloseTriviaModal", () =>
      setOpenTriviaModal(false)
    );
    window.addEventListener("newLocation", locationPR);

    document.addEventListener("trigger-next-location", function () {
      NextLocation();
    });
  }, []);

  useEffect(() => {
    if (props.experience) {
      document
        .querySelector("a-scene")
        .addEventListener("enter-vr", function () {
          window.sessionStorage.setItem("in-vr", true);
          window.sessionStorage.setItem("actualLocationIndex", 0);

          window.dispatchEvent(new CustomEvent("firstDrag"));

          if (props.experience.locations[0].waypoints.length == 0) {
            const duration =
              document.querySelector(
                `#sound-${props.experience.locations[0].audio_id}`
              )?.duration || 15;
            setTimeout(() => NextLocation(), Math.max(20, duration + 5) * 1000);
          }
        });

      document
        .querySelector("a-scene")
        .addEventListener("exit-vr", function () {
          window.sessionStorage.setItem("in-vr", false);
        });
    }
  }, [props.experience]);

  const closeMenuReactions = () => {
    let menu = document.getElementById("xengine-reactions");
    menu.classList.toggle("menu-hidden-r");
    setMenuReactionsState("close");
  };

  const handleAutoAction = () => {
    if (autoAction) {
      setAutoAction(false);
      settings.autoAction = false;
    } else {
      if (window.localStorage.getItem("guideLocation")) {
        setTimeout(() => {
          window.dispatchEvent(new Event("teleport"));
        }, 1200);
      }
      setAutoAction(true);
      settings.autoAction = true;
    }
  };

  const handleMenuBtn = () => {
    let menu = document.getElementById("xengine-menu");
    if (menuState === "close") {
      menu.classList.remove("menu-hidden");
      setMenuState("open");
    } else {
      menu.classList.add("menu-hidden");
      setMenuState("close");
    }
  };

  const handleMenuReactionsBtn = () => {
    let menu = document.getElementById("xengine-reactions");
    menu.classList.toggle("menu-hidden-r");
    if (menuReactionsState === "close") {
      setMenuReactionsState("open");
    } else {
      setMenuReactionsState("close");
    }
  };

  const handleRaiseHand = () => {
    window.dispatchEvent(raiseHand);
  };

  const handleVideoBtn = () => {
    let video = document.getElementById("jitsi-container-id");
    if (videoState === "open") {
      video.classList.add("video-hidden");
      setVideoState("close");
    } else {
      video.classList.remove("video-hidden");
      setVideoState("open");
    }
  };

  const handleMicrophone = () => {
    if (mute) {
      window.jitsi.executeCommand("toggleAudio");
      setMute(false);
    } else {
      window.jitsi.executeCommand("toggleAudio");
      setMute(true);
    }
  };

  window.jitsi?.on("audioMuteStatusChanged", (status) => {
    setMute(status.muted);
  });

  const showShareModal = () => {
    document.getElementById("share-modal").style.visibility = "visible";
  };

  const handleParticipants = () => {
    // No content yet
  };

  const requestFullScreen = () => {
    let elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
    setFullScreen(true);
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }

    setFullScreen(false);
  };

  const handleExit = () => {
    window.sessionStorage.setItem("lastLocationID", null);
    window.sessionStorage.setItem("lastLocationRigYRotation", null);
    try {
      window.dispatchEvent(exit);
    } catch (err) {
      window.location.href = "https://app.immersotours.com";
    }
  };

  const NextLocation = () => {
    let auxActualLocation = parseFloat(
      window.sessionStorage.getItem("actualLocationIndex") || actualLocation
    );
    let nextLocationPosition = actualLocation + 1;
    if (actualLocation || auxActualLocation)
      nextLocationPosition = parseFloat(auxActualLocation) + 1;
    if (nextLocationPosition < props.experience.locations.length) {
      setActualLocation(nextLocationPosition);
      postMessage({
        action: "go-to-location",
        data: {
          targetID:
            props.experience.locations[nextLocationPosition].id_tour_creator,
          targetRy:
            props.experience.locations[nextLocationPosition].default_rotation
              ?.y || 0,
        },
      });
    }
  };

  const PreviousLocation = () => {
    let auxActualLocation = parseFloat(
      window.sessionStorage.getItem("actualLocationIndex") || actualLocation
    );
    let nextLocationPosition = actualLocation - 1;
    if (actualLocation || auxActualLocation)
      nextLocationPosition = auxActualLocation - 1;

    if (nextLocationPosition >= 0) {
      setActualLocation(nextLocationPosition);

      postMessage({
        action: "go-to-location",
        data: {
          targetID:
            props.experience.locations[nextLocationPosition].id_tour_creator,
          targetRy:
            props.experience.locations[nextLocationPosition].default_rotation
              ?.y || 0,
        },
      });
    }
  };

  const locationPR = (e) => {
    const newPosition = e.detail;
    setActualLocation(newPosition);
  };

  return (
    <div id="xengine-ui" className="xengine-ui noselect">
      {mode == 1 ? (
        <div className="menu-btn-container-r">
          <a data-tip data-for="MenuReaction">
            <XEngineBtnReactions
              id="menu-reactions"
              imageURL=""
              icon={
                menuReactionsState === "close" ? "grin-beam" : "chevron-right"
              }
              class="menu-reaction"
              onClick={handleMenuReactionsBtn}
            />
          </a>
          {isMobile === true ? (
            ""
          ) : (
            <ReactTooltip
              id="MenuReaction"
              type="warning"
              border={false}
              borderColor={colors.secondary_light}
              backgroundColor={colors.primary_light}
              effect="solid"
              place="left"
            >
              {" "}
              <span>{$t("reactions")}</span>{" "}
            </ReactTooltip>
          )}
        </div>
      ) : (
        <div />
      )}

      {mode == 1 ? <XEngineBtnTips experience={props.experience} /> : <div />}
      {mode != 2 && <SponsorPanel sponsors={props.experience.sponsors} />}
      {mode != 2 && (
        <NavigationMenu
          locations={props.experience.locations}
          colors={colors}
          sponsors={props.experience.sponsors}
        />
      )}
      <XEngineHeader
        default={props.experience?.locations[0]?.name || ""}
        colors={colors}
      />
      <Notification />
      {mode != 2 && <XEngineShare experience={props.experience} />}
      {/* <XEngineMenu liveSession= {mode === 1} formUrl={props.experience.survey_code} isGuide={isGuide} closeMenu={closeMenu} /> */}
      {/* {mode != 1 && <CallToAction  colors={colors} callToAction={props.experience.call_to_action} slug={props.experience.slug}/>} */}
      {mode === 0 && <ModalGuide visible={liveSession} />}
      {mode && <XEngineReactions closeMenu={closeMenuReactions} />}
      {mode == 1 && (
        <div id="animation-reactions" className="">
          <img id="animationImage" src=""></img>
        </div>
      )}
      {mode == 1 ? (
        <div className="menu-video-btn-container">
          <a data-tip data-for="Video">
            <XEngineBtn
              id="video-btn"
              icon=""
              imageURL={
                videoState === "open" ? "/img/minimizar.png" : "/img/guia.png"
              }
              class=" menu-video"
              onClick={handleVideoBtn}
            />
          </a>
          {isMobile === true ? (
            ""
          ) : (
            <ReactTooltip
              id="Video"
              type="warning"
              border={false}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>
                {videoState === "open" ? $t("minimize") : $t("see-video-guide")}
              </span>{" "}
            </ReactTooltip>
          )}
        </div>
      ) : (
        <div />
      )}
      {mode == 1 ? (
        <div className="menu-static-container">
          {!fullScreen && !isIOs && (
            <a data-tip data-for="Expand">
              {" "}
              <XEngineStaticBtn
                borderColor={colors.primary_dark}
                backgroundColor={colors.primary_light}
                textColor={colors.primary_dark}
                id="expand"
                class=""
                imageURL=""
                icon="expand"
                onClick={requestFullScreen}
              />
            </a>
          )}
          {!fullScreen && !isMobile && (
            <ReactTooltip
              id="Expand"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("fullscreen")}</span>{" "}
            </ReactTooltip>
          )}
          {fullScreen && !isIOs && (
            <a data-tip data-for="Compress">
              {" "}
              <XEngineStaticBtn
                borderColor={colors.primary_dark}
                backgroundColor={colors.primary_light}
                textColor={colors.primary_dark}
                id="compress"
                class=""
                imageURL=""
                icon="compress"
                onClick={closeFullscreen}
              />
            </a>
          )}
          {fullScreen && !isMobile && (
            <ReactTooltip
              id="Compress"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("standard-screen")}</span>{" "}
            </ReactTooltip>
          )}

          <a data-tip data-for="Microphone">
            <XEngineStaticBtn
              borderColor={colors.primary_dark}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              id="miStaticcrophone"
              class={mute ? "mute" : "unmute"}
              icon={mute ? "microphone-slash" : "microphone"}
              onClick={handleMicrophone}
            />
          </a>
          {!isMobile && (
            <ReactTooltip
              id="Microphone"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>
                {mute ? $t("activate-microphone") : $t("mute-microphone")}{" "}
              </span>{" "}
            </ReactTooltip>
          )}

          <a data-tip data-for="Raise">
            <XEngineStaticBtn
              borderColor={colors.primary_dark}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              id="raise"
              class=""
              imageURL=""
              icon="hand-paper"
              onClick={handleRaiseHand}
            />
          </a>
          {!isMobile && (
            <ReactTooltip
              id="Raise"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("raise-your-hand")}</span>{" "}
            </ReactTooltip>
          )}

          <a data-tip data-for="Auto">
            {" "}
            <XEngineStaticBtn
              borderColor={colors.primary_dark}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              textId="auto"
              text={$t("auto")}
              id="auto"
              class={autoAction ? "autoOn" : "autoOff"}
              onClick={handleAutoAction}
            />
          </a>
          {!isMobile && (
            <ReactTooltip
              id="Auto"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("automatic-mode")}</span>{" "}
            </ReactTooltip>
          )}

          <a data-tip data-for="Exit">
            {" "}
            <XEngineStaticBtn
              borderColor={colors.primary_dark}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              id="exit"
              class=""
              imageURL=""
              icon="globe-americas"
              onClick={handleExit}
            />
          </a>
          {!isMobile && (
            <ReactTooltip
              id="Exit"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("exit")}</span>{" "}
            </ReactTooltip>
          )}
        </div>
      ) : mode == 0 ? (
        <div className="menu-static-container">
          <a data-tip data-for="Left">
            {" "}
            <XEngineStaticBtn
              borderColor={colors.primary_dark}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              onClick={PreviousLocation}
              id="arrow-left"
              class=""
              imageURL=""
              icon="arrow-left"
            />
          </a>
          {!isMobile && (
            <ReactTooltip
              id="Left"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("left")}</span>{" "}
            </ReactTooltip>
          )}

          {!fullScreen && !isIOs && (
            <a data-tip data-for="Expand">
              {" "}
              <XEngineStaticBtn
                borderColor={colors.primary_dark}
                backgroundColor={colors.primary_light}
                textColor={colors.primary_dark}
                id="expand"
                class=""
                imageURL=""
                icon="expand"
                onClick={requestFullScreen}
              />
            </a>
          )}
          {!fullScreen && !isMobile && (
            <ReactTooltip
              id="Expand"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("fullscreen")}</span>{" "}
            </ReactTooltip>
          )}
          {fullScreen && !isIOs && (
            <a data-tip data-for="Compress">
              {" "}
              <XEngineStaticBtn
                borderColor={colors.primary_dark}
                backgroundColor={colors.primary_light}
                textColor={colors.primary_dark}
                id="compress"
                class=""
                imageURL=""
                icon="compress"
                onClick={closeFullscreen}
              />
            </a>
          )}
          {fullScreen && !isMobile && (
            <ReactTooltip
              id="Compress"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("standard-screen")}</span>{" "}
            </ReactTooltip>
          )}

          {props.experience.active && (
            <a
              className="btn-tooltip"
              data-tip
              data-for="Info"
              rel="noreferrer"
              href={callToActionUrl}
              target="_blank"
              onClick={lead}
            >
              <XEngineStaticBtn
                borderColor={colors.secondary_light}
                backgroundColor={colors.primary_light}
                textColor={colors.primary_dark}
                onClick={() => {}}
                id="info"
                class=""
                imageURL=""
                icon="calendar-alt"
              />
            </a>
          )}
          {props.experience.active && !isMobile && (
            <ReactTooltip
              id="Info"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("call-to-action-bookings-text")}</span>{" "}
            </ReactTooltip>
          )}

          {props.experience.active && (
            <a data-tip data-for="Share">
              {" "}
              <XEngineStaticBtn
                borderColor={colors.primary_dark}
                backgroundColor={colors.primary_light}
                textColor={colors.primary_dark}
                onClick={showShareModal}
                id="share"
                class=""
                imageURL=""
                icon="share"
              />
            </a>
          )}
          {props.experience.active && !isMobile && (
            <ReactTooltip
              id="Share"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("share")}</span>{" "}
            </ReactTooltip>
          )}

          {
            <a data-tip data-for="Right">
              {" "}
              <XEngineStaticBtn
                borderColor={colors.primary_dark}
                backgroundColor={colors.primary_light}
                textColor={colors.primary_dark}
                onClick={NextLocation}
                id="arrow-right"
                class=""
                imageURL=""
                icon="arrow-right"
              />
            </a>
          }
          {!isMobile && (
            <ReactTooltip
              id="Right"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("right")}</span>{" "}
            </ReactTooltip>
          )}

          {/* { props.experience.active && <a data-tip data-for='Exit'> <XEngineStaticBtn borderColor={colors.primary_dark} backgroundColor={colors.primary_light} textColor={colors.primary_dark} id="exit" class="" imageURL="" icon='globe-americas' onClick={handleExit} /></a>}
                { props.experience.active && !isMobile && <ReactTooltip id='Exit' type='warning' border={true} borderColor={colors.primary_light} backgroundColor={colors.primary_light} textColor={colors.primary_dark} effect='solid'> <span>{$t("exit")}</span> </ReactTooltip>} */}
        </div>
      ) : (
        <div />
      )}
      {mode && (
        <div
          className={
            isGuide || isAdmin
              ? "menu-participant-counters-container"
              : "hidden"
          }
        >
          <a data-tip data-for="Participants">
            <XEngineBtn
              borderColor={colors.primary_dark}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              textId="participantsCounter"
              text={props.participantCount.toString()}
              id="participants-btn"
              icon="users"
              class="menu-participants"
              onClick={handleParticipants}
            />
          </a>
          {isMobile === true ? (
            ""
          ) : (
            <ReactTooltip
              id="Participants"
              type="warning"
              border={false}
              borderColor={colors.primary_light}
              backgroundColor={colors.primary_light}
              textColor={colors.primary_dark}
              effect="solid"
            >
              {" "}
              <span>{$t("participants")}</span>{" "}
            </ReactTooltip>
          )}
        </div>
      )}
      {mode == 0 || mode == 2 ? (
        <XEngineGuidePhoto
          imgGuideSrc={
            props.experience.assets.img.filter(
              (img) => img.id == props.experience.guide_img_id
            )[0]?.src || "../../assets/img/guidePhoto.jpg"
          }
        ></XEngineGuidePhoto>
      ) : (
        <div />
      )}
      <ModalTrivia
        visible={openTriviaModal}
        trivia={trivia}
        assets={props.experience.assets}
      />
    </div>
  );
};

XEngineUI.propTypes = {
  experience: PropTypes.object.isRequired,
  liveSession: PropTypes.bool.isRequired,
  participantCount: PropTypes.number,
  mode: PropTypes.number.isRequired,
};

export default XEngineUI;
