import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import audioManager from "../../../../managers/audio/audio-manager";

const XEngineGuidePhoto = (props) => {
    const [icon, setIcon]= useState("play");
    let containerGuidePhoto= document.getElementById("guide-photo");
    const [firstClick, setFirstClick]= useState(false);
    const srcParts = props.imgGuideSrc.split(".");
    const format = srcParts[srcParts.length - 1];

    const audioGuideHandler=()=>{
        if (firstClick){
            if(audioManager.pausedAudioLocation){
                audioManager.resumeAudioLocation();
                setIcon("pause");
                containerGuidePhoto.classList.add("animationAudioLocationHandler");
            }
            else{
                audioManager.pauseAudioLocation();
                setIcon("play");
                containerGuidePhoto.classList.remove("animationAudioLocationHandler");
            }
        }
    };

    document.addEventListener("EndAudioLocation", () => {
        if(containerGuidePhoto!=null){
            setIcon("play");
            audioManager.pausedAudioLocation= true;
            containerGuidePhoto.classList.remove("animationAudioLocationHandler");
            document.dispatchEvent(new CustomEvent("checkAudioLocation")); 
        }
    });

    document.addEventListener("pauseAudioLocation", () => {
        if(containerGuidePhoto!=null){
            setIcon("play");
            containerGuidePhoto.classList.remove("animationAudioLocationHandler");
        }
    });

    document.addEventListener("startAudioLocation", () => {
        if(containerGuidePhoto!=null){
            setIcon("pause");
            containerGuidePhoto.classList.add("animationAudioLocationHandler");
        }
    });

    document.addEventListener("firstClick", () => {
        setFirstClick(true);
    });
    
    return (
        <div id="guide-photo" className={`xengine-guide-photo ${format != "png" ? "circle":""}`}>
            <img onClick={audioGuideHandler} id="controlAudio" className="btnAudioHandler" src={`../../assets/img/${icon=="play"? "play": "pause"}-btn-audio-location.png`}></img>
            <img id="image-guide-photo" src={props.imgGuideSrc} />
        </div>
    );
};

XEngineGuidePhoto.propTypes = {
    imgGuideSrc: PropTypes.string.isRequired
};


export default XEngineGuidePhoto;
