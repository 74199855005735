/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

import { mobileAndTabletCheck } from "../../helpers";
import { interacionManager } from "../../managers/interaction/interaction-manager";

AFRAME.registerComponent("trivia-hotspot", {
    schema: {
        origin: { type: "string", default: "" },
        position: { type: "string", default: "" },
        mode: { type: "number", default: 0 },
        trivia:{type: "string", default: "{}"},
    },
    init: function () {
        let data = this.data;
        let hotspotTriviaID = this.el.id;
        let opentriviabtnID = `openbtn-trivia-${hotspotTriviaID}`;
        let opentriviabtn = document.getElementById(opentriviabtnID);
        let trivia=JSON.parse(data.trivia);

        this.el.addEventListener("animationcomplete__return", () => {
            this.el.setAttribute("visible", false);
        });

        opentriviabtn.addEventListener("click", () => {
            if(mobileAndTabletCheck() || !interacionManager.wasLastInteractionDrag()){
                document.dispatchEvent(new CustomEvent("OpenTriviaModal", { detail: trivia }));
            }
        });

    }
});
