/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

AFRAME.registerComponent("hotspots", {
    init: function () {
        this.el.addEventListener("reloadhotspots", function (evt) {

            if (evt.detail.currhotspots) {
                //get the entire current spot group and scale it to 0
                let currhotspotgroup = document.getElementById(evt.detail.currhotspots);
                if (currhotspotgroup) {
                    currhotspotgroup.setAttribute("visible", false);
                    currhotspotgroup.setAttribute("scale", "0 0 0");
                }
            }

            //get the entire new spot group and scale it to 1
            let newhotspotgroup = document.getElementById(evt.detail.newhotspots);
            newhotspotgroup.setAttribute("visible", true);
            newhotspotgroup.setAttribute("scale", "1 1 1");
        });
    }
});
