/**
 * @fileoverview
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */
import { directionToVector } from "../../helpers";

AFRAME.registerComponent("cursorclickhotspot", {
  schema: {
    hotspot: { type: "string", default: "" },
  },
  init: function () {
    let hotspot = JSON.parse(this.data.hotspot);
    let image = hotspot.image_id;
    let assetID = `img-${image}`;
    const hotspotPosition = [
      hotspot.position.origin[0],
      hotspot.position.origin[1],
      hotspot.position.origin[2] + 1,
    ];
    const containerPosition = directionToVector(
      hotspotPosition,
      hotspot.position.x_angle,
      hotspot.position.y_angle,
      hotspot.position.distance - 1
    );
    let asset = document.getElementById(assetID);
    hotspot.src = asset?.src;

    const setVRInfo = () => {
      const waypointsGroup = document.getElementById(
        `group-${window.localStorage.getItem("locationID")}`
      );
      const hotspotsGroup = document.getElementById(
        `hotspots-${window.localStorage.getItem("locationID")}`
      );
      const hotspotCointainerVR = document.getElementById(
        "hotspotCointainerVR"
      );
      const closeButtonVR = document.getElementById("VRCloseButton");

      const closeButtonBG = document.getElementById("VRCloseButtonBG");
      const closeButtonBorder = document.getElementById("VRCloseButtonBorder");
      const closeButtonText = document.getElementById("VRCloseButtonText");

      const closeButtonInfoBG = document.getElementById("VRCloseButtonInfoBG");
      const closeButtonInfoBorder = document.getElementById(
        "VRCloseButtonInfoBorder"
      );
      const closeButtonInfoText = document.getElementById("btnInfoButtonVR");

      const infoButtonVR = document.getElementById("VRWatchInfo");
      waypointsGroup.setAttribute("scale", `0 0 0`);
      hotspotsGroup.setAttribute("scale", `0 0 0`);
      //Code for VR hotspot
      if (hotspot.info != "") {
        hotspotCointainerVR.setAttribute(
          "text",
          `value: ${hotspot.info}; opacity: 0; align: center; font: ../../assets/Eina01-Bold-msdf.json; font-image: ../../assets/Eina01-Bold.png; negate: false; wrapCount: 80`
        );

        infoButtonVR.setAttribute(
          "position",
          `${hotspot.position.origin[0] - 0.3}  ${
            hotspot.position.origin[1] - 2
          } ${hotspot.position.origin[2] + 0.1}`
        );
        //infoButtonVR.setAttribute("text", "xOffset: .44 width: 1; color: white; value: Info; side: double;")
        //infoButtonVR.setAttribute("geometry", "primitive: box; width: .2; height: .1; depth: 0")
        closeButtonInfoText.setAttribute("scale", "0.18 0.18 0.18");
        closeButtonInfoBG.setAttribute("scale", `1`);
        closeButtonInfoBorder.setAttribute("scale", `1`);
      } else {
        infoButtonVR.setAttribute("text", "value: ");
        hotspotCointainerVR.setAttribute(
          "text",
          `value: ; opacity: 0; align: center`
        );
      }

      const vrHotspotDistance = 7;
      const vrHotspotPosition = directionToVector(
        hotspotPosition,
        0,
        hotspot.position.y_angle,
        vrHotspotDistance
      );

      hotspotCointainerVR.setAttribute("position", `${vrHotspotPosition}`);
      //TODO change position to top right
      closeButtonVR.setAttribute(
        "position",
        `${hotspot.position.origin[0] + 0.3}  ${
          hotspot.position.origin[1] - 2
        } ${hotspot.position.origin[2] + 0.1}`
      );
      //closeButtonVR.setAttribute("text", "xOffset: .44 width: 1; color: white; value: Close; side: double;")

      closeButtonText.setAttribute("scale", "0.18 0.18 0.18");
      closeButtonBG.setAttribute("scale", `1`);
      closeButtonBorder.setAttribute("scale", `1`);

      hotspotCointainerVR.setAttribute("src", hotspot.src);
      const expectedHeight = 1;
      const aspectRatio = asset.naturalHeight / asset.naturalWidth;
      hotspotCointainerVR.setAttribute("height", expectedHeight);
      hotspotCointainerVR.setAttribute("width", expectedHeight / aspectRatio);
      //closeButtonVR.setAttribute("geometry", "primitive: box; width: .2; height: .1; depth: 0")

      closeButtonVR.addEventListener("click", function () {
        hotspotCointainerVR.setAttribute(
          "text",
          `value: ; opacity: 0; align: center`
        );
      });
    };

    this.el.addEventListener("click", function () {
      //Hide all the hotspots ans waypoints
      document.querySelector("a-scene").is("vr-mode") ? setVRInfo() : null;
    });
  },
});
