import { w3cwebsocket as W3CWebSocket } from "websocket";
import { ManagementAPI } from "./management";
/* global $PROCESS_ENV_WS_API_URL  */

export class ExperienceAPI {

    constructor() {
        this.base_url = "https://api.immersotours.com";
        this.base_ws_url = "wss://api.theimmerso.com";
        this.hasLocationEventListener = false;
        this.sessionUser = null;
        this.fullUrl = "";
        this.experienceId = null;
        this.userId = null;
        this.checkInterval = 1000 * 60; //in milliseconds
        this.checkReadyState = null; //function is called every this.checkInterval seconds to check if user disconnected
    }

    async getRegionsCountryList() {
        return await ManagementAPI.get(`${this.base_url}/api/regions/`);
    }

    async getExperienceList() {
        return await ManagementAPI.get(`${this.base_url}/api/experience/`);
    }

    async getExperienceBySlug(slug) {
        return await ManagementAPI.get(`${this.base_url}/api/experience/${slug}/`);
    }

    async getExperienceForExperiencePage(slug) {
        return await ManagementAPI.get(`${this.base_url}/api/experience-page/${slug}/`);
    }

    async getExperienceAccess(id) {
        return await ManagementAPI.get(`${this.base_url}/api/experience/has-access/${id}/`);
    }
    experienceCommunicationConnect(on_open, on_message, on_error, experience_id, user_id) {
        this.experienceId = experience_id;
        this.userId = user_id;
        this.fullUrl = `${this.base_ws_url}/ws/experience/${this.experienceId}/${this.userId}/`;
        try {
            this.client = new W3CWebSocket(this.fullUrl);

            this.checkReadyState = setInterval(() => {
                if (parseInt(this.client.readyState) !== 1)
                    this.reconnect();
            }, this.checkInterval);
        } catch (e) {
            // Continue regardless of error
        }
        this.client.onopen = on_open;
        this.client.onmessage = on_message;
        this.client.onerror = on_error;
    }
    async experienceCommunicationSend(message) {
        message["experience_id"] = this.experienceId;
        if (parseInt(this.client.readyState) === 1) {
            await this.client.send(JSON.stringify(message));
        } else {
            this.reconnect();
        }
    }

    closeExperienceCommunication() {
        this.client.close();
        clearInterval(this.checkReadyState);
    }

    reconnect() {
        this.closeExperienceCommunication();
        this.experienceCommunicationConnect(
            this.client.onopen, this.client.onmessage, this.client.oneerror, this.experienceId, this.userId
        );
    }
}
