import { Entity, Scene } from "aframe-react";

import "aframe-look-at-component";
import "aframe-fit-texture-component";
import "../../aframe/loadscreen";
import "../../aframe/waypoints";

import "../../aframe/cursClick";
import "../../aframe/cursorclickhotspot";
import "../../aframe/closeVRHotSpot";
import "../../aframe/InfoVRHotSpot";
import "../../aframe/cursorController";

import "../../aframe/spot";
import "../../aframe/hotspot";
import "../../aframe/hotspots";
import "../../aframe/triviaHotspot";
import "../../aframe/loadhandler";
import "../../aframe/lookatcamera";
import "../../aframe/environment";
import "../../aframe/interactiveObject";
import "../../aframe/moveWaypoint";
import "../../aframe/moveHotspot";
import "../../aframe/moveTrivia";
import "../../aframe/moveVideoHotspot";
import "../../aframe/video";
import "../../../managers/action/action-manager";
import "../../../managers/reactions/reactions-manager";
import "../../aframe/shaders/highlight";
import "../../aframe/look-controls";
import ReactDOM from "react-dom";
import React from "react";
import VideoHotspot from "../../xengine/hotspot/video-hotspot";
import TriviaHotspot from "../../xengine/hotspot/trivia-hotspot";
import Hotspot from "../../xengine/hotspot/hotspot";
import Waypoint from "../../xengine/waypoint/waypoint";
import Asset from "../../xengine/asset/asset";
import { startMoveToLocation } from "../../../managers/waypoints/waypoints-manager";
import {
  mobileAndTabletCheck,
  helpCalculateIfLiveSession,
  getOrientation,
} from "../../../helpers";
import {
  api,
  management,
  userLoggedIn,
} from "../../../managers/api/api-manager";
import XEngineUI from "../../xengine/ui/xengine-ui/xengine-ui";
import { LoadingScreen } from "../../xengine/loading-screen/loading-screen";
import ReactGA from "react-ga";
import audioManager from "../../../managers/audio/audio-manager";
import VideoConference from "../../xengine/video-conference/video-conference";
import PropTypes from "prop-types";
import { default as $t } from "../../../i18nProvider/translate";
import User from "../../../models/user";
import { cameraManager } from "../../../managers/camera/camera-manager";
import { openHotspot } from "../../../managers/hotspots/hotspots-manager";
import settings from "../../../models/settings";
import RigRotationPreview from "../../xengine/ui/editor-mode/rigrotation-preview/rigrotation-preview";
import { cartesianToPolar } from "../../../helpers";
import HotspotModal from "../../xengine/hotspot/hotspot-modal";

class XEngine extends React.Component {
  constructor(props) {
    super(props);

    this.jitsiDefaultParameters = {
      roomName: null,
      minParticipants: 1,
      disableRecordAudioNotification: false,
      configOverwrite: {
        apiLogLevels: [],
        disableDeepLinking: true,
        disableRecordAudioNotification: false,
        enableNoAudioDetection: true,
        enableNoisyMicDetection: false,
        enableTalkWhileMuted: false,
        prejoinPageEnabled: false,
        defaultLanguage: "en",
        hideLobbyButton: true,
        enableWelcomePage: false,
        startWithVideoMuted: true,
        startWithAudioMuted: true,
        noAutoPlayVideo: false,
      },
      interfaceConfigOverwrite: {
        TOOLBAR_BUTTONS: [],
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DISABLE_RINGING: true,
        DISABLE_PRESENCE_STATUS: true,
        DISABLE_VIDEO_BACKGROUND: true,
        DISPLAY_WELCOME_PAGE_CONTENT: false,
        DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
        DISPLAY_WELCOME_FOOTER: false,
        SHOW_CHROME_EXTENSION_BANNER: false,
        SHOW_JITSI_WATERMARK: false,
        VERTICAL_FILMSTRIP: false,
        ENABLE_DIAL_OUT: false,
        HIDE_DEEP_LINKING_LOGO: true,
        MOBILE_APP_PROMO: false,
        VIDEO_QUALITY_LABEL_DISABLED: true,
        FILM_STRIP_MAX_HEIGHT: 0,
      },
      userInfo: {
        email: null,
        displayName: null,
      },
    };
    this.state = {
      experience: null,
      api: {}, // Object that helps with communication between the API and back related to the experience
      management: {}, // Object that helps with API calls that have to do with the session management
      token: localStorage.getItem("token"), // Auth JWT token
      refresh_token: localStorage.getItem("refresh"), // Auth JWT token
      experienceId: null,
      experienceSlug: null,
      jitsiParameters: {}, // Configuration that is later on added, for the video call
      assetsLoaded: false,
      liveSession: false,
      mode:
        new URLSearchParams(location.search).get("j") === null
          ? 0
          : Number(new URLSearchParams(location.search).get("j")),
      enabledLocations: [],
      activeVR: false,
    };
    if (this.state.mode) {
      this.videocallSession = true; // Set this to false if you want to deactivate Jitsi
    } else {
      this.videocallSession = false; // Set this to false if you want to deactivate Jitsi
    }
    this.jitsiLoaded = false;
    this.handler = this.handler.bind(this);
    this.activateVRButton = this.activateVRButton.bind(this);
    window.addEventListener("loaded", this.activateVRButton);
    this.assetsLoaded = this.assetsLoaded.bind(this);
    document.addEventListener("assetsloaded", this.assetsLoaded);
    this.enableLocations = this.enableLocations.bind(this);
    document.addEventListener("enablelocation", this.enableLocations);
    window.localStorage.setItem("liveSession", this.state.liveSession);
    this.messageManager = this.messageManager.bind(this);
    window.addEventListener("message", this.messageManager, false);
  }

  activateVRButton() {
    console.log(window.AFRAME.utils.device.checkHeadsetConnected(), "check");
    if (window.AFRAME.utils.device.checkHeadsetConnected()) {
      this.setState({ activeVR: true });
    }
  }

  assetsLoaded() {
    this.setState({ assetsLoaded: true });
  }

  enableLocations(evt) {
    this.setState({ enabledLocations: evt.detail });
    window.sessionStorage.setItem("enabledLocations", evt.detail);
  }

  handler(experience, api, management, token, refresh_token) {
    this.setState({
      experience,
      api,
      management,
      token,
      refresh_token,
    });
    localStorage.setItem("token", token);
    localStorage.setItem("refresh", refresh_token);
  }

  componentWillUnmount() {
    audioManager.stopBackgroundSound();
    api.closeExperienceCommunication();
  }

  toggleMute() {
    if (window.jitsi) {
      window.jitsi.executeCommand("toggleAudio");
    }
  }

  calculateIfLiveSession(experience) {
    this.setState({
      liveSession: helpCalculateIfLiveSession(experience),
    });
  }

  messageManager(e) {
    let index,
      trivia,
      trivias,
      triviaId,
      idData,
      locationId,
      hotspotId,
      location,
      locationIndex,
      waypoint,
      hotspot,
      cam,
      rig,
      camRotation,
      rigRotation,
      title,
      info,
      group,
      waypointsGroup,
      hotspotsGroup;
    switch (e.data.action) {
      case "look-to-position":
        cameraManager.rotateToPoint(e.data.data, 500);
        break;
      case "update-assets":
        this.state.experience.assets = e.data.data;

        let assets = document.querySelector("#assets");

        ReactDOM.render(
          [
            <img
              crossOrigin="true"
              id="card"
              src={
                "https://immerso-experience.s3.amazonaws.com/media/experience/images/carddosrios.png"
              }
            ></img>,
            ...this.state.experience.assets.img.map((img) => (
              <img
                onLoad={this.assetLoad}
                crossOrigin="true"
                key={`img-${img.id}`}
                id={`img-${img.id}`}
                src={img.src}
              ></img>
            )),
            ...this.state.experience.assets.sound.map((sound) => (
              <audio
                crossOrigin="true"
                key={`sound-${sound.id}`}
                id={`sound-${sound.id}`}
                src={sound.src}
                loop={true}
                preload="metadata"
              ></audio>
            )),
            ...this.state.experience.assets.video.map((video) => (
              <video
                key={`video-${video.id}`}
                id={`video-${video.id}`}
                loop
                crossOrigin="anonymous"
                src={video.src}
              ></video>
            )),
          ],
          assets
        );
        break;
      case "create-location":
        this.state.experience.locations.push(e.data.data);
        management.experience.locations.push(e.data.data);

        hotspotsGroup = document.querySelector("#hotspots");
        waypointsGroup = document.querySelector("#spots");

        ReactDOM.render(
          this.state.experience.locations.map((location) => {
            if (location.id_tour_creator === e.data.data.id_tour_creator) {
              let trivias = location.trivias.map((hotspotTrivia, index) => (
                <TriviaHotspot
                  id={`trivia_${hotspotTrivia.id_tour_creator}`}
                  key={`trivia_${hotspotTrivia.id_tour_creator}`}
                  triviaHotspot={hotspotTrivia}
                  index={index}
                />
              ));
              return (
                <Entity
                  key={`key_hotspots_${location.id_tour_creator}`}
                  id={`hotspots-${location.id_tour_creator}`}
                >
                  {location.hotspots.map((hotspot, index) =>
                    hotspot.video_url !== null ? (
                      <VideoHotspot
                        id={`hotspot_${hotspot.id_tour_creator}`}
                        key={`hotspot_${hotspot.id_tour_creator}`}
                        hotspot={hotspot}
                        index={index}
                      />
                    ) : (
                      <Hotspot
                        id={`hotspot_${hotspot.id_tour_creator}`}
                        key={`hotspot_${hotspot.id_tour_creator}`}
                        hotspot={hotspot}
                        index={index}
                        colors={this.state.experience.colors}
                      />
                    )
                  )}
                  {trivias}
                </Entity>
              );
            }
            return (
              <Entity
                key={`key_hotspots_${location.id_tour_creator}`}
                id={`hotspots-${location.id_tour_creator}`}
                visible={false}
                scale="0 0 0"
              >
                {location.hotspots.map((hotspot, index) =>
                  hotspot.video_url !== null ? (
                    <VideoHotspot
                      id={`hotspot_${hotspot.id_tour_creator}`}
                      key={`hotspot_${hotspot.id_tour_creator}`}
                      hotspot={hotspot}
                      index={index}
                    />
                  ) : (
                    <Hotspot
                      id={`hotspot_${hotspot.id_tour_creator}`}
                      key={`hotspot_${hotspot.id_tour_creator}`}
                      hotspot={hotspot}
                      index={index}
                      colors={this.state.experience.colors}
                    />
                  )
                )}
                {trivias}
              </Entity>
            );
          }),
          hotspotsGroup
        );

        ReactDOM.render(
          this.state.experience.locations.map((location) => {
            if (location.id_tour_creator === e.data.data.id_tour_creator) {
              return (
                <Entity
                  key={`key_group_${location.id_tour_creator}`}
                  id={`group-${location.id_tour_creator}`}
                  class="waypoint-group"
                >
                  {location.waypoints.map((waypoint, index) => {
                    if (waypoint.use_default) {
                      waypoint.scale =
                        this.state.experience.default_waypoint.scale;
                      waypoint.text_scale =
                        this.state.experience.default_waypoint.text_scale;
                      waypoint.text_position =
                        this.state.experience.default_waypoint.text_position;
                      waypoint.text_color =
                        this.state.experience.default_waypoint.text_color;
                    }
                    return (
                      <Waypoint
                        id={`waypoint_${waypoint.id_tour_creator}`}
                        key={`waypoint_${waypoint.id_tour_creator}`}
                        waypoint={waypoint}
                        first={index === 0}
                        colors={this.state.experience.colors}
                      />
                    );
                  })}
                </Entity>
              );
            }
            return (
              <Entity
                key={`key_group_${location.id_tour_creator}`}
                id={`group-${location.id_tour_creator}`}
                visible={false}
                scale="0 0 0"
                class="waypoint-group"
              >
                {location.waypoints.map((waypoint) => {
                  if (waypoint.use_default) {
                    waypoint.scale =
                      this.state.experience.default_waypoint.scale;
                    waypoint.text_scale =
                      this.state.experience.default_waypoint.text_scale;
                    waypoint.text_position =
                      this.state.experience.default_waypoint.text_position;
                    waypoint.text_color =
                      this.state.experience.default_waypoint.text_color;
                  }
                  return (
                    <Waypoint
                      id={`waypoint_${waypoint.id_tour_creator}`}
                      key={`waypoint_${waypoint.id_tour_creator}`}
                      waypoint={waypoint}
                      first={false}
                      colors={this.state.experience.colors}
                    />
                  );
                })}
              </Entity>
            );
          }),
          waypointsGroup
        );

        startMoveToLocation(e.data.data.id_tour_creator);
        break;
      case "delete-location":
        let firstLocationID =
          this.state.experience.locations[0]?.id_tour_creator || "";
        startMoveToLocation(firstLocationID);

        const idToDelete = e.data.data;
        const locationToDelete = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == idToDelete
        )[0];
        index = this.state.experience.locations.indexOf(locationToDelete);
        if (index > -1) {
          this.state.experience.locations.splice(index, 1);
        }

        hotspotsGroup = document.querySelector("#hotspots");
        waypointsGroup = document.querySelector("#spots");

        ReactDOM.render(
          this.state.experience.locations.map((location) => {
            let trivias = location.trivias.map((hotspotTrivia, index) => (
              <TriviaHotspot
                id={`trivia_${hotspotTrivia.id_tour_creator}`}
                key={`trivia_${hotspotTrivia.id_tour_creator}`}
                triviaHotspot={hotspotTrivia}
                index={index}
              />
            ));
            if (location.id_tour_creator == firstLocationID) {
              return (
                <Entity
                  key={`key_hotspots_${location.id_tour_creator}`}
                  id={`hotspots-${location.id_tour_creator}`}
                >
                  {location.hotspots.map((hotspot, index) =>
                    hotspot.video_url !== null ? (
                      <VideoHotspot
                        id={`hotspot_${hotspot.id_tour_creator}`}
                        key={`hotspot_${hotspot.id_tour_creator}`}
                        hotspot={hotspot}
                        index={index}
                      />
                    ) : (
                      <Hotspot
                        id={`hotspot_${hotspot.id_tour_creator}`}
                        key={`hotspot_${hotspot.id_tour_creator}`}
                        hotspot={hotspot}
                        index={index}
                        colors={this.state.experience.colors}
                      />
                    )
                  )}
                  {trivias}
                </Entity>
              );
            }
            return (
              <Entity
                key={`key_hotspots_${location.id_tour_creator}`}
                id={`hotspots-${location.id_tour_creator}`}
                visible={false}
                scale="0 0 0"
              >
                {location.hotspots.map((hotspot, index) =>
                  hotspot.video_url !== null ? (
                    <VideoHotspot
                      id={`hotspot_${hotspot.id_tour_creator}`}
                      key={`hotspot_${hotspot.id_tour_creator}`}
                      hotspot={hotspot}
                      index={index}
                    />
                  ) : (
                    <Hotspot
                      id={`hotspot_${hotspot.id_tour_creator}`}
                      key={`hotspot_${hotspot.id_tour_creator}`}
                      hotspot={hotspot}
                      index={index}
                      colors={this.state.experience.colors}
                    />
                  )
                )}
                {trivias}
              </Entity>
            );
          }),
          hotspotsGroup
        );

        ReactDOM.render(
          this.state.experience.locations.map((location) => {
            if (location.id_tour_creator == firstLocationID) {
              return (
                <Entity
                  key={`key_group_${location.id_tour_creator}`}
                  id={`group-${location.id_tour_creator}`}
                  class="waypoint-group"
                >
                  {location.waypoints.map((waypoint, index) => {
                    if (waypoint.use_default) {
                      waypoint.scale =
                        this.state.experience.default_waypoint.scale;
                      waypoint.text_scale =
                        this.state.experience.default_waypoint.text_scale;
                      waypoint.text_position =
                        this.state.experience.default_waypoint.text_position;
                      waypoint.text_color =
                        this.state.experience.default_waypoint.text_color;
                    }
                    return (
                      <Waypoint
                        id={`waypoint_${waypoint.id_tour_creator}`}
                        key={`waypoint_${waypoint.id_tour_creator}`}
                        waypoint={waypoint}
                        first={index === 0}
                        colors={this.state.experience.colors}
                      />
                    );
                  })}
                </Entity>
              );
            }
            return (
              <Entity
                key={`key_group_${location.id_tour_creator}`}
                id={`group-${location.id_tour_creator}`}
                visible={false}
                scale="0 0 0"
                class="waypoint-group"
              >
                {location.waypoints.map((waypoint) => {
                  if (waypoint.use_default) {
                    waypoint.scale =
                      this.state.experience.default_waypoint.scale;
                    waypoint.text_scale =
                      this.state.experience.default_waypoint.text_scale;
                    waypoint.text_position =
                      this.state.experience.default_waypoint.text_position;
                    waypoint.text_color =
                      this.state.experience.default_waypoint.text_color;
                  }
                  return (
                    <Waypoint
                      id={`waypoint_${waypoint.id_tour_creator}`}
                      key={`waypoint_${waypoint.id_tour_creator}`}
                      waypoint={waypoint}
                      first={false}
                      colors={this.state.experience.colors}
                    />
                  );
                })}
              </Entity>
            );
          }),
          waypointsGroup
        );
        break;
      case "update-location":
        const updatedLocation = e.data.data;
        const idToUpdate = updatedLocation.id_tour_creator;
        const locationToUpdate = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == idToUpdate
        )[0];
        index = this.state.experience.locations.indexOf(locationToUpdate);
        if (index > -1) {
          this.state.experience.locations[index] = updatedLocation;
        }

        if (updatedLocation.bg_type == "img") {
          info = document.getElementById("skybox");
          info.setAttribute("src", `#img-${e.data.data.bg_id}`);
        } else {
          info = document.getElementById("videosphere");
          info.setAttribute("src", `#video-${e.data.data.video_id}`);
        }

        title = document.getElementById("xengine-location-name");
        title.innerHTML = updatedLocation.name;
        break;
      case "go-to-location":
        startMoveToLocation(e.data.data.targetID);
        break;
      case "create-waypoint":
        let newWaypoint = e.data.data.waypoint;
        cam = document.querySelector("#cam");
        rig = document.querySelector("#rig");
        camRotation = cam.getAttribute("rotation");
        rigRotation = rig.getAttribute("rotation");
        newWaypoint.position.y_angle = camRotation.y + rigRotation.y;
        newWaypoint.position.x_angle = camRotation.x;
        locationId = e.data.data.locationID;
        location = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == locationId
        )[0];
        locationIndex = this.state.experience.locations.indexOf(location);
        this.state.experience.locations[locationIndex].waypoints.push(
          newWaypoint
        );
        group = document.querySelector(`#group-${locationId}`);
        window.parent.postMessage(
          {
            action: "update-new-waypoint-position",
            data: { waypoint: newWaypoint },
          },
          "*"
        );
        ReactDOM.render(
          this.state.experience.locations[locationIndex].waypoints.map(
            (waypoint, index) => {
              if (waypoint.use_default) {
                waypoint.scale = this.state.experience.default_waypoint.scale;
                waypoint.text_scale =
                  this.state.experience.default_waypoint.text_scale;
                waypoint.text_position =
                  this.state.experience.default_waypoint.text_position;
                waypoint.text_color =
                  this.state.experience.default_waypoint.text_color;
              }
              return (
                <Waypoint
                  id={`waypoint_${waypoint.id_tour_creator}`}
                  key={`waypoint_${waypoint.id_tour_creator}`}
                  waypoint={waypoint}
                  first={index === 0}
                  colors={this.state.experience.colors}
                />
              );
            }
          ),
          group
        );
        break;
      case "delete-waypoint":
        locationId = e.data.data.locationID;
        location = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == locationId
        )[0];
        locationIndex = this.state.experience.locations.indexOf(location);
        waypoint = location.waypoints.filter(
          (waypoint) => waypoint.id_tour_creator == e.data.data.waypointID
        )[0];
        index = location.waypoints.indexOf(waypoint);
        if (locationIndex > -1) {
          this.state.experience.locations[locationIndex].waypoints.splice(
            index,
            1
          );
        }

        group = document.querySelector(`#group-${locationId}`);

        ReactDOM.render(
          this.state.experience.locations[locationIndex].waypoints.map(
            (waypoint, index) => {
              if (waypoint.use_default) {
                waypoint.scale = this.state.experience.default_waypoint.scale;
                waypoint.text_scale =
                  this.state.experience.default_waypoint.text_scale;
                waypoint.text_position =
                  this.state.experience.default_waypoint.text_position;
                waypoint.text_color =
                  this.state.experience.default_waypoint.text_color;
              }
              return (
                <Waypoint
                  id={`waypoint_${waypoint.id_tour_creator}`}
                  key={`waypoint_${waypoint.id_tour_creator}`}
                  waypoint={waypoint}
                  first={index === 0}
                  colors={this.state.experience.colors}
                />
              );
            }
          ),
          group
        );
        break;
      case "create-trivia":
        let newTrivia = e.data.data.trivia;
        cam = document.querySelector("#cam");
        rig = document.querySelector("#rig");
        camRotation = cam.getAttribute("rotation");
        rigRotation = rig.getAttribute("rotation");
        newTrivia.position.y_angle = camRotation.y + rigRotation.y;
        newTrivia.position.x_angle = camRotation.x;
        locationId = e.data.data.locationID;
        location = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == locationId
        )[0];
        locationIndex = this.state.experience.locations.indexOf(location);
        location.trivias.push(newTrivia);
        this.state.experience.locations[locationIndex].trivias =
          location.trivias;

        group = document.querySelector(`#hotspots-${locationId}`);

        trivias = location.trivias.map((hotspotTrivia, index) => (
          <TriviaHotspot
            id={`trivia_${hotspotTrivia.id_tour_creator}`}
            key={`trivia_${hotspotTrivia.id_tour_creator}`}
            triviaHotspot={hotspotTrivia}
            index={index}
          />
        ));

        ReactDOM.render(
          this.state.experience.locations[locationIndex].hotspots
            .map((hotspot, index) =>
              hotspot.video_url !== null ? (
                <VideoHotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                />
              ) : (
                <Hotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                  colors={this.state.experience.colors}
                />
              )
            )
            .concat(trivias),
          group
        );
        break;
      case "update-trivia":
        document.dispatchEvent(new CustomEvent("CloseTriviaModal"));
        locationId = e.data.data.locationID;
        triviaId = e.data.data.trivia.id_tour_creator;
        location = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == locationId
        )[0];
        locationIndex = this.state.experience.locations.indexOf(location);
        trivia = location.trivias.filter(
          (trivia) => trivia.id_tour_creator == triviaId
        )[0];
        index = location.trivias.indexOf(trivia);
        location.trivias[index] = e.data.data.trivia;
        this.state.experience.locations[locationIndex].trivias[index] =
          e.data.data.trivia;

        group = document.querySelector(`#hotspots-${locationId}`);
        trivias = location.trivias.map((hotspotTrivia, index) => {
          return (
            <TriviaHotspot
              id={`trivia_${hotspotTrivia.id_tour_creator}`}
              key={`trivia_${hotspotTrivia.id_tour_creator}`}
              triviaHotspot={hotspotTrivia}
              index={index}
            />
          );
        });
        ReactDOM.render(
          this.state.experience.locations[locationIndex].hotspots
            .map((hotspot, index) =>
              hotspot.video_url !== null ? (
                <VideoHotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                />
              ) : (
                <Hotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                  colors={this.state.experience.colors}
                />
              )
            )
            .concat(trivias),
          group
        );
        break;
      case "delete-trivia":
        document.dispatchEvent(new CustomEvent("CloseTriviaModal"));
        locationId = e.data.data.locationID;
        triviaId = e.data.data.triviaID;
        location = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == locationId
        )[0];
        locationIndex = this.state.experience.locations.indexOf(location);
        trivia = location.trivias.filter(
          (trivia) => trivia.id_tour_creator == triviaId
        )[0];
        index = location.trivias.indexOf(trivia);
        location.trivias.splice(index, 1);
        this.state.experience.locations[locationIndex].trivias =
          location.trivias;

        group = document.querySelector(`#hotspots-${locationId}`);
        trivias = location.trivias.map((hotspotTrivia, index) => (
          <TriviaHotspot
            id={`trivia_${hotspotTrivia.id_tour_creator}`}
            key={`trivia_${hotspotTrivia.id_tour_creator}`}
            triviaHotspot={hotspotTrivia}
            index={index}
          />
        ));
        ReactDOM.render(
          this.state.experience.locations[locationIndex].hotspots
            .map((hotspot, index) =>
              hotspot.video_url !== null ? (
                <VideoHotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                />
              ) : (
                <Hotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                  colors={this.state.experience.colors}
                />
              )
            )
            .concat(trivias),
          group
        );
        break;
      case "create-hotspot":
        let newHotspot = e.data.data.hotspot;
        cam = document.querySelector("#cam");
        rig = document.querySelector("#rig");
        camRotation = cam.getAttribute("rotation");
        rigRotation = rig.getAttribute("rotation");
        newHotspot.position.y_angle = camRotation.y + rigRotation.y;
        newHotspot.position.x_angle = camRotation.x;
        locationId = e.data.data.locationID;
        location = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == locationId
        )[0];
        locationIndex = this.state.experience.locations.indexOf(location);
        this.state.experience.locations[locationIndex].hotspots.push(
          newHotspot
        );

        group = document.querySelector(`#hotspots-${locationId}`);
        trivias = location.trivias.map((hotspotTrivia, index) => (
          <TriviaHotspot
            id={`trivia_${hotspotTrivia.id_tour_creator}`}
            key={`trivia_${hotspotTrivia.id_tour_creator}`}
            triviaHotspot={hotspotTrivia}
            index={index}
          />
        ));
        ReactDOM.render(
          this.state.experience.locations[locationIndex].hotspots
            .map((hotspot, index) =>
              hotspot.video_url !== null ? (
                <VideoHotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                />
              ) : (
                <Hotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                  colors={this.state.experience.colors}
                />
              )
            )
            .concat(trivias),
          group
        );
        break;
      case "update-waypoint":
        locationId = e.data.data.locationID;
        let waypointId = e.data.data.waypoint.id_tour_creator;
        location = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == locationId
        )[0];
        locationIndex = this.state.experience.locations.indexOf(location);
        waypoint = location.waypoints.filter(
          (waypoint) => waypoint.id_tour_creator == waypointId
        )[0];
        index = location.waypoints.indexOf(waypoint);
        this.state.experience.locations[locationIndex].waypoints[index] =
          e.data.data.waypoint;
        title = document.getElementById(`text-waypoint_${waypointId}`);
        title.setAttribute("value", e.data.data.waypoint.text);
        break;
      case "update-hotspot":
        locationId = e.data.data.locationID;
        hotspotId = e.data.data.hotspot.id_tour_creator;
        location = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == locationId
        )[0];
        locationIndex = this.state.experience.locations.indexOf(location);
        hotspot = location.hotspots.filter(
          (hotspot) => hotspot.id_tour_creator == hotspotId
        )[0];
        index = location.hotspots.indexOf(hotspot);

        if (
          e.data.data.hotspot.position.distance != hotspot.position.distance ||
          e.data.data.hotspot.has_info != hotspot.has_info ||
          e.data.data.hotspot.sound_id != hotspot.sound_id ||
          e.data.data.hotspot.video_url != hotspot.video_url
        ) {
          this.state.experience.locations[locationIndex].hotspots[index] =
            e.data.data.hotspot;

          group = document.querySelector(`#hotspots-${locationId}`);
          trivias = location.trivias.map((hotspotTrivia, index) => (
            <TriviaHotspot
              id={`trivia_${hotspotTrivia.id_tour_creator}`}
              key={`trivia_${hotspotTrivia.id_tour_creator}`}
              triviaHotspot={hotspotTrivia}
              index={index}
            />
          ));
          ReactDOM.render(
            this.state.experience.locations[locationIndex].hotspots
              .map((hotspot, index) =>
                hotspot.video_url !== null ? (
                  <VideoHotspot
                    id={`hotspot_${hotspot.id_tour_creator}`}
                    key={`hotspot_${hotspot.id_tour_creator}`}
                    hotspot={hotspot}
                    index={index}
                  />
                ) : (
                  <Hotspot
                    id={`hotspot_${hotspot.id_tour_creator}`}
                    key={`hotspot_${hotspot.id_tour_creator}`}
                    hotspot={hotspot}
                    index={index}
                    colors={this.state.experience.colors}
                  />
                )
              )
              .concat(trivias),
            group
          );
        } else {
          this.state.experience.locations[locationIndex].hotspots[index] =
            e.data.data.hotspot;

          info = document.getElementById(`${hotspotId}`);

          title = document.getElementById(`title-hotspot_${hotspotId}`);
          info = document.getElementById(`info-hotspot_${hotspotId}`);
          title.setAttribute("value", e.data.data.hotspot.title);
          if (e.data.data.info)
            info.setAttribute("value", e.data.data.hotspot.info);

          info = document.getElementById(`openbtn-hotspot_${hotspotId}`);
          info.setAttribute("src", `#img-${e.data.data.hotspot.icon_id}`);

          if (e.data.data.image_id) {
            info = document.getElementById(`image-card-hotspot_${hotspotId}`);
            info.setAttribute("src", `#img-${e.data.data.hotspot.image_id}`);
          }
        }
        break;
      case "delete-hotspot":
        locationId = e.data.data.locationID;
        hotspotId = e.data.data.hotspotID;
        location = this.state.experience.locations.filter(
          (location) => location.id_tour_creator == locationId
        )[0];
        locationIndex = this.state.experience.locations.indexOf(location);
        hotspot = location.hotspots.filter(
          (hotspot) => hotspot.id_tour_creator == hotspotId
        )[0];
        index = location.hotspots.indexOf(hotspot);
        if (locationIndex > -1) {
          this.state.experience.locations[locationIndex].hotspots.splice(
            index,
            1
          );
        }

        group = document.querySelector(`#hotspots-${locationId}`);
        trivias = location.trivias.map((hotspotTrivia, index) => (
          <TriviaHotspot
            id={`trivia_${hotspotTrivia.id_tour_creator}`}
            key={`trivia_${hotspotTrivia.id_tour_creator}`}
            triviaHotspot={hotspotTrivia}
            index={index}
          />
        ));
        ReactDOM.render(
          this.state.experience.locations[locationIndex].hotspots
            .map((hotspot, index) =>
              hotspot.video_url !== null ? (
                <VideoHotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                />
              ) : (
                <Hotspot
                  id={`hotspot_${hotspot.id_tour_creator}`}
                  key={`hotspot_${hotspot.id_tour_creator}`}
                  hotspot={hotspot}
                  index={index}
                  colors={this.state.experience.colors}
                />
              )
            )
            .concat(trivias),
          group
        );
        break;
      case "update-color":
        this.state.experience.colors = e.data.data;
        const changeColor = new CustomEvent("update-color", {
          detail: e.data.data,
        });
        window.dispatchEvent(changeColor);
        break;
      default:
        break;
    }
  }
  appHeight() {
    const doc = document.documentElement;
    doc.style.setProperty("--src-height", `${window.innerHeight}px`);
  }

  componentDidMount() {
    window.addEventListener("resize", this.appHeight);
    this.appHeight();

    window.sessionStorage.removeItem("lastLocationID");
    window.localStorage.removeItem("visitedlocations");
    // In case you have a selectedHotspot from another experience
    window.localStorage.removeItem("selectedHotspot");

    const {
      match: { params },
    } = this.props;
    this.setState({ experienceSlug: params.slug.split("&")[0] });
    this.setState({
      token: localStorage.getItem("token"),
      refresh_token: localStorage.getItem("refresh_token"),
    });
    ReactGA.pageview(`/tour/${this.props.match.params.slug}`);

    this.setState({ api, management });
    if (
      this.state.mode === 1 &&
      !userLoggedIn(
        localStorage.getItem("token"),
        localStorage.getItem("refresh_token")
      )
    ) {
      location.href = `/login?u=${location.pathname}`;
    }

    if (true) {
      // TODO debemos hacer que rig previe mode sirva sin hacer peticiones a BE
      if (this.state.mode !== 1)
        api
          .getExperienceBySlug(
            this.state.experienceSlug || params.slug.split("&")[0]
          )
          .then((experience) => {
            // In each hotspots of each location in experience, delete the character ";" at info, info_es and info_en

            experience.locations.forEach((location) => {
              location.hotspots.forEach((hotspot) => {
                if (hotspot.info) hotspot.info = hotspot.info.replace(/;/g, "");
                if (hotspot.info_es)
                  hotspot.info_es = hotspot.info_es.replace(/;/g, "");
                if (hotspot.info_en)
                  hotspot.info_en = hotspot.info_en.replace(/;/g, "");
              });
            });

            experience.assets = experience.assets[0];
            if (experience.colors.length == 0) {
              experience.colors = [
                {
                  color: {
                    primary: "#202945",
                    secondary: "#ffffff",
                  },
                },
                {
                  color: {
                    primary: "#00f0a1",
                    secondary: "#202945",
                  },
                },
              ];
            }
            if (experience.transition_sound_id)
              audioManager.transitionSound = experience.assets.sound.filter(
                (audio) => audio.id == experience.transition_sound_id
              )[0].src;

            this.calculateIfLiveSession(experience);

            ReactGA.event({
              category: "tour-results",
              action: experience.slug + "-visit",
            });

            experience.locations.sort((a, b) => {
              return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
            });

            let currentLocation = experience.locations[0];
            if (currentLocation)
              window.localStorage.setItem(
                "locationID",
                currentLocation.id_tour_creator
              );

            this.setState({ experience }, () => {});
            management.experience = experience;
            if (currentLocation)
              window.localStorage.setItem(
                "firstLocationID",
                currentLocation.id_tour_creator
              );
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
      else {
        management
          .getUserWithToken()
          .then((response) => {
            let user = response.user_info;
            api.sessionUser = new User(
              user.id,
              user.email,
              user.first_name,
              user.last_name,
              user.groups,
              user.username
            );
            this.setState({ user });
            api
              .getExperienceBySlug(this.state.experienceSlug)
              .then((experience) => {
                ReactGA.event({
                  category: "tour-results",
                  action: experience.slug + "-visit",
                });

                experience.locations.forEach((location) => {
                  location.hotspots.forEach((hotspot) => {
                    if (hotspot.info)
                      hotspot.info = hotspot.info.replace(/;/g, "");
                    if (hotspot.info_es)
                      hotspot.info_es = hotspot.info_es.replace(/;/g, "");
                    if (hotspot.info_en)
                      hotspot.info_en = hotspot.info_en.replace(/;/g, "");
                  });
                });

                experience.assets = experience.assets[0];
                if (experience.colors.length == 0) {
                  experience.colors = [
                    {
                      color: {
                        primary: "#202945",
                        secondary: "#ffffff",
                      },
                    },
                    {
                      color: {
                        primary: "#00f0a1",
                        secondary: "#202945",
                      },
                    },
                  ];
                }

                if (experience.transition_sound_id)
                  audioManager.transitionSound = experience.assets.sound.filter(
                    (audio) => audio.id == experience.transition_sound_id
                  )[0].src;

                experience.locations.sort((a, b) => {
                  return a.order > b.order;
                });

                let currentLocation = experience.locations[0];
                if (currentLocation)
                  window.localStorage.setItem(
                    "locationID",
                    currentLocation.id_tour_creator
                  );

                api.sessionUser.currentExperience = experience;
                this.setState({ experience }, () => {
                  if (this.videocallSession) {
                    this.jitsiDefaultParameters.roomName = experience.slug;
                    this.jitsiDefaultParameters.userInfo.displayName =
                      api.sessionUser.getFullName();
                    if (api.sessionUser.isGuide()) {
                      this.jitsiDefaultParameters.userInfo.displayName += ` ${api.sessionUser.id}`;
                      this.jitsiDefaultParameters.interfaceConfigOverwrite.TOOLBAR_BUTTONS =
                        ["camera", "mute-everyone"];
                    }
                    this.setState({
                      jitsiParameters: this.jitsiDefaultParameters,
                    });
                  }
                });
                management.experience = experience;
                window.localStorage.setItem(
                  "firstLocationID",
                  currentLocation.id_tour_creator
                );

                api.experienceCommunicationConnect(
                  () => {
                    //WS Connection established
                  },
                  (message) => {
                    let parsed = JSON.parse(message.data);
                    if (api.sessionUser.isGuide()) {
                      if (parsed.action === "raise_hand") {
                        window.dispatchEvent(
                          new CustomEvent("receivedraisehand", {
                            detail: parsed,
                          })
                        );
                      } else if (parsed.action === "reaction") {
                        window.dispatchEvent(
                          new CustomEvent("receivedreaction", {
                            detail: parsed,
                          })
                        );
                      }
                    } else {
                      if (parsed.action === "highlight_hotspot") {
                        window.dispatchEvent(
                          new CustomEvent("hightlighthotspot", {
                            detail: parsed,
                          })
                        );
                        if (settings.autoAction) {
                          setTimeout(
                            () => openHotspot(parsed.hotspot_html_id),
                            500
                          );
                        }
                      } else if (parsed.action === "switch_location") {
                        window.dispatchEvent(
                          new CustomEvent("changelocation", { detail: parsed })
                        );
                        // If autoAction is on then teleport to guide
                        if (settings.autoAction) {
                          // Get locations with the waypoint that the Guide clicked
                          setTimeout(() => {
                            window.dispatchEvent(new Event("teleport"));
                          }, 1200);
                        }
                      } else if (parsed.action === "raise_hand") {
                        window.dispatchEvent(
                          new CustomEvent("receivedraisehand", {
                            detail: parsed,
                          })
                        );
                      } else if (parsed.action === "reaction") {
                        window.dispatchEvent(
                          new CustomEvent("receivedreaction", {
                            detail: parsed,
                          })
                        );
                      } else if (parsed.action === "survey") {
                        window.dispatchEvent(
                          new CustomEvent("receivedsurvey", { detail: parsed })
                        );
                      }
                    }
                  },
                  (error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                  },
                  experience.id,
                  user.id
                );
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
              });
          })
          .catch(() => {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("refresh");
            this.setState({ token: null, refresh_token: null });
          });
      }
    }

    if (true) {
      // eslint-disable-next-line no-console
      console.warn("DEV ENV");
    }

    window.addEventListener("waypoint-position-update", (e) => {
      let idData = e.detail.id;
      let locationId = window.localStorage.getItem("locationID");
      let location = this.state.experience.locations.filter(
        (location) => location.id_tour_creator == locationId
      )[0];
      let locationIndex = this.state.experience.locations.indexOf(location);
      let waypoint = location.waypoints.filter(
        (waypoint) => waypoint.id_tour_creator == idData
      )[0];
      let index = location.waypoints.indexOf(waypoint);
      let newPosition = cartesianToPolar(
        e.detail.position.x,
        e.detail.position.y,
        e.detail.position.z
      );
      newPosition.distance =
        this.state.experience.locations[locationIndex].waypoints[
          index
        ].position.distance;
      this.state.experience.locations[locationIndex].waypoints[index].position =
        newPosition;
      window.parent.postMessage(
        {
          action: "waypoint-position-update",
          data: { id: idData, position: newPosition },
        },
        "*"
      );
    });

    window.addEventListener("hotspot-position-update", (e) => {
      let idData = e.detail.id;
      let locationId = window.localStorage.getItem("locationID");
      let location = this.state.experience.locations.filter(
        (location) => location.id_tour_creator == locationId
      )[0];
      let locationIndex = this.state.experience.locations.indexOf(location);
      let hotspot = location.hotspots.filter(
        (hotspot) => hotspot.id_tour_creator == idData
      )[0];
      let index = location.hotspots.indexOf(hotspot);
      let newPosition = cartesianToPolar(
        e.detail.position.x,
        e.detail.position.y,
        e.detail.position.z
      );
      newPosition.distance =
        this.state.experience.locations[locationIndex].hotspots[
          index
        ].position.distance;
      this.state.experience.locations[locationIndex].hotspots[index].position =
        newPosition;
      window.parent.postMessage(
        {
          action: "hotspot-position-update",
          data: { id: idData, position: newPosition },
        },
        "*"
      );
    });

    window.addEventListener("trivia-position-update", (e) => {
      let idData = e.detail.id;
      let locationId = window.localStorage.getItem("locationID");
      let location = this.state.experience.locations.filter(
        (location) => location.id_tour_creator == locationId
      )[0];
      let locationIndex = this.state.experience.locations.indexOf(location);
      let trivia = location.trivias.filter(
        (trivia) => trivia.id_tour_creator == idData
      )[0];
      let index = location.trivias.indexOf(trivia);
      let newPosition = cartesianToPolar(
        e.detail.position.x,
        e.detail.position.y,
        e.detail.position.z
      );
      newPosition.distance =
        this.state.experience.locations[locationIndex].trivias[
          index
        ].position.distance;
      this.state.experience.locations[locationIndex].trivias[index].position =
        newPosition;
      window.parent.postMessage(
        {
          action: "trivia-position-update",
          data: { id: idData, position: newPosition },
        },
        "*"
      );
    });

    document.addEventListener("checkAudioLocation", () => {
      let locationIDAudio = window.localStorage.getItem("locationID");
      let checkAudio = this.state.experience.locations.filter(
        (location) => location.id_tour_creator == locationIDAudio
      )[0].audio_id;
      let controlAudio = document.getElementById("controlAudio");
      let mode =
        new URLSearchParams(location.search).get("j") === null
          ? 0
          : new URLSearchParams(location.search).get("j") == 1
          ? 1
          : new URLSearchParams(location.search).get("j");
      let audioLocation = document.querySelector(`#sound-${checkAudio}`);

      if (checkAudio && mode == 0) {
        audioManager.audioLocation = audioLocation.src;
        controlAudio.style.visibility = "visible";
      } else {
        controlAudio.style.visibility = "hidden";
      }
    });
  }

  render() {
    if (this.state.experience === null) {
      //TODO give a proper HTML error message
      return (
        <LoadingScreen backgroundStyles={{ backgroundColor: "#ffffff" }} />
      );
    }

    let portraitModeDisplay = "block";

    if (!mobileAndTabletCheck()) {
      portraitModeDisplay = "none";
    } else if (getOrientation() === "landscape") {
      portraitModeDisplay = "none";
    }

    window.addEventListener("orientationchange", () => {});

    if (this.state.mode == 3) {
      const type = new URLSearchParams(location.search).get("type");
      const src = new URLSearchParams(location.search).get("src");
      const rotation = Number(
        new URLSearchParams(location.search).get("rotation") || 0
      );
      return <RigRotationPreview src={src} type={type} rotation={rotation} />;
    } else
      return (
        <React.Fragment>
          <LoadingScreen
            backgroundStyles={{ backgroundColor: "#ffffffff", zIndex: 1 }}
            loaderStyles={{ borderTop: "16px solid #00F0A1" }}
          ></LoadingScreen>
          <div id="xengine">
            <div id="video-hotspot-display"></div>

            {this.state.assetsLoaded &&
              !this.jitsiLoaded &&
              this.state.jitsiParameters.roomName && (
                <VideoConference
                  jitsiParameters={this.state.jitsiParameters}
                  onLoad={() => {
                    if (true)
                      // eslint-disable-next-line no-console
                      console.log("Jitsi Loaded");
                  }}
                  guideIds={this.state.experience.guides}
                  setPartipantsCount={(partipants) =>
                    (document.getElementById("participantsCounter").innerHTML =
                      Object.keys(partipants).length)
                  }
                />
              )}
            <HotspotModal colors={this.state.experience.colors} />
            <XEngineUI
              liveSession={this.state.liveSession}
              mode={parseInt(this.state.mode)}
              experience={this.state.experience}
              participantCount={0}
            />
            <Scene
              cursor-controller
              cursor="rayOrigin: mouse; fuseTimeout: 0;"
              environment
              raycaster="objects: .clickable"
              loader
              vr-mode-ui={`arEnabled: false; enabled: ${this.state.activeVR};`}
            >
              {/* kev */}
              {/* <a-camera>
                            <a-entity
                                id="cursorARVR"
                                animation__fusing="property: scale; from: 1 1 1; to: 0.1 0.1 0.1; startEvents:fusing;"
                                animation__reset="property: scale; from: 0.1 0.1 0.1; to: 1 1 1; startEvents:mouseleave;">
                            </a-entity>
                        </a-camera> */}

              <a-plane
                lookatcamera
                scale="6 6 6"
                id="hotspotCointainerVR"
                height="0"
                width="0"
                rotation="-180 90 180"
                position="1 1 1"
                material="transparent: true; alphaTest: 0.5;"
              >
                <a-entity
                  text="xOffset: .44 width: 1; color: white; value: ; side: double;"
                  close-vr-hotspot
                  id="VRCloseButton"
                  geometry="primitive: box; width: 0; height: 0; depth: 0"
                  material="color: #13C8BB"
                  height=".1"
                  width=".1"
                >
                  <a-image
                    height=".14"
                    width=".08"
                    rotation="0 0 90"
                    position="0 -0.02 -0.01"
                    scale="0"
                    // class={(spotClickState) ? "not-clickable" : "clickable"}
                    id="VRCloseButtonBG"
                    material={`shader: highlight; color: ${"#452043"}; image: #card; transparent: true; `}
                  ></a-image>

                  <a-image
                    scale="0"
                    id="VRCloseButtonBorder"
                    height=".15"
                    width=".09"
                    //scale={`${0.2 * lines + 0.07} ${length * 1.2 + 0.07} 1`}
                    rotation="0 0 90"
                    position="0 -0.02 -0.02"
                    //class={(spotClickState) ? "not-clickable" : "clickable"}
                    material={`shader: highlight; color: ${"#fff"}; image: #card; transparent: true; alphaTest: 0.5;`}
                  ></a-image>
                  <a-text
                    id="VRCloseButtonText"
                    value={"X"}
                    class="not-clickable"
                    font="../../assets/Eina01-Bold-msdf.json"
                    align="center"
                    font-image="../../assets/Eina01-Bold.png"
                    negate="false"
                    color={"#ffffff"}
                    scale="0"
                    position="0 -.01 0"
                  ></a-text>
                </a-entity>
                <a-entity
                  text="width: 1; color: black; value: ; side: double;"
                  openinfo-vr-hotspot
                  id="VRWatchInfo"
                  geometry="primitive: box; width: 0; height: 0; depth: 0"
                  material="color: #202945"
                  height=".1"
                  width=".1"
                  position="0 0 0.1"
                >
                  <a-image
                    height=".14"
                    width=".08"
                    rotation="0 0 90"
                    position="0 -0.02 -0.01"
                    scale="0"
                    // class={(spotClickState) ? "not-clickable" : "clickable"}
                    id="VRCloseButtonInfoBG"
                    material={`shader: highlight; color: ${"#452043"}; image: #card; transparent: true; `}
                  ></a-image>

                  <a-image
                    scale="0"
                    id="VRCloseButtonInfoBorder"
                    height=".15"
                    width=".09"
                    //scale={`${0.2 * lines + 0.07} ${length * 1.2 + 0.07} 1`}
                    rotation="0 0 90"
                    position="0 -0.02 -0.02"
                    //class={(spotClickState) ? "not-clickable" : "clickable"}
                    material={`shader: highlight; color: ${"#fff"}; image: #card; transparent: true; alphaTest: 0.5;`}
                  ></a-image>
                  <a-text
                    id="btnImageButtonVR"
                    value={"Foto"}
                    class="not-clickable"
                    font="../../assets/Eina01-Bold-msdf.json"
                    align="center"
                    font-image="../../assets/Eina01-Bold.png"
                    negate="false"
                    color={"#ffffff"}
                    scale="0"
                    position="0 -.01 0"
                  ></a-text>
                  <a-text
                    id="btnInfoButtonVR"
                    value={"Info"}
                    class="not-clickable"
                    font="../../assets/Eina01-Bold-msdf.json"
                    align="center"
                    font-image="../../assets/Eina01-Bold.png"
                    negate="false"
                    color={"#ffffff"}
                    scale="0"
                    position="0 -.01 0"
                  ></a-text>
                </a-entity>
              </a-plane>

              {this.state.mode != 2 ? (
                <Asset experience={this.state.experience} />
              ) : (
                <a-assets id="assets" timeout="40000">
                  <img
                    crossOrigin="true"
                    id="card"
                    src={
                      "https://immerso-experience.s3.amazonaws.com/media/experience/images/carddosrios.png"
                    }
                  ></img>
                  {this.state.experience.assets.img.map((img) => (
                    <img
                      onLoad={this.assetLoad}
                      crossOrigin="true"
                      key={`img-${img.id}`}
                      id={`img-${img.id}`}
                      src={img.src}
                    ></img>
                  ))}
                  {this.state.experience.assets.sound.map((sound) => (
                    <audio
                      crossOrigin="true"
                      key={`sound-${sound.id}`}
                      id={`sound-${sound.id}`}
                      src={sound.src}
                      loop={true}
                      preload="metadata"
                    ></audio>
                  ))}
                  {this.state.experience.assets.video.map((video) => (
                    <video
                      key={`video-${video.id}`}
                      id={`video-${video.id}`}
                      loop
                      crossOrigin="anonymous"
                      src={video.src}
                    ></video>
                  ))}
                </a-assets>
              )}

              <Entity id="spots" waypoints>
                {this.state.experience.locations.map((location, index) => {
                  if (
                    this.state.enabledLocations.includes(
                      location.id_tour_creator
                    ) ||
                    this.state.mode == 2 ||
                    index === 0
                  ) {
                    if (index === 0) {
                      return (
                        <Entity
                          key={`key_group_${location.id_tour_creator}`}
                          id={`group-${location.id_tour_creator}`}
                          class="waypoint-group"
                        >
                          {location.waypoints.map((waypoint, index) => {
                            if (waypoint.use_default) {
                              waypoint.scale =
                                this.state.experience.default_waypoint.scale;
                              waypoint.text_scale =
                                this.state.experience.default_waypoint.text_scale;
                              waypoint.text_position =
                                this.state.experience.default_waypoint.text_position;
                              waypoint.text_color =
                                this.state.experience.default_waypoint.text_color;
                            }
                            return (
                              <Waypoint
                                id={`waypoint_${waypoint.id_tour_creator}`}
                                key={`waypoint_${waypoint.id_tour_creator}`}
                                waypoint={waypoint}
                                first={index === 0}
                                colors={this.state.experience.colors}
                              />
                            );
                          })}
                        </Entity>
                      );
                    }
                    return (
                      <Entity
                        key={`key_group_${location.id_tour_creator}`}
                        id={`group-${location.id_tour_creator}`}
                        visible={false}
                        scale="0 0 0"
                        class="waypoint-group"
                      >
                        {location.waypoints.map((waypoint) => {
                          if (waypoint.use_default) {
                            waypoint.scale =
                              this.state.experience.default_waypoint.scale;
                            waypoint.text_scale =
                              this.state.experience.default_waypoint.text_scale;
                            waypoint.text_position =
                              this.state.experience.default_waypoint.text_position;
                            waypoint.text_color =
                              this.state.experience.default_waypoint.text_color;
                          }
                          return (
                            <Waypoint
                              id={`waypoint_${waypoint.id_tour_creator}`}
                              key={`waypoint_${waypoint.id_tour_creator}`}
                              waypoint={waypoint}
                              first={false}
                              colors={this.state.experience.colors}
                            />
                          );
                        })}
                      </Entity>
                    );
                  }
                  return (
                    <Entity
                      key={`key_group_${location.id_tour_creator}`}
                      id={`group-${location.id_tour_creator}`}
                      visible={false}
                      scale="0 0 0"
                      class="waypoint-group"
                    ></Entity>
                  );
                })}
              </Entity>
              <Entity id="hotspots" hotspots>
                {this.state.experience.locations.map((location, index) => {
                  let trivias;
                  if (location.trivias.length > 0) {
                    trivias = location.trivias.map((hotspotTrivia, index) => (
                      <TriviaHotspot
                        id={`trivia_${hotspotTrivia.id_tour_creator}`}
                        key={`trivia_${hotspotTrivia.id_tour_creator}`}
                        triviaHotspot={hotspotTrivia}
                        index={index}
                      />
                    ));
                  }

                  if (
                    this.state.enabledLocations.includes(
                      location.id_tour_creator
                    ) ||
                    this.state.mode == 2 ||
                    index === 0
                  ) {
                    if (index === 0) {
                      return (
                        <Entity
                          key={`key_hotspots_${location.id_tour_creator}`}
                          id={`hotspots-${location.id_tour_creator}`}
                        >
                          {location.hotspots.map((hotspot, index) =>
                            hotspot.video_url !== null ? (
                              <VideoHotspot
                                id={`hotspot_${hotspot.id_tour_creator}`}
                                key={`hotspot_${hotspot.id_tour_creator}`}
                                hotspot={hotspot}
                                index={index}
                              />
                            ) : (
                              <Hotspot
                                id={`hotspot_${hotspot.id_tour_creator}`}
                                key={`hotspot_${hotspot.id_tour_creator}`}
                                hotspot={hotspot}
                                index={index}
                                colors={this.state.experience.colors}
                              />
                            )
                          )}
                          {trivias}
                        </Entity>
                      );
                    }

                    return (
                      <Entity
                        key={`key_hotspots_${location.id_tour_creator}`}
                        id={`hotspots-${location.id_tour_creator}`}
                        visible={false}
                        scale="0 0 0"
                      >
                        {location.hotspots.map((hotspot, index) =>
                          hotspot.video_url !== null ? (
                            <VideoHotspot
                              id={`hotspot_${hotspot.id_tour_creator}`}
                              key={`hotspot_${hotspot.id_tour_creator}`}
                              hotspot={hotspot}
                              index={index}
                            />
                          ) : (
                            <Hotspot
                              id={`hotspot_${hotspot.id_tour_creator}`}
                              key={`hotspot_${hotspot.id_tour_creator}`}
                              hotspot={hotspot}
                              index={index}
                              colors={this.state.experience.colors}
                            />
                          )
                        )}
                        {trivias}
                      </Entity>
                    );
                  }
                  return (
                    <Entity
                      key={`key_hotspots_${location.id_tour_creator}`}
                      id={`hotspots-${location.id_tour_creator}`}
                      visible={false}
                      scale="0 0 0"
                    ></Entity>
                  );
                })}
              </Entity>
              {/* kev */}
              <Entity
                id="rig"
                rotation={`0 ${
                  this.state.experience?.locations[0]?.default_rotation?.y || 0
                } 0`}
                animation__rotate="property: rotation.y; dir: forward; dur: 1000; easing: easeInSine; enabled: false;"
                animation__idle_y="property: rotation.y; dir: forward; dur: 700000; easing: linear; enabled: true; loop: true"
              >
                <Entity
                  primitive="a-camera"
                  wasd-controls="false"
                  id="cam"
                  position="0 1.6 0"
                  animation__move="property: camera.zoom; dir: forward; dur: 1000; easing: easeInSine; enabled: false;"
                  look-controls="magicWindowTrackingEnabled: "
                >
                  <a-entity
                    //TODO change to circle
                    material="color: #28e89d; opacity: 0.8; shader: flat"
                    id="cursorARVR"
                    animation__fusing="property: scale; from: 1 1 1; to: 0.1 0.1 0.1; startEvents:fusing;"
                    animation__reset="property: scale; from: 0.1 0.1 0.1; to: 1 1 1; startEvents:mouseleave;"
                  ></a-entity>
                  {/* <a-entity>
                                {this.state.experience.sponsors.sort((v1, v2) => v1.order - v2.order).map((sponsors) => {
                                    console.log(this.state.experience)
                                    return <a-image key={sponsors.sponsor.name} position="-1 0.5 -3" fit-texture src={this.state.experience.banner} material="transparent: false; alphaTest: 0.5"></a-image>
                                })}
                                </a-entity> */}
                  {/* <a-box lookatcamera position="-1 0.5 -3" rotation="0 45 0" color="#4CC3D9"></a-box> */}
                  {/* static image */}
                  {/* <a-image position="-4 -2 -3" fit-texture src='/img/haha.png' material="transparent: false; alphaTest: 0.5"></a-image> */}
                </Entity>
              </Entity>

              <Entity
                primitive="a-sky"
                id="skybox"
                src={`#img-${this.state.experience?.locations[0]?.bg_id || ""}`}
                scale="-20 20 20"
              />

              {this.state.experience?.assets?.video?.length > 0 ? (
                <a-videosphere
                  class="clickable"
                  id="videosphere"
                  video
                  scale="0 0 0"
                  src={`#video-${this.state.experience.assets.video[0].id}`}
                ></a-videosphere>
              ) : (
                <div />
              )}
            </Scene>
          </div>
        </React.Fragment>
      );
  }
}

XEngine.propTypes = {
  match: PropTypes.object.isRequired,
};

export default XEngine;
