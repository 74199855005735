import React from "react";
import PropTypes from "prop-types";

class Asset extends React.Component {
    constructor(props){
        super(props);

        this.experience = props.experience;
        this.assets = {img: [], sound: [], video: []};
        this.currentLocation;
        this.getCurrentLocation();
        this.imgIDs = [];
        this.videoIDs = [];
        this.soundIDs = [];
        this.enabledLocations = [];
        this.backgroundImgs = [];
        this.getBackgroundImgs();

        //assets needed but not asked by locations
        let imgNeeded = ["video-icon"];
        this.experience.assets.img.map((img) => {
            if(imgNeeded.includes(img.id)){
                this.assets.img.push(img);
            }
        });
        if(this.experience.assets.video.length > 0){
            this.assets.video.push(this.experience.assets.video[0]);
        }

        this.assetsTransition = this.assetsTransition.bind(this);
        document.addEventListener("assetstransition", this.assetsTransition);
        this.addAssetsFromLocation(this.currentLocation);
        this.assetLoad = this.assetLoad.bind(this);

        window.sessionStorage.setItem("loadedBackgrounds", []);
        window.sessionStorage.setItem("lastLocationID", undefined);
    }

    getBackgroundImgs(){
        this.experience.locations.map( (location) => {
            if(location.bg_type=="img"){
                this.backgroundImgs.push(location.bg_id);
            }
        });
    }

    assetsTransition(){
        this.getCurrentLocation();
        this.addAssetsFromLocation(this.currentLocation);
        this.loadNextLocations();
    }

    getCurrentLocation(){
        let currentLocationID = window.sessionStorage.getItem("lastLocationID");
        if(currentLocationID && currentLocationID != "null"){
            this.currentLocation = this.experience.locations.filter(location => location.id_tour_creator == currentLocationID)[0];
        }else{
            this.currentLocation = this.experience.locations[0];
        }
    }

    addAssetsFromLocation(location){
        if(!this.enabledLocations.includes(location.id_tour_creator)){
            //background assets
            if(location.bg_type == "img"){
                this.imgIDs.push(location.bg_id);
                this.backgroundImgs.push(location.bg_id);
            }else{
                this.videoIDs.push(location.video_id);
            }
            this.soundIDs.push(location.sound_id);
            this.soundIDs.push(location.audio_id);

            //hotspots assets
            location.hotspots.map( (hotspot) => {
                this.imgIDs.push(hotspot.image_id);
                this.imgIDs.push(hotspot.icon_id);
                this.soundIDs.push(hotspot.sound_id);
                this.videoIDs.push(hotspot.video_id);    
            });
            
            this.assets.img.push.apply(this.assets.img, this.experience.assets.img.filter(img => this.imgIDs.includes(img.id) && !this.assets.img.includes(img)));
            this.assets.video.push.apply(this.assets.video, this.experience.assets.video.filter(video => this.videoIDs.includes(video.id) && !this.assets.video.includes(video)));
            this.assets.sound.push.apply(this.assets.sound, this.experience.assets.sound.filter(sound => this.soundIDs.includes(sound.id) && !this.assets.sound.includes(sound)));

            this.enabledLocations.push(location.id_tour_creator);
        }
    }

    loadNextLocations(){
        let nextLocations = [];
        this.currentLocation.waypoints.map( (waypoint) => {
            nextLocations.push(waypoint.to);
        });
        this.experience.locations.map(
            (location) => {
                if(location.order == this.currentLocation.order + 1) {
                    if(!nextLocations.includes(location.id_tour_creator))nextLocations.push(location.id_tour_creator);
                }
            });

        nextLocations = this.experience.locations.filter(location => nextLocations.includes(location.id_tour_creator) );

        nextLocations.map((location) =>{
            this.addAssetsFromLocation(location);
            this.setState({assets: this.assets});
            const enableHotspotsEvent = new CustomEvent("enablelocation", {detail: this.enabledLocations});
            document.dispatchEvent(enableHotspotsEvent);
        });
    }

    assetLoad(evt){
        let id = evt.target.id.split("-").slice(1).join("-");
        let loadedBackgrounds =  window.sessionStorage.getItem("loadedBackgrounds");
        if(!loadedBackgrounds){
            loadedBackgrounds = [];
        }
        if(typeof(loadedBackgrounds) === "string"){
            loadedBackgrounds = loadedBackgrounds.split(",");
        }

        if(!loadedBackgrounds.includes(id) && this.backgroundImgs.includes(id)){
            loadedBackgrounds.push(id);
            window.sessionStorage.setItem("loadedBackgrounds", loadedBackgrounds);
            try {
                const waitingTransitionEvent = new CustomEvent("transition-"+id, {detail: id});
                document.dispatchEvent(waitingTransitionEvent);
            }catch(error){
                // eslint-disable-next-line no-console
                console.log(error);
            }
        }         
    }

    componentDidMount(){
        this.loadNextLocations();
    }
    
    render(){
        return <a-assets id="assets" timeout="40000">
            <img crossOrigin="true" id="card" src={"https://immerso-experience.s3.amazonaws.com/media/experience/images/carddosrios.png"}></img>
            {this.assets.img.map((img) => <img onLoad={this.assetLoad} crossOrigin="true" key={`img-${img.id}`} id={`img-${img.id}`} src={img.src}></img>)}
            {this.assets.sound.map((sound) => <audio crossOrigin="true" key={`sound-${sound.id}`} id={`sound-${sound.id}`} src={sound.src} loop={true} preload="metadata"></audio>)}
            {this.assets.video.map((video) => <video key={`video-${video.id}`} id={`video-${video.id}`} loop crossOrigin="anonymous" src={video.src}></video>)}
        </a-assets>;
    }
}

Asset.propTypes = {
    experience: PropTypes.object.isRequired,
};

export default Asset;
