import React from "react";
import { default as $t } from "../../../i18nProvider/translate";
import PropTypes from "prop-types";

const ExperienceCard = (props) => {   
    const handleClick = () => {
        window.location.href = `/${props.experience.slug}`;
    };
    const state = { ...props.experience };

    return (
        <div onClick={state.comingSoon ? null : handleClick} className={
            state.comingSoon ? "card experienceCard not-clickable comming-soon" : "card experienceCard"
        }>
            <img className='cardImage' alt={state.location} src={state.thumbnail} />


            <p className="cardTitle">
                {state.name}
            </p>
            <p className="cardLocation">{state.location}</p>
            <button className={state.comingSoon?"comming-soon":"available"} ><p>{state.comingSoon?$t("soon"):$t("card-call-to-action")}</p></button>
            {/* <div className="labelsCardContainer">
                {labels}
            </div> */}
        </div>
    );
};

ExperienceCard.propTypes = {
    experience: PropTypes.object.isRequired,
};

export default ExperienceCard;
