/**
 * @fileoverview
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */
import { startMoveToLocation } from "../../managers/waypoints/waypoints-manager";
import { interacionManager } from "../../managers/interaction/interaction-manager";
import { mobileAndTabletCheck } from "../../helpers";

AFRAME.registerComponent("cursClick", {
  schema: {
    linkto: { type: "string", default: "" },
    position: { type: "string", default: "" },
    mode: { type: "number", default: 0 },
  },
  init: function () {
    let data = this.data;
    const spotClickable = new Event("spotClickable");
    if (data.mode != 2) {
      this.el.addEventListener("click", function (evt) {
        if (
          !mobileAndTabletCheck() ||
          !interacionManager.wasLastInteractionDrag()
        ) {
          window.dispatchEvent(spotClickable);
          startMoveToLocation(data.linkto);
        }
      });
    }
  },
});
