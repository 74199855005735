import React from "react";
import { Entity, Scene } from "aframe-react";
import { directionToVector } from "../../../helpers";
import PropTypes from "prop-types";

const VideoHotspot = (props) => {
  const { hotspot, id } = props;
  const initialPosition = directionToVector(
    hotspot.position.origin,
    hotspot.position.x_angle,
    hotspot.position.y_angle,
    hotspot.position.distance + 50
  );
  const btnPosition = directionToVector(
    hotspot.position.origin,
    hotspot.position.x_angle,
    hotspot.position.y_angle,
    hotspot.position.distance
  );
  const highlightPosition = directionToVector(
    hotspot.position.origin,
    hotspot.position.x_angle,
    hotspot.position.y_angle,
    hotspot.position.distance - 0.5
  );
  const modeParam = new URLSearchParams(location.search).get("j");
  const mode = modeParam === null ? 0 : Number(modeParam);

  return (
    <Entity class="videoHotSpot-container">
      {/* Hotspot btn component */}
      <Entity
        hotspot={`hotspot:${JSON.stringify(
          hotspot
        )};origin:${initialPosition};mode:${mode}`}
        position={btnPosition}
        lookatcamera
        move-video-hotspot={`mode: ${mode}; distance: ${hotspot.position.distance}`}
        id={id}
      ></Entity>

      <Entity
        position={btnPosition}
        lookatcamera
        scale="1.5 1.5 1.5"
        id={`icon-${id}`}
        interactive-object
        animation__scale="property: scale; dir: alternate; to: 1.6 1.6 1.6; easing: easeInOutQuad; loop: true; dur: 1000; pauseEvents: mouseenter; resumeEvents: mouseleave"
      >
        <a-image
          id={`openbtn-${id}`}
          fit-texture
          class="clickable"
          src={`#img-${hotspot.icon_id || "video-icon"}`}
          material="transparent: false; alphaTest: 0.5"
        ></a-image>
      </Entity>

      {/* Hotspot highlight component */}
      <Entity
        position={highlightPosition}
        lookatcamera
        id={`highlight-${id}`}
        scale="0 0 0"
      >
        <a-image
          fit-texture
          scale="1.5 1.5 1.5"
          material={
            "shader: highlight; color: #02F1A0; opacity: 0.6; image: #img-video-icon"
          }
        ></a-image>
      </Entity>
    </Entity>
  );
};

VideoHotspot.propTypes = {
  hotspot: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default VideoHotspot;
