import React from "react";
import Modal from "react-modal";
import XEngine from "./components/pages/xengine/xengine";
import Explore from "./components/pages/explore/explore";
import Login from "./components/pages/login/login";
import Register from "./components/pages/register/register";
import Contact from "./components/pages/contact/contact";
import ExperiencePage from "./components/pages/experience-page/experience-page";
import PlatformNavbar from "./components/platform/platform-navbar/platform-navbar";
import ExperienceFooter from "./components/platform/experience-footer/experience-footer";
import TipsPage from "./components/pages/tips-page/tips-page";
import { I18nPropvider, LOCALES } from "./i18nProvider";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ReactGA from "react-ga";
import { deleteCookie } from "./helpers";
import { getUser } from "./managers/api/api-manager";
import PageContainer from "./components/pages/page-container";


if (true) {
    const trackingId = "UA-178407168-1"; // Replace with your Google Analytics tracking ID
    ReactGA.initialize(trackingId, {
        debug: false
    });
    ReactGA.set({forceSSL: true});
}

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            locale: localStorage.getItem("locale")
        };
        // We need to remove this cookie for survey monkey
        deleteCookie("smcx_0_last_shown_at");
    }

    componentDidMount() {
        let lenguajeFromQuery =  new URLSearchParams(window.location.search).get("l");
        Modal.setAppElement("#root");

        // Obtain locale from local storage
        let saved_locale = this.state.locale;

        // If not found then we get it from navigator API
        if (!saved_locale) {
            saved_locale = navigator.language;
        }
        if(lenguajeFromQuery){
            saved_locale = lenguajeFromQuery;
        }
        if (saved_locale.includes("en")) {
            saved_locale = LOCALES.ENGLISH;
        } else if (saved_locale.includes("es")) {
            saved_locale = LOCALES.SPANISH;
        } else {
            saved_locale = LOCALES.ENGLISH;
        }

        // Save it back to local storage
        localStorage.setItem("locale", saved_locale);
        this.setState({
            locale: saved_locale
        });

        let user = getUser();
        if(user)
            ReactGA.set({userId: user.username});
    }

    render() {
        if (!this.state.locale) {
            return <div />;
        }

        return (
            <I18nPropvider locale={this.state.locale}>
                <Router>
                
                    <div className="platform">  
                        <Switch>
                            <Route path="/tour/:slug" render={(matchProps) =>
                                <XEngine
                                    {...matchProps}
                                    {...this.props}
                                    handleMatch={this.handleMatch}
                                />
                            }>
                            </Route>
                            <Route path="/:slug" render={(matchProps) =>
                                <PageContainer>
                                    <ExperiencePage
                                        {...matchProps}
                                        {...this.props}
                                        handleMatch={this.handleMatch}
                                    />
                                </PageContainer>
                            }>
                            </Route>
                            <Route exact path="/">
                                <PageContainer>
                                    <Explore />
                                </PageContainer>
                            </Route>
                            <Route path="/login">
                                <PageContainer>
                                    <Login />
                                </PageContainer>
                            </Route>
                            <Route path="/register">
                                <PageContainer>
                                    <Register />
                                </PageContainer>
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </I18nPropvider>
        );
    }
}
