import React from "react";
import ProfileImage from "../profile-image/profile-image";
import { LogoutButton } from "../logout-btn/logout-btn";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { userLoggedIn } from "../../../managers/api/api-manager";
import { default as $t } from "../../../i18nProvider/translate";

const PlatformNavbar = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/");
  };

  return (
    <nav
      id="platformNavbar"
      className="platformNavbar navbar navbar-expand-nv navbar-dark"
    >
      <div className="navbarContent">
        <img
          className="logo h-5 sm:h-7 pr-2 mb-2"
          onClick={handleClick}
          src="assets/img/Logo-vertical-Blanco.png"
          alt="Logo Imerso"
        />

        <div className="collapse navbar-collapse nav-container" id="navbarNav">
          <ul
            className="navbar-nav w-50 nav-fill mr-auto"
            style={{ marginLeft: "50%" }}
          >
            <li className="nav-item my-1">
              <a href="https://immersotours.com" className="textbtn">
                {$t("home")}
              </a>
            </li>
            <li className="nav-item my-1">
              <NavLink
                exact
                to="/"
                className="textbtn"
                activeClassName={"selected"}
              >
                {$t("exploration")}
              </NavLink>
            </li>
            <li className="nav-item my-1">
              <a href="https://immerso.io" className="textbtn">
                {$t("clients")}
              </a>
            </li>
            {/* {userLoggedIn(localStorage.getItem("token"), localStorage.getItem("refresh_token")) ? 
                            <li className="nav-item my-1">
                                <LogoutButton className='textbtn' activeClassName={"selected"} />
                            </li>
                            : 
                            <li className="nav-item my-1">
                                <ProfileImage />
                            </li>
                        } */}
          </ul>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
};

export default PlatformNavbar;
