import React from "react";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { default as $t } from "../../../i18nProvider/translate";

const ExperienceFooter = () => {
    let language = localStorage.getItem("locale");

    let verbose_languages = {
        "es": "Español",
        "en": "English ",
    };

    const changeLanguage = (lan) => {
    // Save new language
        localStorage.setItem("locale", lan);
        // Refresh page
        location.reload();
    };

    const history = useHistory();
    const handleClick = () => {
        history.push("/");
    };

    return (
        <footer id="experienceFooter" className='experienceFooter'>
            <div className="footerContent row-auto row-xl-auto">
                <div className="footer-text">
                    <img className='logo' onClick={handleClick} src="assets/img/Logo-vertical-Blanco.png" alt="Logo Imerso" />
                    <div className="copyright">
                        Copyright© 2021 Immerso Tours
                    </div>
                </div>
                <div className="row-container">
                    <div id="language-dropdown">
                        <div className="btn-group dropup">
                            <button data-tip data-for='SelectLanguage' id="change-language-btn" type="button" className="btn btn-secondary dropdown-toggle"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {verbose_languages[language]}
                            </button>
                            <div className="dropdown-menu">
                                {
                                    Object.entries(verbose_languages).map(([key, value]) =>
                                        <a className="dropdown-item" href="#" onClick={(e) => {
                                            e.preventDefault();
                                            changeLanguage(key);
                                        }} key={key}>{value}

                                            <img className= "flags"  src= {`../../assets/img/${key}.png`} ></img>
                                        </a>
                                    )
                                }
                            </div>
                        </div>
                        <ReactTooltip id='SelectLanguage' type='warning' border={true} borderColor='#00F0A1' backgroundColor='#202945' effect='solid' place='top'> <span>{$t("select-language")}</span> </ReactTooltip>
                    </div>
                    <div className="icons d-nv-block">
                        <a href="https://www.facebook.com/ImmersoTours" style={{ color: "#00F0A1" }} target="_blank" rel="noreferrer">
                            <i className={"fab fa-facebook-f"}></i>
                        </a>
                        <a href="https://www.instagram.com/immersotours/" style={{ color: "#00F0A1", marginLeft: "15px" }}
                            target="_blank" rel="noreferrer">
                            <i className={"fab fa-instagram"}></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UC8EY77km7qXLgOPFhZUWVug" style={{ color: "#00F0A1", marginLeft: "15px" }} target="_blank" rel="noreferrer">
                            <i className={"fab fa-youtube"}></i>
                        </a>
                    </div>
                </div>

            </div>

        </footer>
    );
};

export default ExperienceFooter;
