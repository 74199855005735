import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { mobileAndTabletCheck } from "../../../helpers";


const HotspotModal = (props) => {
    const [hotspot, setHotspot]= useState({
        title: "",
        description: "",
        srrc: ""
    });
    const [open, setOpen]= useState(false);
    const [orientation, setOrientation]= useState(window.innerHeight > window.innerWidth ? "vertical" : "horizontal");

    const color1 = props.colors?.filter(color => color.order == 0)[0];
    const color2 = props.colors?.filter(color => color.order == 1)[0];
    const [colors, setColors] = useState({
        "primary_light": color1?.color?.primary || "#202945", 
        "primary_dark": color1?.color?.secondary || "#ffffff", 
        "secondary_light": color2?.color?.primary || "#00f0a1", 
        "secondary_dark": color2?.color?.secondary || "#202945" });

    const close = () => {
        window.sessionStorage.setItem("hotspotOpen", false);
        setOpen(false);
        setTimeout(() => setHotspot({...hotspot, src:"", info:"", title:""}), 500);
    };

    useEffect(() => {
        window.addEventListener("hotspotOpened",(evt) => {
            setOpen(true);
            setHotspot(evt.detail.hotspot);
        });
        window.addEventListener("orientationchange", function() {
            let element = document.getElementById("hotspot-modal-image");
            let newOrientation = element.naturalHeight/element.naturalWidth > document.documentElement.clientHeight/document.documentElement.clientWidth ? "vertical" : "horizontal";
            setOrientation(newOrientation);
        }, false);
        window.addEventListener("resize", function() {
            let element = document.getElementById("hotspot-modal-image");
            let newOrientation = element.naturalHeight/element.naturalWidth > document.documentElement.clientHeight/document.documentElement.clientWidth ? "vertical" : "horizontal";
            setOrientation(newOrientation);
        }, false);

    }, []);

    useEffect(() => {
        let element = document.getElementById("hotspot-modal-image");
        if(element.naturalHeight == 0 || element.naturalWidth == 0) {
            element.onload = function() {
                let newOrientation = this.naturalHeight/this.naturalWidth > document.documentElement.clientHeight/document.documentElement.clientWidth ? "vertical" : "horizontal";
                setOrientation(newOrientation);
            };
        }
        else {
            let newOrientation = element.naturalHeight/element.naturalWidth > document.documentElement.clientHeight/document.documentElement.clientWidth ? "vertical" : "horizontal";
            setOrientation(newOrientation);
        }
    }, [hotspot]);

    if(hotspot) return (
        <div className="hotspot-modal-container">

            <div className={open? "hotspot-modal" : "hotspot-modal closed"}>
                <i className="close-btn fas fa-times-circle" style={{color:colors.primary_dark}} onClick={close}></i>
                <div className={`hotspot-content ${orientation}`}>
                    <img id="hotspot-modal-image" src={hotspot.src}>
                    </img>
                    {(hotspot.title != "" || hotspot.info != "") && <div className="hotspot-info">
                        <div className="hotspot-title">{hotspot.title}</div>
                        <div className="hotspot-description">{hotspot.info}</div>
                    </div>}
                </div>
            </div>
            <div className={open? "hotspot-modal-overlay" : "hotspot-modal-overlay closed"} onClick={close}></div>
        </div>
    );
    else return <div></div>;
};

HotspotModal.propTypes = {
    colors: PropTypes.array,
};

HotspotModal.defaultProps = {
    colors: undefined,
};

export default HotspotModal;
