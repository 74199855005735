import { Howl } from "howler";

class AudioManager {
  constructor() {
    if (!AudioManager.instance) {
      this._data = [];
      this.baseVolume = 0.3;
      this.volume = this.baseVolume;
      this.volumeChangeRatio = 0.05;
      this.backgroundSound = null;
      this.transitionSound = null;
      this.notificationSound = null;
      this.reactionSound = null;
      this.paused = false;
      this.audioLocation = null;
      this.audioHotspot = null;
      this.volumeLocationBase = 0;
      this.pausedAudioLocation = true;
    }
    return AudioManager.instance;
  }

  get pausedAudioLocation() {
    return this._pausedAudioLocation;
  }

  set pausedAudioLocation(value) {
    this._pausedAudioLocation = value;
  }

  get backgroundSound() {
    return this._backgroundSound;
  }

  set backgroundSound(src) {
    delete this._backgroundSound;
    this._backgroundSound = new Howl({
      src: [src],
      volume: this.volume,
      loop: true,
    });
  }

  get reactionSound() {
    return this._reactionSound;
  }

  set reactionSound(src) {
    delete this._reactionSound;
    this._reactionSound = new Howl({
      src: [src],
      volume: 0.3,
      loop: false,
    });
  }

  get transitionSound() {
    return this._transitionSound;
  }

  set transitionSound(src) {
    delete this._transitionSound;
    this._transitionSound = new Howl({
      src: [src],
      volume: this.volume,
      loop: false,
    });
  }

  get notificationSound() {
    return this._notificationSound;
  }

  set notificationSound(src) {
    delete this._notificationSound;
    this._notificationSound = new Howl({
      src: [src],
      volume: 0.6,
      loop: false,
    });
  }

  get audioLocation() {
    return this._audioLocation;
  }

  set audioLocation(src) {
    console.log("set audioLocation");
    if (this._audioLocation) {
      console.log("stop audioLocation");
      this._audioLocation.stop();
      this.audioLocation.unload();
      delete this._audioLocation;
    }
    if (this.volume > 0) {
      this.volumeLocationBase = this.volume + 0.05;
    } else {
      this.volumeLocationBase = this.volume;
    }
    this._audioLocation = new Howl({
      src: [src],
      volume: this.volumeLocationBase,
      loop: false,
      onend: function () {
        document.dispatchEvent(new CustomEvent("EndAudioLocation"));
      },
    });
  }

  get audioHotspot() {
    return this._audioHotspot;
  }

  set audioHotspot(src) {
    delete this._audioHotspot;
    this._audioHotspot = new Howl({
      src: [src],
      volume: this.volume,
      loop: false,
      onend: function () {
        document.dispatchEvent(new CustomEvent("EndAudioHotspot"));
      },
    });
  }

  setVolume(value) {
    this.volume = value;
    let backgroundVolume = 0;
    let transitionVolume = 0;
    let audioVolume = 0;
    let hotspotVolume = 0;

    if (this.volume > 0) {
      backgroundVolume = this.volume;
      transitionVolume = this.volume;
      audioVolume = this.volume + 0.05;
      hotspotVolume = this.volume + 0.05;
    }

    this.backgroundSound.volume(backgroundVolume);
    this.transitionSound.volume(transitionVolume);
    if (audioVolume > 1) audioVolume == 1;
    this.audioLocation.volume(audioVolume);
    this.audioTemp = audioVolume;
    if (hotspotVolume > 1) hotspotVolume == 1;
    this.audioHotspot.volume(hotspotVolume);
    // let videosphere = document.getElementById("videosphere");
    // let video = document.querySelector(videosphere.getAttribute("src"));
    // video.volume = this.volume;
  }

  increaseVolume() {
    if (this.volume < 1) {
      let newVolume = this.volume + this.volumeChangeRatio;
      if (newVolume > 1) newVolume = 1;
      this.setVolume(newVolume);
    }
  }

  decreaseVolume() {
    if (this.volume > 0) {
      let newVolume = this.volume - this.volumeChangeRatio;
      if (newVolume < 0) {
        newVolume = 0;
      }

      this.setVolume(newVolume);
    }
  }

  playTransitionSound() {
    this.transitionSound.play();
    this.transitionSound.fade(0, this.volume, 1000);
  }

  playNotificationSound() {
    if (this.notificationSound._src[0] === null) {
      let src = "/sounds/notification.mp3";
      this.notificationSound = src;
    }
    this.notificationSound.play();
  }

  playReactionSound() {
    if (this.reactionSound._src[0] === null) {
      let src = "/sounds/reaction.mp3";
      this.reactionSound = src;
    }
    this.reactionSound.play();
  }

  playBackgroundSound(src) {
    if (this.backgroundSound._src[0] === null) {
      this.backgroundSound = src; // Calls backgroundSound setter
      this.backgroundSound.play();
      this.backgroundSound.fade(0, this.volume, 2000);
    } else if (src !== this.backgroundSound._src) {
      this.backgroundSound.fade(this.volume, 0, 2000);
      this.backgroundSound = src;
      this.backgroundSound.play(); // Calls backgroundSound setter
      this.backgroundSound.fade(0, this.volume, 2000);
    }
    this.resumeBackgroundSound();
  }

  playAudioLocation(src) {
    this.decreaseBackgroundVolume();
    this.pausedAudioLocation = false;
    document.dispatchEvent(new CustomEvent("startAudioLocation"));
    if (this.audioLocation._src[0] === null) {
      this.audioLocation = src; // Calls backgroundSound setter
      this.audioLocation.play();
    } else if (src !== this.audioLocation._src) {
      this.audioLocation = src; // Calls backgroundSound setter
      setTimeout(() => this.audioLocation.play(), 1000);
    }
  }

  playAudioHotspot(src) {
    this.pauseAudioLocation();
    document.dispatchEvent(new CustomEvent("pauseAudioLocation"));

    if (this.audioHotspot._src[0] === null) {
      this.audioHotspot = src; // Calls backgroundSound setter
      this.audioHotspot.play();
    } else if (src !== this.audioHotspot._src) {
      this.audioHotspot.stop();
      this.audioHotspot.unload();
      this.audioHotspot = src; // Calls backgroundSound setter
      this.audioHotspot.play();
    } else {
      this.audioHotspot.stop();
      this.audioHotspot.play();
    }
  }

  stopBackgroundSound() {
    this.backgroundSound.fade(this.volume, 0, 1300);
    this.paused = true;
  }

  resumeBackgroundSound() {
    if (this.paused) {
      this.backgroundSound.play();
      this.backgroundSound.fade(0, this.volume, 2000);
      this.paused = false;
    }
  }

  resumeAudioLocation() {
    this.audioLocation.play();
    this.pausedAudioLocation = false;
  }

  pauseAudioLocation() {
    this.audioLocation.pause();
    this.pausedAudioLocation = true;
  }

  stopAudioHotspot() {
    this.audioHotspot.stop();
    this.audioHotspot = null;
  }

  decreaseBackgroundVolume() {
    this.backgroundSound.fade(this.volume, 0, 2000);
  }

  increaseBackgroundVolume() {
    this.backgroundSound.fade(this.volume / 2, this.volume, 2000);
  }
}

const audioManager = new AudioManager();

export default audioManager;
