import React, { useState } from "react";
import { Entity, Scene } from "aframe-react";
import { directionToVector, mobileAndTabletCheck } from "../../../helpers";
import PropTypes from "prop-types";

const Waypoint = (props) => {
  const { waypoint, id } = props;
  waypoint.position.distance = mobileAndTabletCheck() ? 4 : 5;
  const position = directionToVector(
    waypoint.position.origin,
    waypoint.position.x_angle,
    waypoint.position.y_angle,
    waypoint.position.distance
  );
  const wrapCount = 12;
  const lines = parseInt(waypoint?.text?.length / wrapCount) + 1;
  const length = lines > 1 ? 1 : waypoint.text.length / wrapCount;
  const [spotClickState, setSpotClickState] = useState(false);
  const modeParam = new URLSearchParams(location.search).get("j");
  const mode = modeParam === null ? 0 : Number(modeParam);

  window.addEventListener("spotClickable", () => {
    setSpotClickState(true);

    setTimeout(() => {
      setSpotClickState(false);
    }, 4000);
  });

  return (
    <a-entity class="waypoint-container">
      <Entity
        id={id}
        spot={`linkto:${waypoint.to};position: ${JSON.stringify(
          waypoint.position
        )};mode: ${mode}`}
        lookatcamera
        position={position}
        scale="1.6 1.6 1.6"
        interactive-object
        move-waypoint={mode == 2 ? `mode: ${mode}` : undefined}
        animation="property: scale; dir: alternate; to: 1.7 1.7 1.7; easing: easeInOutQuad; loop: true; dur: 1000; pauseEvents: mouseenter; resumeEvents: mouseleave"
      >
        <a-image
          id={`waypoint-card-${id}`}
          scale={`${0.2 * lines + 0.04} ${length * 1.2 + 0.04} 1`}
          rotation="0 0 90"
          position="0 -0.02 -0.01"
          class={spotClickState ? "not-clickable" : "clickable"}
          material={`shader: highlight; color: ${props.colors[0].color.primary}; image: #card; transparent: true; alphaTest: 0.5;`}
        ></a-image>

        <a-image
          scale={`${0.2 * lines + 0.07} ${length * 1.2 + 0.07} 1`}
          rotation="0 0 90"
          position="0 -0.02 -0.02"
          class={spotClickState ? "not-clickable" : "clickable"}
          material={`shader: highlight; color: ${props.colors[0].color.secondary}; image: #card; transparent: true; alphaTest: 0.5;`}
        ></a-image>
        <a-text
          id={`text-${id}`}
          value={waypoint.text}
          class="not-clickable"
          font="../../assets/Eina01-Bold-msdf.json"
          align="center"
          font-image="../../assets/Eina01-Bold.png"
          negate="false"
          color={props.colors[0].color.secondary}
          scale="0.18 0.18 0.18"
          wrap-count={wrapCount}
          position="0 0 0"
        ></a-text>
      </Entity>
      ;
    </a-entity>
  );
};

Waypoint.propTypes = {
  waypoint: PropTypes.object.isRequired,
  first: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  colors: PropTypes.array.isRequired,
};

export default Waypoint;
