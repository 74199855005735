/**
 * @fileoverview 
 * This is our custom A-Frame component.
 * It is responsible for adding the outer wireframe mesh
 * and nodes to its vertices.
 */

import { interacionManager } from "../../managers/interaction/interaction-manager";


window.AFRAME.registerComponent("move-waypoint", {
    schema: {
        mode: { type: "number", default: 0 },
        moving: { type: "boolean", default: false },
        position: { type: "vec3", default: {} },
        point: { type: "vec3", default: {} },
    },

    tick: function () {
        if(this.data.mode == 2) {
            if (this.raycaster == undefined) { return; }  // Not intersecting.
            this.intersection = this.raycaster.components.raycaster.getIntersection(this.card);
            if (this.intersection == undefined) { return; }
            this.data.point = this.intersection.point;
            
            if(this.data.moving && this.intersection){

                this.data.point.x += this.offset.x;
                this.data.point.y += this.offset.y;
                this.data.point.z += this.offset.z;

                let length = Math.sqrt(this.data.point.x**2 + (this.data.point.y)**2 + this.data.point.z**2);
                this.data.point.x = 5*this.data.point.x/length;
                this.data.point.y = 5*this.data.point.y/length;
                this.data.point.z = 5*this.data.point.z/length;
                this.el.setAttribute("position", this.data.point);
            }
        }
    },

    init:function() {
        let data = this.data;

        if(data.mode == 2) {
            let camera = document.querySelector("#cam");

            let ID = this.el.id;

            let cardID = `waypoint-card-${ID}`;

            this.card = document.getElementById(cardID);

            this.card.addEventListener("mousedown", (evt) => {
                this.data.position = this.el.getAttribute("position");

                this.offset = {
                    x: this.data.position.x - evt.detail.intersection.point.x,
                    y: this.data.position.y - evt.detail.intersection.point.y,
                    z: this.data.position.z - evt.detail.intersection.point.z,
                };
                this.data.moving = true;
                camera.setAttribute("look-controls-enabled", false);
            });

            this.card.addEventListener('raycaster-intersected', evt => {
                this.raycaster = evt.detail.el;
            });
            this.card.addEventListener('raycaster-intersected-cleared', evt => {
                this.raycaster = null;
            });
    
            this.card.addEventListener("mouseup", () => {
                if(!interacionManager.wasLastInteractionDrag()){
                    window.parent.postMessage({action: "waypoint-selected", data: this.el.id.split("_")[1]}, "*");
                }
                this.data.moving = false;
                camera.setAttribute("look-controls-enabled", true);
                window.dispatchEvent(new CustomEvent("waypoint-position-update", { detail: {id: this.el.id.split("_")[1], position: this.el.getAttribute("position")} }));
            });
        }

    }
});