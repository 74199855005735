import en from "./en-US";
import fr from "./fr-CA";
import de from "./de-DE";
import es from "./es-CR";

export default {
    ...es,
    ...en,
    ...fr,
    ...de,
};
